import React from 'react'

const Delete = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <path d="M9.08881 15.9453C9.42963 15.9453 9.70654 15.6711 9.70654 15.3336V7.90885C9.70654 7.57136 9.42963 7.29714 9.08881 7.29714C8.748 7.29714 8.47108 7.57136 8.47108 7.90885V15.3336C8.47108 15.6711 8.748 15.9453 9.08881 15.9453Z" fill="black"/>
    <path d="M14.2224 15.3336C14.2224 15.6711 13.9455 15.9453 13.6046 15.9453C13.2425 15.9453 12.9656 15.6711 12.9869 15.3336V7.90885C12.9869 7.57136 13.2638 7.29714 13.6046 7.29714C13.9455 7.29714 14.2224 7.57136 14.2224 7.90885V15.3336Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.847 2.93086H14.6697V1.03248C14.6697 0.694988 14.3928 0.420776 14.052 0.420776H8.66279C8.32197 0.420776 8.04506 0.694988 8.04506 1.03248V2.93086H1.86773C1.52691 2.93086 1.25 3.20508 1.25 3.54257C1.25 3.88006 1.52691 4.15427 1.86773 4.15427H3.25231L4.35996 18.4133C4.44517 19.489 5.36112 20.3116 6.42617 20.3116H16.2886C17.3749 20.3116 18.2909 19.4679 18.3548 18.4133L19.4625 4.15427H20.847C21.1878 4.15427 21.4648 3.88006 21.4648 3.54257C21.4648 3.20508 21.1878 2.93086 20.847 2.93086ZM9.30183 1.64418H13.3916V2.93086H9.30183V1.64418ZM16.2673 19.0882C16.6933 19.0882 17.0554 18.7507 17.098 18.3289L18.1844 4.17536H4.48777L5.59543 18.3289C5.63803 18.7507 6.00015 19.0882 6.42617 19.0882H16.2673Z" fill="black"/>
    <path d="M9.08881 15.9453C9.42963 15.9453 9.70654 15.6711 9.70654 15.3336V7.90885C9.70654 7.57136 9.42963 7.29714 9.08881 7.29714C8.748 7.29714 8.47108 7.57136 8.47108 7.90885V15.3336C8.47108 15.6711 8.748 15.9453 9.08881 15.9453Z" fill="black"/>
    <path d="M14.2224 15.3336C14.2224 15.6711 13.9455 15.9453 13.6046 15.9453C13.2425 15.9453 12.9656 15.6711 12.9869 15.3336V7.90885C12.9869 7.57136 13.2638 7.29714 13.6046 7.29714C13.9455 7.29714 14.2224 7.57136 14.2224 7.90885V15.3336Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.847 2.93086H14.6697V1.03248C14.6697 0.694988 14.3928 0.420776 14.052 0.420776H8.66279C8.32197 0.420776 8.04506 0.694988 8.04506 1.03248V2.93086H1.86773C1.52691 2.93086 1.25 3.20508 1.25 3.54257C1.25 3.88006 1.52691 4.15427 1.86773 4.15427H3.25231L4.35996 18.4133C4.44517 19.489 5.36112 20.3116 6.42617 20.3116H16.2886C17.3749 20.3116 18.2909 19.4679 18.3548 18.4133L19.4625 4.15427H20.847C21.1878 4.15427 21.4648 3.88006 21.4648 3.54257C21.4648 3.20508 21.1878 2.93086 20.847 2.93086ZM9.30183 1.64418H13.3916V2.93086H9.30183V1.64418ZM16.2673 19.0882C16.6933 19.0882 17.0554 18.7507 17.098 18.3289L18.1844 4.17536H4.48777L5.59543 18.3289C5.63803 18.7507 6.00015 19.0882 6.42617 19.0882H16.2673Z" fill="black"/>
  </svg>
  )
}

export default Delete