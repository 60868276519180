import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./BlogPageStyles.css";
import { Helmet } from "react-helmet";

const ManipalOrigaStrategic = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    }, [])

    const blogPost = {
      heading: "Manipal and Origa's Strategic Leasing",
    };

    
  return (
    <>
    <Helmet>
            <title>{blogPost.heading}</title>
            <meta
              name="keywords"
              content={`${blogPost.heading}`}
            />
          </Helmet>
      <div className="blogpage-totaldiv">
        {" "}
        {/* Total div to contain all divs inside :  */}
        <div className="blogpage-headingcontent">
          <h6 className="CaseStudy">Case Study</h6>
          <h1 className="CaeStudy-content">
          Manipal and Origa&#39;s Strategic Leasing Partnership for
          Business Expansion
          </h1>
        </div>
        <div className="blogpage-profile">
          <div className="blogpage-profile-image">
            <img
              className="blogpage-profile-img-seperate"
              src="https://www.origa.market/asset/user.png"
            />
          </div>

          <div className="blogpage-profile-text">
            <p className="blogpage-para">
              <span>Origa</span>
              <span className="blogpage-year">July 5, 2024 • 3 min read</span>
            </p>
          </div>
        </div>
        <div className="blogpage-profile-bigimage">
          <img
            className="blogpage-profile-bigimage-seperate"
            src="/manipalpage.webp"
          />
          <p className="blogpage-profile-bigimage-text">
          Manipal and Origa&#39;s Strategic Leasing Partnership for
          Business Expansion
          </p>
        </div>
        <div className="blogpage-realcontent">
          <h3 className="blogpage-realcontent-introductionheading">
            Company Background:
          </h3>
          <p className="blogpage-realcontent-introductioncontent">
          Manipal’s MCT Card and Technologies Pvt Ltd is a prominent player in
the printing industry, known for its significant market presence and
robust revenue generation. Despite their financial stability, MCT faced
difficulties in expanding their financial exposure through existing leasing
partners.
          </p>

          {/* 
          <h3 className="blogpage-realcontent-introductionheading">Problem Statement:</h3>
          <p className="blogpage-realcontent-introductioncontent">
          In its pursuit of growth, Yasho Industries heavily relied on debt to finance its new projects.
          This strategy led to a substantial increase in its debt-to-equity ratio. However additional
          capital was required to fund its expansion.
          Recognizing the critical need for a sustainable financing solution, the company explored
          alternative financial strategies. It approached ORIGA, a company known for innovative
          leasing &amp; financing solutions, to understand how it could assist in its expansion.
          </p> */}

          <h3 className="blogpage-strategic-objectives">Problem Statement:</h3>
          <p className="blogpage-realcontent-Strategicpara">
          MCT Card and Technologies aimed to achieve  key objectives with this campaign:
          </p>

          <ul className="blogpage-unorderd">
            <li className="blogpage-list dljgn">
              {" "}
              <span className="blogpage-boost">Objective:  </span>Secure additional funding to support growth and operational expansion.

            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Challenge: </span>{" "}
              Unsuccessful attempts to increase financial exposure with existing financiers.
            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">
              Impact:
              </span>{" "}
              Barrier to their growth ambitions.
            </li>
          
          </ul>

          <h3 className="blogpage-realcontent-innovateheading">
          Solution:
          </h3>

          <div className="blogpage-realcontent-leasingstructureparagrapdiv">
         
              <p className="blogpage-realcontent-leasingstructureparagrap">
              Origa intervened to address MCT&#39;s financial challenges by leveraging its
extensive network and expertise in the leasing market. Origa identified
two new financing partners willing to invest in MCT, securing a financial
sanction of 17.5 crore INR. This strategic move not only provided
immediate funding but also positioned Origa as a long-term growth
partner for MCT.
              </p>
              <p className="blogpage-realcontent-leasingstructureparagrap">
              Origa&#39;s proven track record of leasing to companies with revenues
exceeding 1000 crore INR underscored their capability to handle
substantial financial transactions. The partnership between Origa and
MCT has flourished over four years, solidifying Origa&#39;s role in supporting
MCT&#39;s journey towards expansion and market leadership.
              </p>
           
          </div>

          <h3 className="blogpage-realcontent-conclusion">Conclusion:</h3>

          <p className="blogpage-realcontent-conclusionparagraph">
          Origa&#39;s strategic intervention enabled MCT Card and Technologies Pvt
Ltd to secure the necessary funding for their expansion plans,
overcoming their financial challenges effectively. This case study
highlights Origa’s role as a reliable growth partner, showcasing their
ability to provide tailored financial solutions and leverage their network to
drive client success.
          </p>
        </div>
      </div>

      <div className="lastline">
        <hr className="lastlinehr" />
      </div>

      <div className="somespacetop"></div>
      <Footer />
    </>
  );
};

export default ManipalOrigaStrategic;
