import React from 'react'
import { NavLink } from 'react-router-dom'
const FooterBottom = () => {
  return (
    <>
      <div  style={{ backgroundColor: "#999E51" }}>
      <div className='max-container'>
        <div className="container-fluid p-0 m-0 row text-white" style={{minHeight:"48px"}}>
          <div className='col col-md-4 d-flex align-items-center'>
          <p className='m-0 heading-400-12-10'>Copyright © 2024 Origa, All Right Reserved</p></div>
          <div className='col col-md-4 for-desktop' >
            <div className='d-flex justify-content-center align-items-center h-100'>
            <p className='m-0 heading-400-12-10'>Our Policies | <NavLink to="/legal" className='text-white'>Legal Disclaimers</NavLink> </p>

            </div>
           
          </div>
          <div className='col col-md-4 d-flex justify-content-end align-items-center' >
           <p className='m-0 heading-300-10-8'>Designed by &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="91" height="34" viewBox="0 0 91 31" fill="none">
              <g clip-path="url(#clip0_1_85805)">
                <path d="M31.8251 10.3793L27.4958 20.362H25.586L26.9228 17.2881L24.0898 10.3477H26.1271L27.2094 13.39L27.8779 15.3866L28.6101 13.4534L29.9151 10.3793H31.8251Z" fill="#EEF2F4" />
                <path d="M33.796 14.5316C33.8597 14.9752 34.0826 15.3555 34.4327 15.609C34.8147 15.8943 35.2922 16.0211 35.897 16.0211C36.2473 16.0211 36.5974 15.9577 36.9475 15.8309C37.2977 15.7042 37.5841 15.5457 37.7752 15.3555L38.8893 16.4647C38.5392 16.845 38.0935 17.1303 37.5205 17.3204C36.9793 17.5105 36.4063 17.6057 35.8334 17.6057C34.6237 17.6057 33.6688 17.257 32.9685 16.5915C32.2681 15.8943 31.918 15.007 31.918 13.8661C31.918 12.7886 32.2681 11.9012 32.9366 11.204C33.6369 10.5068 34.5282 10.1582 35.6742 10.1582C36.8838 10.1582 37.8387 10.5385 38.4755 11.2674C39.1122 12.028 39.3986 13.1055 39.2394 14.4998H33.796V14.5316ZM37.6478 13.0738C37.5841 12.6301 37.3931 12.2815 37.0748 12.0597C36.7566 11.8378 36.3427 11.7111 35.8015 11.7111C35.3241 11.7111 34.8784 11.8378 34.5282 12.0597C34.1781 12.2815 33.9234 12.6301 33.796 13.0738H37.6478Z" fill="#EEF2F4" />
                <path d="M41.9768 7.43359V17.448H40.2578V7.43359H41.9768Z" fill="#EEF2F4" />
                <path d="M44.8446 7.43359V17.448H43.0938V7.43359H44.8446Z" fill="#EEF2F4" />
                <path d="M51.9441 11.2978C52.6444 11.995 52.9628 12.8823 52.9628 13.9281C52.9628 14.9739 52.6126 15.8614 51.9441 16.5584C51.2756 17.2557 50.3843 17.6044 49.2702 17.6044C48.1561 17.6044 47.2647 17.2557 46.5962 16.5584C45.9277 15.8614 45.6094 14.9739 45.6094 13.9281C45.6094 12.8823 45.9595 12.0267 46.628 11.2978C47.2965 10.6006 48.1879 10.252 49.302 10.252C50.3843 10.2203 51.2756 10.5689 51.9441 11.2978ZM47.8695 12.4387C47.5194 12.8507 47.3602 13.326 47.3602 13.8965C47.3602 14.4669 47.5194 14.9422 47.8695 15.3542C48.2196 15.7662 48.6972 15.9564 49.2702 15.9564C49.875 15.9564 50.3525 15.7662 50.6709 15.3542C51.021 14.9422 51.1802 14.4669 51.1802 13.8965C51.1802 13.3577 50.9891 12.8507 50.6389 12.4387C50.2888 12.0267 49.8113 11.8048 49.2384 11.8048C48.6972 11.8048 48.2196 12.0267 47.8695 12.4387Z" fill="#EEF2F4" />
                <path d="M59.9655 10.3477L61.5253 15.6401L63.1487 10.3477H65.0905L62.6075 17.4465H60.5703L59.8381 15.3548L59.2015 13.0097L58.5648 15.3548L57.8327 17.4465H55.7954L53.3125 10.3477H55.2543L56.8777 15.6401L58.4056 10.3477H59.9655Z" fill="#EEF2F4" />
                <path d="M70.7569 11.5534C70.0247 10.9195 69.3244 10.8245 68.5287 10.8245C67.4144 10.8245 66.364 11.2364 66.3958 12.1238C66.4277 13.0745 67.6691 13.2647 68.5604 13.4231C69.8337 13.645 71.5528 13.8668 71.4891 15.4831C71.4571 17.0359 69.8337 17.4478 68.5604 17.4478C67.3189 17.4478 66.0774 16.9725 65.4727 16.0217L65.982 15.5781C66.5868 16.4338 67.6373 16.7824 68.5922 16.7824C69.4518 16.7824 70.7569 16.5605 70.8206 15.4513C70.8523 14.4372 69.6745 14.2471 68.4967 14.057C67.128 13.8351 65.7592 13.5816 65.7273 12.1555C65.7273 10.7611 67.0961 10.1589 68.5287 10.1906C69.5791 10.1906 70.4704 10.4759 71.1707 11.1414L70.7569 11.5534Z" fill="#EEF2F4" />
                <path d="M73.3331 7.30469V17.3191H72.6328V7.30469H73.3331Z" fill="#EEF2F4" />
                <path d="M75.8179 7.97268C75.8179 8.70158 74.6719 8.70158 74.6719 7.97268C74.6719 7.24379 75.8179 7.21209 75.8179 7.97268ZM74.863 10.2228V17.3215H75.5632V10.2228H74.863Z" fill="#EEF2F4" />
                <path d="M82.9813 16.338C82.2491 17.0669 81.326 17.3838 80.4028 17.3838C78.3974 17.3838 76.7422 16.0211 76.7422 13.771C76.7422 11.5526 78.3337 10.1582 80.4028 10.1582C81.326 10.1582 82.2809 10.5068 82.9813 11.204L82.5356 11.6477C81.9627 11.0772 81.1668 10.792 80.4028 10.792C78.7794 10.792 77.4425 11.8378 77.4425 13.7393C77.4425 15.6408 78.7794 16.6866 80.4028 16.6866C81.1668 16.6866 81.9308 16.4013 82.5038 15.7992L82.9813 16.338Z" fill="#EEF2F4" />
                <path d="M83.8713 13.8026C83.8713 11.6793 85.4947 10.1581 87.4684 10.1581C89.4738 10.1581 91.2565 11.3623 90.9699 14.1195H84.5716C84.7308 15.7673 86.0041 16.7498 87.4684 16.7498C88.3916 16.7498 89.5057 16.3695 90.015 15.6723L90.5243 16.0842C89.824 16.9717 88.6143 17.4471 87.4684 17.4471C85.463 17.4153 83.8713 16.0526 83.8713 13.8026ZM90.3333 13.5173C90.3333 11.7743 89.1555 10.7919 87.4684 10.7919C86.0041 10.7919 84.7308 11.7743 84.5716 13.5173H90.3333Z" fill="#EEF2F4" />
                <path d="M4.64062 18.366L14.9544 11.7109L14.9226 21.5668C14.9226 22.169 14.6042 22.7394 14.0949 23.0563L4.92712 28.7291C4.79979 28.7925 4.67246 28.7291 4.67246 28.5706L4.64062 18.366Z" fill="#EEF2F4" />
                <path d="M1.23438 15.8299L1.64821 5.97404L10.6886 0.23796C11.0706 0.0161237 11.5481 0.269652 11.5481 0.713327V9.42837C11.5481 9.42837 11.5481 10.5059 13.7446 11.3615C16.1639 12.3123 18.8378 11.4249 18.8378 11.4249L9.19251 17.256C9.19251 17.256 6.00926 18.6821 4.1948 18.2068C1.90286 17.5729 1.23438 15.8299 1.23438 15.8299Z" fill="#BDCCD1" />
                <path d="M8.90625 17.5433V7.84584L18.2014 2.17315C18.7744 1.82455 19.5383 2.23653 19.5383 2.93374V10.0642C19.5383 10.6664 19.22 11.2368 18.7107 11.5537L8.90625 17.5433Z" fill="#EEF2F4" />
                <path d="M5.17787 18.4607C5.14604 18.4607 5.11422 18.4607 5.08238 18.4607C4.09557 18.429 3.10876 17.9537 2.15378 17.0663C1.45347 16.4007 1.07148 15.7669 1.03965 15.7353L1.00781 15.6719V6.22795C1.00781 5.97442 1.23064 5.75259 1.4853 5.78428C1.70813 5.81597 1.86729 6.00612 1.86729 6.22795V15.2281C1.86729 15.3233 1.89913 15.3866 1.93095 15.45C2.24928 15.9572 3.29975 17.5417 5.05055 17.5733C6.26018 17.605 7.43798 17.1614 8.0428 16.9079C8.20196 16.8445 8.29747 16.686 8.29747 16.4958V8.00266C8.29747 7.74913 8.52029 7.52729 8.77494 7.55898C8.99778 7.59067 9.15694 7.78082 9.15694 8.00266V17.0346C9.15694 17.193 9.06145 17.3198 8.93411 17.3833C8.36113 17.7002 6.57851 18.4607 5.17787 18.4607Z" fill="white" />
                <path d="M4.67222 29.0445C4.41755 29.0445 4.22656 28.8544 4.22656 28.6009V18.1745C4.22656 17.921 5.08604 17.921 5.08604 18.1745V28.6009C5.11787 28.8544 4.92687 29.0445 4.67222 29.0445Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1_85805">
                  <rect width="91" height="30.3333" fill="white" />
                </clipPath>
              </defs>
            </svg></p></div>
        </div>
        </div>
      </div>
    </>
  )
}

export default FooterBottom