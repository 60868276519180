import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import ImageSlider from "../Buying/Modals/ImageSlider.jsx";
import { framre3dIcon } from "../../helpers/Icons.js";
import ThreeDIcon from "../SubComponent/AllSvgs/ThreeDIcon";
import { heartIcon } from "../../helpers/Icons";
import useAMCWishListAddOrUpdate from "../SubComponent/useAMCWishListAddOrUpdate.jsx";
import TabSlider from "../SubComponent/AllSlider/TabSlider";
import Loader from "../SubComponent/Loader.jsx";
import SummaryCartLoggedNot from "./SummaryCartLoggedNot.jsx";
import SummaryCartforamc from "./SummaryCartforamc.jsx";
import { Container, Button } from "react-bootstrap";
import client from "../Services/ServicesPopup/apolloclient";
import { gql } from "graphql-tag";
import "../Addonservice/success.css";
import Footer from "../Footer/Footer.jsx";
import EditIcon from "../SubComponent/EditIcon.jsx";
import "./CartPageAmcStyles.css";
import SummaryCartAmcAgain from "./SummaryCartAmcAgain.jsx";
import SummaryCartAmcAgainNew from "./SummaryCartAmcAgainNew.jsx";
import Button2 from "../Button/Button2.jsx";

const CREATE_PAYMENT = gql`
  mutation CreateAmc($amcPlanData: AMCPlanInput!) {
    createAmc(amcPlanData: $amcPlanData) {
      message
      success
      paymenturl
      referenceid
    }
  }
`;
const CartPageAmcSimm = (products, onProductBlockNavigate) => {

  useEffect(() => {
    window.scrollTo(0,0);
      },[])
  console.log("Here inside this file");
  
  const location = useLocation();
  const [loadinged, setLoadinged] = useState(false);
  const navigate = useNavigate();
  const {
    selectedCategory,
    selectedLocation,
    firstname,
    serialNo,
    id,
    companyName,
    factoryName,
    city,
    state,
    secondgstno,
    secondcity,
    secondstate,
    address1,
    lastname,
    email,
    formattedPhoneNumber,
    formattedAlterPhoneNumber,
    pinCode,
    address2,
    secondpinCode,
    secondaddress1,
    secondaddress2,
    productId,
    productName,
    producttype,
    productPrice,
    productPlan,
    productGstAmount,
    productTotalAmount,
    productImage,
    productStatus, 
  } = location.state || {};
  const product = location.state?.product; // Use optional chaining to safely access product
  
  
  const productData = location.state && location.state.product;
  //   console.log("Selected id in CartPageAmc:  ",id);
  //   console.log("Selected companyName in CartPageAmc:  ",companyName);
  //   console.log("Selected factoryName in CartPageAmc:  ",factoryName);
  //   console.log("Selected city in CartPageAmc:  ",city);
  //   console.log("Selected state in CartPageAmc:  ",state);
  //   console.log("Selected Category in CartPageAmc:  ",secondgstno);
  //   console.log("Selected Category in CartPageAmc:  ",secondcity);
  //   console.log("Selected Category in CartPageAmc:  ",secondstate);
  //   console.log("Selected Category in CartPageAmc:  ",address1);

  //   console.log("Selected Category in CartPageAmc:  ",lastname);
  //   console.log("Selected Category in CartPageAmc:  ",firstname);
  //   console.log("Selected Category in CartPageAmc:  ",email);
  //   console.log("Selected Category in CartPageAmc:  ",formattedPhoneNumber);
  //   console.log("Selected Category in CartPageAmc:  ",formattedAlterPhoneNumber);

  //   console.log("Selected Category in CartPageAmc:  ",pinCode);
  //   console.log("Selected Category in CartPageAmc:  ",address2);

  //   console.log("Selected Category in CartPageAmc",secondpinCode);
  //   console.log("Selected Category in CartPageAmc ",secondaddress1);
  //   console.log('Selected Category in CartPageAmc:  ', selectedCategory);
  //    console.log('Selected Location in CartPageAmc:  ', selectedLocation);
  //   console.log('Selected Firstname in CartPageAmc:  ', firstname);
  //    console.log('Selected serialNo in CartPageAmc:  ', serialNo);

  //    console.log("Selected productId in CartPageAmc:  ",productId);
  //    console.log('Selected productName in CartPageAmc:  ', productName);
  //    console.log('Selected prodcutType in CartPageAmc:  ', producttype);
  //    console.log('Selected ProductPrice in CartPageAmc:  ', productPrice);

  //    console.log('Selected ProductPlan in CartPageAmc:  ', productPlan);
  //    console.log('Selected productGstAmount in CartPageAmc:  ', productGstAmount);
  //    console.log('Selected productTotalAmount in CartPageAmc:  ', productTotalAmount);

  //    console.log('Selected productTotalAmount in CartPageAmc:  ', productStatus);

  console.log(
    "Firstname  :  ", firstname, "\n",
    "Lastname  :  ", lastname, "\n",
    "Formatted Phone Number  :  ", formattedPhoneNumber, "\n",
    "Formatted Alternate Phone Number  :  ", formattedAlterPhoneNumber, "\n",
    "Pin Code  :  ", pinCode, "\n",
    "State  :  ", state, "\n",
    "Address 1  :  ", address1, "\n",
    "Address 2  :  ", address2, "\n",
    "City  :  ", city, "\n",
    "Second Pin Code  :  ", secondpinCode, "\n",
    "Second State  :  ", secondstate, "\n",
    "Second Address 1  :  ", secondaddress1, "\n",
    "Second City  :  ", secondcity, "\n",
    "Second GST No  :  ", secondgstno, "\n",
    "Company Name  :  ", companyName, "\n",
    "Factory Name  :  ", factoryName, "\n",
    "Product ID  :  ", productId, "\n",
    "Product Name  :  ", productName, "\n",
    "Product Type  :  ", producttype, "\n",
    "Product Price  :  ", productPrice, "\n",
    "Product Plan  :  ", productPlan, "\n",
    "Product GST Amount  :  ", productGstAmount, "\n",
    "Product Total Amount  :  ", productTotalAmount, "\n",
    "Product Status  :  ", productStatus,"\n",
    "Product id  :  ", id
);



const Gotoedittadderss = () => {
    console.log("Going to /amcnaddaddresscart",);


    setLoadinged(true);
        setTimeout(() => {
          navigate('/amcnaddaddresscart', {
            state: {
              productId,
              id,
              selectedCategory,
              selectedLocation,
              serialNo,
              firstname,
              lastname,
              companyName,
              factoryName,
              city,
              state,
              secondgstno,
              secondcity,
              secondstate,
              formattedPhoneNumber,
              formattedAlterPhoneNumber,
              pinCode,
              state,
              address1,
              address2,
              city,
              secondpinCode,
              secondstate,
              secondaddress1,
              secondaddress2,
              secondcity,
              secondgstno,
              companyName,
              factoryName,
              productId,
              productName,
              producttype,
              productPrice,
              productPlan,
              productGstAmount,
              productTotalAmount,
              productImage,
              productStatus,
              titlename:"Edit Address"
            }
        });
        }, 1000);
    // navigate("/amcnaddaddresscart", {
    //     state: {
    //       productId,
    //       id,
    //       selectedCategory,
    //       selectedLocation,
    //       serialNo,
    //       firstname,
    //       lastname,
    //       companyName,
    //       factoryName,
    //       city,
    //       state,
    //       secondgstno,
    //       secondcity,
    //       secondstate,
    //       formattedPhoneNumber,
    //       formattedAlterPhoneNumber,
    //       pinCode,
    //       state,
    //       address1,
    //       address2,
    //       city,
    //       secondpinCode,
    //       secondstate,
    //       secondaddress1,
    //       secondaddress2,
    //       secondcity,
    //       secondgstno,
    //       companyName,
    //       factoryName,
    //       productId,
    //       productName,
    //       producttype,
    //       productPrice,
    //       productPlan,
    //       productGstAmount,
    //       productTotalAmount,
    //       productImage,
    //       productStatus,
    //       titlename:"Edit Address"
    //     }
    //   });
}
  //  console.log("AMC plan id :  ",productId);
  const AMC_BASED_ON_TYPE = location.state?.selectedCategory?.toUpperCase();

//   console.log(
//     "AMC BASED ON TYPE in Cart finCartPageAmc : -------------> ",
//     AMC_BASED_ON_TYPE
//   );
  const [responseData, setResponseData] = useState(null);
//   console.log("product data=>>", productData);
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { onWishlistHandler, heartColor } = useAMCWishListAddOrUpdate();

//   const onMouseEnterhandler = () => {
//     setIsHovered(true);
//   };

//   const onMouseLeavehandler = () => {
//     setIsHovered(false);
//   };

  const handleModal = (status) => {
    setShowModal(status);
  };


  const [SuccessPopUp, setSuccessPopUp] = useState(false);
  const [PaymentLink, setPaymentLink] = useState([]);


  

  const onBuyHandler = async () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling effect
    });
    console.log("wwswswswswwsw");
    console.log("Here Proceed Button is clicked : ")
    const id = localStorage.getItem("id");
    console.log("Customer Id :  --------->",id);
    try {
      console.log("Now the api is going to be called :  ");
      const { data } = await client.mutate({
        mutation: CREATE_PAYMENT,
        variables: {
            "amcPlanData": {
              "appAmcPlanId": productId,  // Pass as an integer, not a string
              "customerId": id,
              "stage": "PAYMENT",
              "serviceType": "AMC",
              "category":selectedCategory,
              "location":selectedLocation,
              "serialNo":serialNo,
              "firstName":firstname,
              "companyName": companyName,
              "factoryName": factoryName,
              "address": {
                "billingAddress": {
                  "city": city,
                  "cityArea": "",
                  "companyName":companyName ,
                  "country": "IN",
                  "countryArea": state,
                  "firstName": firstname,
                  "lastName": lastname,
                  "phone": `${formattedPhoneNumber}`,
                  "postalCode": `${pinCode}`,
                  "streetAddress1": address1,
                  "streetAddress2": address2,
                  "gst": secondgstno
                },
                "shippingAddress": {
                  "city": secondcity,
                  "cityArea": "",
                  "companyName": companyName,
                  "country": "IN",
                  "countryArea": secondstate,
                  "firstName": firstname,
                  "lastName": lastname,
                  "phone": `${formattedPhoneNumber}`,
                  "postalCode": `${secondpinCode}`,
                  "streetAddress1": secondaddress1,
                  "streetAddress2": secondaddress2,
                  "gst": secondgstno
                }
              }
            }
          }
      });

      console.log("API Response paymentgetway==>>>", data);
      console.log("Create payment is happening : ------------------------>");
      console.log("appAmcPlanId : ------------------------>", productId);
      setResponseData(data);
      setSuccessPopUp(true);
      setPaymentLink(data.createAmc.paymenturl);
      console.log(
        "Payment Link :  ---------------------------------> ",
        PaymentLink
      );
      //window.location.href = data.createAmc.paymenturl;
    } catch (error) {
      console.error("API Error==>", error.message);
    }
  };
  return (
    <>

{loadinged && <Loader />}
      {SuccessPopUp ? (
        <Container fluid className="p-fixed bg-blur hw-100 d-j-a">
          <div className="App">
            <div className="success-login">
              <div className="contents">
                <h3 className="heading-600-28">Confirm Payment</h3>
                <p className="heading-400-16 op-60">Proceed to the payment</p>
                <div className="d-flex justify-content-between mt-3">
                  <a href={PaymentLink} rel="noreferrer">
                    <Button variant="success" className="me-2">
                      Pay Now
                    </Button>
                  </a>
                  <Button
                    variant="danger"
                    style={{ marginLeft: "8px" }}
                    onClick={() => setSuccessPopUp(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      ) : null}

      <div className="totalcartpage">
        <div className="leftsideBordertotaldiv">
          <div className="toppartofcartpage row justify-content-between align-items-center">
            <div className="leftheadingcart col-4">
              <h1 className="leftheadingcarttext">My Cart</h1>
            </div>
            {/* <div className="rightheadingcart col-4">
              <h1 className="rightheadingcarttext">Price</h1>
            </div> */}
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartunder" />
          </div>

          <div className="threesectionsofcart">
            <div className="firstsectionofcart">
              {/* <img className="imageonleftofcart"src={product.plan_image} alt={product.name} /> */}
              <img
                className="imageonleftofcart"
                src={productImage} // Use a fallback image URL or placeholder
                alt={productName} // Provide a default alt text
              />
            </div>
            <div className="secondsectionofcart">
              <h1 className="headingpartofcarttt">
                {productPlan} Preventive maintenance  (PM) plan for{" "}
                {producttype || "Unknown"} machine
                <br />
                {productName ? (
                  <span className="rbfbef heading-400-20-14 op-60">
                    &nbsp;| {productName}
                  </span>
                ) : (
                  "N/A"
                )}
              </h1>
              Status : {productStatus}
              {/* <div className="contentofcartpara">
                <p className="inStocktext">
                  <span className="inStocktextcontent">₹{productPrice}</span>
                </p>
              </div> */}
            </div>
            <div className="thirdsectionofcart mt-5">
              {/* <p className="badgerightside">
                    <span className="salespricecart">GST : ₹{product. gst_amount}</span>
                    
                </p>
                <p className="badgerightside">
                   
                    <span className="salespricecart">Total Amount ₹{product. total_amount}</span>
                </p> */}
            </div>
            <div className="basespace"></div>
          </div>

          <div className="address-container">
  <div className="address-header">
    <h4 className="addressheading">Address</h4>
    <div className="edit-address-container">
      <div
        className="edit-address d-flex curser-pointer"
        onClick={Gotoedittadderss}
      >
        <EditIcon />
        <p className="m-0 pl-2 heading-600-14">Edit Address</p>
      </div>
    </div>
  </div>

  <div className="horizontallineofcartpage">
    <hr className="lineofcartbottomiin" />
  </div>

  <div className="company-details-container">
    <div className="company-details">
      <span className="headingindelivery">Company Details:</span><br />
      <span className="headingindelivery">Company Name:</span> 
      <span>{companyName}</span><br />
      <span className="headingindelivery">Factory Name:</span> 
      <span>{factoryName}</span> <br />
      <span className="headingindelivery">Gst No:</span> 
      <span>{secondgstno}</span>
    </div>

    <div className="personal-details">
      <span className="headingindelivery">Name:</span>
      <span>{firstname} {lastname}</span><br />
      <span className="headingindelivery">Mobile Number:</span>
      <span>{formattedPhoneNumber}</span>
    </div>

    <div className="shipping-address">
      {/* <span className="headingindelivery">Shipping Address:</span><br />
      <span>{secondaddress1},{secondaddress2},{secondcity},</span>
      <span>{secondstate},{secondpinCode}</span> */}

      <span className="headingindelivery">Shipping Address:</span><br />
      <span>{secondaddress1}, {secondaddress2}, {secondcity},</span>
      <span>{secondstate}, {secondpinCode}</span>

    </div>

    <div className="billing-address">
      <span className="headingindelivery">Billing Address:</span><br />
      <span>{address1}, {address2}, {city},</span>
      <span>{state}, {pinCode}</span>
    </div>
  </div>


<button className="proceedcartbtnnew"  onClick={onBuyHandler}>
    Go to Payment
  </button>

 
</div>
        </div>

        <div className="rightsideBordertotaldiv">
          <SummaryCartAmcAgainNew />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CartPageAmcSimm;


          {/* <div className='d-flex justify-content-end'>
<Button message={"Proceed to Payment"}   />
</div> */}
          {/* <button className="proceedcartbtnnew"  onClick={onBuyHandler}>
    Confirmation 
        </button> */}