import React, { useEffect } from 'react'
import CardGrid from './CardGrid'
import HeroComponent from './HeroComponent'
import Footer from '../Footer/Footer'

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroComponent />
      <CardGrid />
      <Footer/> 
    </>
  )
}

export default Blog