import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  closeIcon,
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  vectorLineIcon,
} from "../../../helpers/Icons";
import Footer from "../../Footer/Footer";
import Confirmation from "../../Buying/Product/Confirmation";
import axios from "axios";
import Loader from "../../SubComponent/Loader";
import QuotationPopupForBuynowjsx from "./QuotationPopupForBuynow";

const SummaryCartPage = () => {


useEffect(() => {
  window.scrollTo(0,0);
},[])

let finalOrderId ;

  const [showQuotationPopup, setShowQuotationPopup] = useState(false); // Control popup visibility

  const [showPopup2, setShowPopup2] = useState(false);
  const closePopup2 = () => {
    setShowQuotationPopup(false);
   
  };

  const location = useLocation();
 
  // Destructure the state object to retrieve the values
  const { newItemId, newBucketId, newQuantity, newOrderId, mpAddressId,quantityTotal,
    subtotalAmount,
    gstAmount,
    taxesAmount,
    shippingFee,
    totalAmount, } = location.state || {};

  // // Display or use the data in your component
  // console.log("New Item ID:", newItemId);
  // console.log("New Bucket ID:", newBucketId);
  // console.log("New Quantity:", newQuantity);
  // console.log("New Order ID:", newOrderId);
  // console.log("Selected mp_address_id:", mpAddressId); 
  // console.log("Quantity Total:", quantityTotal);
  // console.log("Subtotal Amount:", subtotalAmount);
  // console.log("GST Amount:", gstAmount);
  // console.log("Taxes Amount:", taxesAmount);
  // console.log("Shipping Fee:", shippingFee);
  // console.log("Total Amount:", totalAmount);// This is the mp_address_id you passed

  const [bucketId, setBucketId] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [quantityjd, setQuantityjd] = useState(0);
  const [itemName, setItemName] = useState('');

  const [price, setPrice] = useState(0);
  const [thumbnailUrl, setThumbnailUrl] = useState('');


 
  const [loadinged, setLoadinged] = useState(false);
  const navigate = useNavigate();
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const [orderId, setOrderId] = useState('');
  const address = location.state?.address || {}; // Ensure address is an object
  const [loginPortal, setLoginPortal] = useState(false);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  const id = queryParams.get('id');
  const message = queryParams.get('message');
  const [orderDetails, setOrderDetails] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [totalprice, setTotalPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [draftItems, setDraftItems] = useState([]);
  const [quantity, setQuantity] = useState("");

  const [deliveryAvailable, setDeliveryAvailable] = useState(false); // For delivery availability
  const [deliveryLocation, setDeliveryLocation] = useState(""); // Store postal code for checking delivery
  const selectedAddressIndex = parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;
  const selectedAddress = data ? data[selectedAddressIndex]?.address : null; // 



  
  // console.log("Selected address  in SummaryCartPage : ",selectedAddress);
  // console.log("Selected address index in SummaryCartPage : ",selectedAddressIndex);



  useEffect(() => {
    if (selectedAddress) {
      const postalCode = selectedAddress.input.postalCode;
      if (postalCode) {
        checkDeliveryAvailability(postalCode);
      }
    }
  }, [selectedAddress]);

  // Function to check delivery availability based on postal code
  const checkDeliveryAvailability = async (pincode) => {
    try {
      const response = await fetch('https://ompextension.origa.market/api/getdeliverypincode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pincode }),
      });
      const data = await response.json();
      setDeliveryAvailable(data.available_status === 'available');
      setDeliveryLocation(pincode);
    } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailable(false);
    }
  };

  // const handleProceedPaymentbtn = () => {
  //   const loggedin = !!localStorage.getItem("userToken");
  //   if (loggedin) {
  //     handleCreateOrder();
  //     navigate("/manageaddresspart");
  //   } else {
  //     setLoginPortal(true);
  //   }
  // };

  const handleProceedQuotationbtn = () => {
    // Handle the quotation logic here
    console.log("Quotation requested for unavailable delivery area.");

    setShowQuotationPopup(true); // Show the Quotation popup
  };
  // const location = useLocation();
  // const [loadinged, setLoadinged] = useState(false);
  // const navigate = useNavigate();
  // const [invoiceUrl, setInvoiceUrl] = useState('');
  // const queryParams = new URLSearchParams(location.search);
  // const [orderId, setOrderId] = useState('');
  // const address = location.state?.address || {}; // Ensure address is an object
  // const [loginPortal, setLoginPortal] = useState(false);
  // const [cartItems, setCartItems] = useState(
  //   JSON.parse(localStorage.getItem("cartItems")) || []
  // );
  // const id = queryParams.get('id');
  // const message = queryParams.get('message');
  //   const [orderDetails, setOrderDetails] = useState(null);
  // const [subtotal, setSubtotal] = useState(0);
  // const [taxTotal, setTaxTotal] = useState(0);
  // const [discountTotal, setDiscountTotal] = useState(0);
  // const [discountPercent, setDiscountPercent] = useState(0);
  // const [shippingCharge, setShippingCharge] = useState(0);
  // const [totalprice, setTotalPrice] = useState(0);
  // const [total, setTotal] = useState(0);
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  // const [totalQuantity, setTotalQuantity] = useState(0);
  // const [draftItems, setDraftItems] = useState([]);
  // const [quantity, setQuantity] = useState("");

  // const selectedAddressIndex =
  //   parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;

  // const selectedAddress = data ? data[selectedAddressIndex] : null;

  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      try {
        console.log('Fetching order details for:', { customerId, referenceNumber });

        const response = await fetch(`https://ompextension.origa.market/sparesapi/get_orders`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customer_id: customerId,
            reference_number: referenceNumber,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        console.log('API response:', data);

        if (data.order) {
          setOrderDetails(data.order);

          // Extract the s3_url from the invoice_info
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      } finally {
        setLoading(false);
      }
    };

    const id = localStorage.getItem("id");
    if (id && message === 'Paid') {
      fetchOrderDetails(id);
    }
    
  }, [id, message]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number")
          }
        );
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const [responseUrl, setResponseUrl] = useState("");

  // console.log(
  //   "selectedAddress-------------------------------->",
  //   selectedAddress
  // );



  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem('userToken');
      const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName") ;
      const customer_last_name = localStorage.getItem("lastName") ;
      const customer_contact_no = localStorage.getItem("number") ;
      const customer_email = localStorage.getItem("emailId");

      if (loggedin) {
        const requestBody = {
          items: draftItems.map(item => ({
            item_id: item.item_id,
            quantity: item.quantity
          })),
          ompuserId,//get the id from the localStorage
        customer_first_name,
        customer_last_name,
        customer_contact_no,
        order_status: "Draft",
        order_ref_number: "",
        customer_email,
        input: {
          city: "",
          cityArea: "",
          companyName: "",
          country: "",
          countryArea: "",
          firstName: "",
          lastName: "  ",
          phone: "",
          postalCode: "",
          streetAddress1: " ",
          streetAddress2: "",
        },
        businessaddress: {
          city: "",
          cityArea: "",
          companyName: " ",
          country: "",
          countryArea: "",
          firstName: "",
          lastName: "",
          phone: "",
          postalCode: "",
          streetAddress1: "",
          streetAddress2: "",
        },
        gst_no: "",
        };
        try {
          // Fetch draft items
          const draftResponse = await fetch(
            'https://ompextension.origa.market/sparesapi/create_order',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!draftResponse.ok) {
            throw new Error('Failed to fetch draft items');
          }

          const draftData = await draftResponse.json();
          const lineItem = draftData.line_items[0];
          setOrderId(draftData.order_id  || 0);
          setCartItems(draftData.line_items || []);

          setBucketId(lineItem.bucket_id);
          setItemId(lineItem.item_id);
          setQuantityjd(lineItem.quantity);
          setItemName(lineItem.name);
          setPrice(lineItem.rate);
          setThumbnailUrl(lineItem.thumbnail[0]?.url || ''); // First image thumbnail

          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          setTotalPrice(draftData.total_quantity || 0);
          setQuantity(draftData.total_quantity || 0);



          // Calculate the total quantity from the response
          // const totalQuantity = draftData.line_items.reduce((acc, item) => acc + item.quantity, 0);
          // setTotalQuantity(totalQuantity);

        } catch (error) {
          console.error('Error fetching or merging cart items:', error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };

    addCartItems(); // Call the function to add cart items on component mount
  }, []);

  const handleProceedPaymentbtn = () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      handleCreateOrder();
      navigate("/manageaddresspart");
    } else {
      setLoginPortal(true);
    }
  };

  const handleOrderAddressUpdate = async () => {
    try {
      let payload;
  
      // Check if newOrderId or mpAddressId is null and set payload accordingly
      if (newOrderId && mpAddressId) {
        payload = {
          order_type: "Oil",
          order_id: newOrderId,
          mp_address_id: mpAddressId,
        };
        console.log("Proceeding with Buy: newOrderId is set.");
      } else if (orderId && mpAddressId) {
        payload = {
          order_type: "Oil",
          order_id: orderId,
          mp_address_id: mpAddressId,
        };
        console.log("Proceeding with Add to Cart: orderId is set.");
      } else {
        console.error("Missing required information: newOrderId or orderId or mpAddressId is null");
        return; // Exit if required data is missing
      }
  
      // Debug logs
      console.log("Order Id:", payload.order_id);
      console.log("Mp Address Id:", mpAddressId);
  
      // Make the API call
      const response = await axios.post("https://ompextension.origa.market/api/order_address_update", payload);

      
  
      // Handle the response
      if (response.data.status === "Success") {
        console.log("Address updated successfully so that handleOrderAddressUpdate function is working : ");
        
      } else {
        console.error("Failed to update address:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating order address:", error);
    }
  };
  const handleProceedConfirmation = async () => {
    const customer_first_name = localStorage.getItem("firstName") ;
  const customer_last_name = localStorage.getItem("lastName") ;
  const customer_contact_no = localStorage.getItem("number") ;


    const query = `
          mutation CreatePayment($inputpayment: PaymentInput!) {
            createPayment(inputpayment: $inputpayment) {
              payment {
                merchantTransactionId
                PaymentID
              }
              message
              success
              responseurl
            }
          }
      `;


    if (newOrderId){
      finalOrderId = newOrderId
    }
    else if(orderId)
    {
      finalOrderId= orderId
    }

    const variables = {
      inputpayment: {
        amount: "10",
        createdby: "Karthik",
      mobileno: "9361298454",
        paymentmethod: "PAGE",
        paymentstatus: "PEN",
        redirecturl:
          `https://ompextension.origa.market/toolspaymentredirect?id=${finalOrderId}`,
      },
    };

    try {
      const response = await fetch(
        "https://ompextension.origa.market/graphql/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
            variables,
          }),
        }
      );

      const result = await response.json();
      if (
        result.data &&
        result.data.createPayment &&
        result.data.createPayment.responseurl
      ) {
        setResponseUrl(result.data.createPayment.responseurl);
        window.location.href = result.data.createPayment.responseurl;
      } else {
        console.error("Failed to get response URL:", result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const handleButtonClick = async () => {
    await handleOrderAddressUpdate(); 
    await handleProceedConfirmation(); 
  };
  


  


  const handleCreateOrder = async () => {
    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      // Handle case where user is not logged in
      setLoginPortal(true); // Assuming this sets state to show login portal
      return;
    }
    // console.log('cartItems --------------------------------------------------------------- > :', cartItems);
    // console.log("cartItems -------------------------------->  ",cartItems)
    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log("localCartItems :: ", localCartItems);

    var itemList = [];
    for (var item in localCartItems) {
      var obj = {};
      obj["item_id"] = localCartItems[item]["productId"];
      obj["quantity"] =  localCartItems[item]["quantity"];
      itemList.push(obj);
    }
    console.log("itemList -------------------->  :: ", itemList);
    const ompuserId = localStorage.getItem("id") || "defaultId";
    const customer_first_name = localStorage.getItem("firstName") ;
    const customer_last_name = localStorage.getItem("lastName") ;
    const customer_contact_no = localStorage.getItem("number") ;
    const customer_email = localStorage.getItem("emailId");
  
    


    const requestBody = {
      items: itemList,
      ompuserId,//get the id from the localStorage
      customer_first_name,
      customer_last_name,
      customer_contact_no,
      order_status: "Draft",
      order_ref_number: "",
      customer_email,
      input: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "  ",
        phone: "",
        postalCode: "",
        streetAddress1: " ",
        streetAddress2: "",
      },
      businessaddress: {
        city: "",
        cityArea: "",
        companyName: " ",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      gst_no: "",
    };
     

    try {
      console.log(
        "requestBody  ------------------------------------------------------------- > :: ",
        requestBody
      );
      // Create order request
      const createOrderResponse = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!createOrderResponse.ok) {
        throw new Error("Failed to create order");
      }

      const createOrderData = await createOrderResponse.json();
      const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
      console.log("Created order with items:", draftItems);

      // Clear local storage cartItems after successful order creation
      // localStorage.removeItem('cartItems');
      setCartItems([]); // Clear cart items state
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };


  //To fetch the customer addresses

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number")  // Ensure mobileno is retrieved correctly
          }
        );
        console.log("API Response:", response.data);

        // Set the response data (address list) to state
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <>{loadinged && <Loader />}
    
    <div className="totalcartpagebuttop">
        <div className="centertotaldivcart">
          <div className="icon-containere">
            <span className="dilevery-address-1">
            {ellipseWhiteIcon({ width: 32, height: 32 })}
              
            </span>
            <span className="icon-number">1</span>
          </div>
          <span className="add-to-cart-text">Add to Cart</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-containere rsbbref">
            <span className="order-sumary-2">
              {ellipseWhiteIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">2</span>
          </div>
          <span className="add-to-cart-text">Delivery Address</span>
          <div className="line lineedbr">
            {vectorLineIcon({ width: 88, height: 1 })}
          </div>
          <div className="icon-containere ">
            <span className="order-sumary-2">
            {ellipsePurpleIcon({ width: 32, height: 32 })}
            </span>
            <span className="icon-number">3</span>
          </div>
          <span className="add-to-cart-text">Payment</span>
        </div>
      </div>

      
      <div class="row justify-content-center twodivborderseperate">
        <div className="DeliveryAddressesHeading">
          {/* <h1 className='DeliverAddresContentText'>Your Delivery Address : </h1> */}
        </div>
        <div class="col-6 leftsideborderdeliverypart">
          <h4 className="addressheading"> Address : </h4>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartbottomiin" />
          </div>

          <p>
            <div></div>

{selectedAddress && (
  <>
    <span className="headingindelivery">Name: </span>
    {selectedAddress.input.firstName} {selectedAddress.input.lastName}
    <br />
    <span className="headingindelivery">Mobile Number: </span>
    {selectedAddress.input.phone}

    <br />

    <span className="headingindelivery">Company Name: </span>
    {selectedAddress.input.companyName}
    <br />

    <span className="headingindelivery">Factory Name: </span>
    {selectedAddress.factoryName}
    <br />

    
    <span className="headingindelivery">Billing Address: </span>
    <br />
    {selectedAddress.input.streetAddress1}, {selectedAddress.input.city}
    <br />
    {selectedAddress.input.countryArea}, {selectedAddress.input.postalCode}
    <br />
    <span className="headingindelivery">Shipping Address: </span>
    <br />
    {selectedAddress.businessaddress?.streetAddress1}, {selectedAddress.businessaddress?.streetAddress2}
    <br />
    {selectedAddress.businessaddress.countryArea},{selectedAddress.businessaddress?.city}, {selectedAddress.businessaddress?.postalCode}
    <br />
  </>
)}
          </p>
        </div>

        {/* <div class="col-8 leftsideborderdeliverypart">
    <h4>Your Products  : </h4>

    <div className="horizontallineofcartpage">
            <hr className="lineofcartbottomiin" />
          </div>




      </div> */}
        <div class="col-4 rightsidebordersummarypart">
          <div className="buttondivsummaryaddress">
          {deliveryAvailable ? (
              <button className="usethisaddressinbutton" onClick={handleButtonClick}>
                Proceed to Payment
              </button>
            ) : (
              <button className="usethisaddressinbutton" onClick={handleProceedQuotationbtn}>
                Get Quotation
              </button>
            )}
            {/* <button
              className="usethisaddressinbutton "
              onClick={handleProceedConfirmation}
            >
              Proceed
            </button> */}

            <p className="chooseashippingaddress">
              Choose a shipping address and payment method to calculate
              shipping, handling and tax.
            </p>
            {/* <hr className="horinvdnv" /> */}
            {/* <hr className="lineofcartbottomiinvjnbf" /> */}
            <h6 className="ordersummaryheading">Order Summary</h6>
            <div className="pricing-details">
            <div className="pricing-item total">
  <span className="item-label">Total quantity:</span>
  <span className="item-value">
    {totalprice > 0 ? totalprice : quantityTotal}
  </span>
</div>

<div className="pricing-item">
  <span className="item-label">Sub Total:</span>
  <span className="item-value">
    {subtotal > 0 ? `₹${subtotal}` : `₹${subtotalAmount}`}
  </span>
</div>

<div className="pricing-item">
  <span className="item-label">Taxes :</span>
  <span className="item-value">
    {taxTotal > 0 ? `₹${taxTotal}` : `₹${gstAmount}`}
  </span>
</div>

<div className="pricing-item">
  <span className="item-label">Shipping Charge:</span>
  <span className="item-value">
    {/*shippingCharge is from the api : response  */}
    {shippingCharge >= 0 ? `₹${shippingCharge}` : `₹${shippingFee}`}
  </span>
</div>

<div className="pricing-item total">
  <span className="item-label">Total:</span>
  <span className="item-value">
  {total > 0 ? `₹${total}` : `₹${totalAmount}`}
  </span>
  
</div>

{discountTotal > 0 && (
  <div className="pricing-item promotion">
    <span className="item-label">Discount percent:</span>
    <span className="item-value">₹{discountTotal}</span>
  </div>
)}


            </div>
          </div>
        </div>
      </div>

      {showQuotationPopup && (
      <div className="popup-overlay">
        <div className="popup-banner">
          <div className="popup-content">
          <QuotationPopupForBuynowjsx
                bucketId={bucketId}
                itemId={itemId}
                quantity={quantity}
                itemName={itemName}
                price={price}
                thumbnailUrl={thumbnailUrl}
                subtotal={subtotal}
                total={total}
                totalQuantity={totalQuantity}
              />
            <div className="close-popup" onClick={closePopup2}>
              {closeIcon({ width: 14, height: 14 })}
            </div>
          </div>
        </div>
      </div>
    )}
      
      <Footer />
    </>
  );
};

export default SummaryCartPage;
