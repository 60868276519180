import React, { useEffect, useState } from 'react';
import { secondClient, GET_SPARE_DETAILS } from "../OrigaExtentionAPI/mutations";
import { useLocation, useNavigate } from "react-router-dom";
import "./CartPageSampleStyles.css";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import Loader from '../SubComponent/Loader';

const client = secondClient;


const CartItemSample = ({ productId, productName, productBrand, productImage, salePrice }) => {
  const location = useLocation(); // to access the current location
    const queryParams = new URLSearchParams(location.search); // to pass the query parameters to the url
    const groupId = queryParams.get('group_id'); // to get the id from url
    const [cartItems, setCartItems] = useState(
        JSON.parse(localStorage.getItem("cartItems")) || []
      );
      const [endQuantity, setEndQuantity] = useState();
      
      const [bucketSize, setBucketSize] = useState(null);
    const [productDETAILS, setProductDETAILS] = useState({});
    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
      const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const item = storedCartItems.find(item => item.productId === productId) || {};
      setEndQuantity(item.endQuantity || 0);
      setQuantity(item.quantity || 0); // Initialize quantity from cart item
    }, [productId]);


    
    // Retrieve the quantity from localStorage specific to the product
    // const [quantity, setQuantity] = useState(() => {
    //     const savedQuantity = localStorage.getItem(`quantity_${productId}`);
    //     return savedQuantity ? parseInt(savedQuantity, 10) : 1;
    // });

    const [quantity, setQuantity] = useState(() => {
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const item = cartItems.find(item => item.productId === productId);
      return item ? item.quantity : 1;
    });
  
    useEffect(() => {
      // Save the quantity to localStorage whenever it changes
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const itemIndex = cartItems.findIndex(item => item.productId === productId);
      if (itemIndex !== -1) {
        cartItems[itemIndex].quantity = quantity;
      } else {
        cartItems.push({ productId, quantity }); // Add new item if not found
      }
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [quantity, productId]);
    
    
  const cartItemsString = localStorage.getItem("cartItems");
  const cartItemsnow = JSON.parse(cartItemsString);  
  const quantities = cartItemsnow.map(item => item.quantity);
  // console.log("Quantities from cart:", quantities);



 // Function to get the total price from cart items
const getBucketPrice = () => {
  // Use reduce to sum up totalCartPriceocvf from each item
  return cartItems.reduce((total, item) => total + (item.bucketPrice || 0), 0);
};

// Calculate total cart price
const buckettPriceinCartItem = getBucketPrice();
// console.log("Bucket Price in CartItemSample : ---------> ", buckettPriceinCartItem);

const bucketpriceintoquantity = (buckettPriceinCartItem * quantity).toString(); // Convert to string


const getTotalCartPrice = () => {
  // Use reduce to sum up totalCartPriceocvf from each item
  return cartItems.reduce((total, item) => total + (item.totalCartPriceocvf || 0), 0);
};

// Calculate total cart price
const totalCartPrice = getTotalCartPrice();

  const onHidePortal = () => {
    setLoginPortal(false);
  };

    const uniqueProductIds = [
        ...new Set(cartItems.map((item) => item.productId)),
      ];
    const totalUniqueItems = uniqueProductIds.length;
    const calculateTotalPrice = () => {
      return cartItems.reduce((total, item) => {
        return total + item.salePrice;
      }, 0);
    };
    const navigate = useNavigate();

    const product = cartItems.find(item => item.productId === productId);
    console.log(product);
    // const productff = cartItems.find(item => item.bucketSize === bucketSize);

// Extract the totalCartPriceocvf for the found item
const totalCartPriceForProduct = product ? product.totalCartPriceocvf : 0;
const bucketSizevd= product ? product.bucketSize : 0
const svfjl =  product ? product.bucketPrice : 0

// const totalvalue = svfjl * bucketSizevd * quantity;
// const bucketSizegrsfbd = productff ? productff.bucketSize : 0;
const calculateTotalValue = () => {
    if (quantity >= 11) {
      return svfjl * bucketSizevd;
    } else {
      return svfjl * bucketSizevd * quantity;
    }
  };

  const totalvalue = calculateTotalValue();


  useEffect(() => {
    console.log("Quantity changed:", quantity);
    console.log("Total value is:", totalvalue);
  }, [quantity, totalvalue]);
    
  
    const totalPrice = calculateTotalPrice();
  
    const handleCreateOrder = async () => {
        const userToken = localStorage.getItem("userToken");
        if (!userToken) {
          // Handle case where user is not logged in
          setLoginPortal(true); // Assuming this sets state to show login portal
          return;
        }
    
        const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        console.log("localCartItems :: ", localCartItems);
    
        var itemList = [];
        let bucketId = null;
        for (var item of localCartItems) { // Use 'of' to iterate over array elements
          var obj = {};
          
          obj["item_id"] = item["productId"];
          console.log("Cart Items While Loggin in handleCreateOrder Function in CartitemSampleLoggedin.jsx")
          console.log("Quantity in localCartitems :  --------------> ", item["quantity"]);
          obj["quantity"] = item["quantity"];
          obj["bucket_id"] = item["bucketId"]; // Use bucket_id
    
          console.log("Bucketid in localCartitems :  --------------> ", item["bucketId"]);
    
          if (bucketId === null) {
            bucketId = item["bucketId"]; // Set bucketId if it's null
          }
    
          itemList.push(obj);
        }
        console.log("itemList :: ", itemList);
        console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
        console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
        console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
        console.log("itemList ---------------------------------------------------------------------->:: ", itemList);
        const ompuserId = localStorage.getItem("id");
        const customer_first_name =
          localStorage.getItem("firstName") ;
        const customer_last_name =
          localStorage.getItem("lastName");
        const customer_contact_no =
          localStorage.getItem("number") ;
        const customer_email =
          localStorage.getItem("emailId") ;
    
        const requestBody = {
          items: itemList,
          bucket_id: bucketId, 
          ompuserId, //get the id from the localStorage
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: "",
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
          },
          gst_no: "",
        };
    
        try {
          console.log(
            "requestBody  ------------------------------------------------------------- > :: ",
            requestBody
          );
          // Create order request
          const createOrderResponse = await fetch(
            "https://ompextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );
    
          if (!createOrderResponse.ok) {
            throw new Error("Failed to create order");
          }
    
          const createOrderData = await createOrderResponse.json();
          const draftItems = createOrderData.line_items || []; // Assuming draft items are in line_items array
          console.log("Created order with items:", draftItems);
    
          // Clear local storage cartItems after successful order creation
          // localStorage.removeItem('cartItems');
          setCartItems([]); // Clear cart items state
        } catch (error) {
          console.error("Error creating order:", error);
        }
      };
    
    const handleProceedPaymentbtn = () => {
        const loggedin = !!localStorage.getItem("userToken");
        if (loggedin) {
          // handleCreateOrder();
          navigate("/manageaddresspart");
        } else {
          setLoginPortal(true);
        }
      };
    
      const [loginPortal, setLoginPortal] = useState(false);
  
    
    useEffect(() => {
        // Save the quantity to localStorage specific to the product whenever it changes
        localStorage.setItem(`quantity_${productId}`, quantity);
    }, [quantity, productId]);

    const AvailableStock = productDETAILS[0]?.available_stock || "N/A;";
    // let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    
    
  const handleIncreaseQuantity = () => {
    if (endQuantity === null || quantity >= 12) {
      console.log("Cannot increase quantity further.");
      return; // Exit the function if endQuantity is null or quantity is 12 or more
    }

    setQuantity((prevQuantity) => {
      const updatedQuantity = prevQuantity + 1;
      const newEndQuantity = getEndQuantityForQuantity(updatedQuantity);

      // Find the cart item to update
      const updatedCartItems = cartItems.map(item => {
        if (item.productId === productId) {
          // Calculate new total value
          const newTotalCartPrice = item.bucketSize * item.bucketPrice * updatedQuantity;

          // Return updated item
          return {
            ...item,
            quantity: updatedQuantity,
            totalCartPriceocvf: newTotalCartPrice,
            endQuantity: newEndQuantity
          };
        }
        return item;
      });

      // Update state and localStorage
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

      // Reload the page after a short delay (optional)
      setTimeout(() => {
        window.location.reload();
      }, 100);

      return updatedQuantity; // Return updated quantity
    });
  };

  const getEndQuantityForQuantity = (quantity) => {
    if (quantity >= 1 && quantity <= 3) return 3;
    if (quantity >= 4 && quantity <= 6) return 6;
    if (quantity >= 7 && quantity <= 10) return 10;
    if (quantity >= 11) return null; // Beyond 11, endQuantity should be null
    return 0;
  };


    
    // const handleDecreaseQuantity = () => {
    //   setQuantity((prevQuantity) => {
    //     // Calculate the updated quantity (minimum 1)
    //     const updatedQuantity = Math.max(prevQuantity - 1, 1);//it will never go below 1 
    
    //     // Find the cart item to update
    //     const updatedCartItems = cartItems.map(item => {
    //       if (item.productId === productId) {
    //         // Calculate new total value
    //         const newTotalCartPrice = item.bucketSize * item.bucketPrice * updatedQuantity;
    
    //         // Return updated item
    //         return {
    //           ...item,
    //           quantity: updatedQuantity,
    //           totalCartPriceocvf: newTotalCartPrice
    //         };
    //       }
    //       return item;
    //     });
    
    //     // Update state and localStorage
    //     setCartItems(updatedCartItems);
    //     localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    
    //     // Set loading state and reload page
        
    //     setTimeout(() => {
    //       // Uncomment this line to stop loading spinner
    //       // Avoid reloading the page if possible
    //       window.location.reload();
    //     }, 100);
    
    //     return updatedQuantity; // Return updated quantity
    //   });
    // };
    


    
    
    
    // const handleDelete = (productId) => {
    //     let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    //     cartItems = cartItems.filter(cartItem => cartItem.productId !== productId);
    //     localStorage.setItem('cartItems', JSON.stringify(cartItems));
    //     console.log(`Item with productId ${productId} deleted. Total items in cart: ${cartItems.length}`);
    //     window.location.reload();
    // };


    const handleDecreaseQuantity = () => {
  setQuantity((prevQuantity) => {
    // Calculate the updated quantity
    const updatedQuantity = Math.max(prevQuantity - 1, 0); // Allow quantity to go to 0

    // If the updated quantity is 0, delete the item
    if (updatedQuantity === 0) {
      handleDelete(productId);
    } else {
      // Find the cart item to update
      const updatedCartItems = cartItems.map(item => {
        if (item.productId === productId) {
          // Calculate new total value
          const newTotalCartPrice = item.bucketSize * item.bucketPrice * updatedQuantity;

          // Return updated item
          return {
            ...item,
            quantity: updatedQuantity,
            totalCartPrice: newTotalCartPrice
          };
        }
        return item;
      });

      // Update state and localStorage
      setCartItems(updatedCartItems);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

      // Set loading state and reload page
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }

    return updatedQuantity; // Return updated quantity
  });
};

useEffect(() =>{
if(quantity === 0){
  handleDelete(productId);
}
},[quantity,productId]);

const handleDelete = (productId) => {
  let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  cartItems = cartItems.filter(cartItem => cartItem.productId !== productId);
  localStorage.setItem('cartItems', JSON.stringify(cartItems));
  console.log(`Item with productId ${productId} deleted. Total items in cart: ${cartItems.length}`);
  window.location.reload();
};

    const updateFormattedPrice = () => { // is used to update how the price of a product is displayed on a webpage
        setProductDETAILS((prevDetails) => ({ // it takes the current details of the product(prevdetails)and creates a new set of details with an updated formatted price
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                // amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
                // delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
                machine_price: formatCurrency(prevDetails.sales_rate),
            },
        }));
    };

    const formatCurrency = (value) => { // like to show in Indian rupees it adds commas where needed and shows the right number of decimal place
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top on component mount or update
        const fetchData = async () => {
            let id = localStorage.getItem('id');
            if (id === null) {
                id = "";
            }
            try {
                // Performs a GraphQL query using the Apollo client 'client'
                const { data } = await client.query({ query: GET_SPARE_DETAILS, variables: { groupId: groupId || '', itemId: productId || '' } });
                // Sets the fetched data to state variable 'productDETAILS'
                setProductDETAILS(data?.inventoryItem?.response);
                // Sets 'loading' state to false after data is fetched
                setLoading(false);
                // Updates formatted price
                updateFormattedPrice();
            } catch (error) {
                // Handle error
            }
        };
        fetchData();
    }, [groupId, productId]);

    console.log("Cart Items in local Storage :    :  -------------------------------------->", cartItems);

    useEffect(() => {
      cartItems.forEach(item => {
        console.log(`"totalCartPriceocvf" for productId ${item.productId}: ${item.totalCartPriceocvf}`);
      });
    }, []);
    return (
        <>
         {loading && <Loader />}
        
        <div className="threesectionsofcart">
            <div className="firstsectionofcart">
                <img className="imageonleftofcart" src={productDETAILS[0]?.images?.productImages[0]?.url || "N/A"} alt="Product" />
            </div>
            <div className="secondsectionofcart">
                <h1 className="headingpartofcarttt">
                    {productDETAILS[0]?.name || "N/A"}
                   
                </h1>
                <span className="stockbold">  {productDETAILS[0]?.brand || "N/A"}</span>
                <div className="contentofcartpara">
                    <p className="inStocktext">
                        <span className="inStocktextcontent">In Stock</span>
                        <br></br>
                        <span className="stockbold"> Bucket : </span>
                        {/* {bucketSizegrsfbd} */}
                        {product ? `${product.bucketSize} Ltrs` : 'Product not found'}
                    </p>
                </div>
                <div className="buttonsgapbetweentwocart">
                    <span className="quantitytextright">Qty{" "}</span>
                    <div className="quantity-selector-wrapper">
                        <div className="quantity-selector">
                            <button onClick={handleDecreaseQuantity} className="quantity-button">-</button>
                            <span className="quantity-value">{quantity}</span>
                            <button onClick={handleIncreaseQuantity}
                             disabled={endQuantity === null || quantity >= 11}  className="quantity-button">+</button>
                        </div>
                    </div>
                    <button className="deletecartbtn" onClick={() => handleDelete(productId)}>Delete</button>
                </div> 
            </div>
            <div className="thirdsectionofcart">
                <p className="badgerightside">
                    <span className="salespricecart">₹{totalvalue}</span>
                </p>
            </div>
            <div className='basespace'></div>
            
        </div>
        


      {loginPortal && <LoginModel onHide={onHidePortal} />}

         </>
    );
};

export default CartItemSample;



   {/* <div className="rifhtsidebortertotaldivcontent">
        <p>
          <div class="meter-container">
            <div class="meter">
              <div class="meter-fill" style={{ width: "80%" }}></div>
            </div>
          </div>
          <IoCheckmarkDoneSharp className="ioiconscc" size={28} /> &nbsp;
          <span className="orderdetailscart">
            Your order is ready for processing.{" "}
          </span>
          <br></br>
        </p>
        <p className="summaryContent">
          <span className="summaryItem">
            <span className="label labledddd">Summary</span>
          </span>
          <span className="summaryItem">
            <span className="label">Total quantity:</span>
            <span className="value">{totalUniqueItems}</span>
          </span>
    
          <span className="summaryItem">
                <span className="label">MRP Value:</span>
                <span className="value valuesdbf">₹{totalPrice}</span>
              </span>

          <span className="summaryItem">
            <span className="label">Our Price:</span>
            <span className="value">
              ₹{totalPrice}
            </span>
          </span>
        </p>
      </div>

      <div className="proceddcartbuttondiv">
        <button className="proceedcartbtn" onClick={handleProceedPaymentbtn}>
          Proceed to Payment
        </button>
      </div> */}
