import React, { useState, useEffect } from "react";
import "./Form.css";
import { Container } from "react-bootstrap";
import Button from "../Button/Button";
import Textarea from "@mui/joy/Textarea";
import { gql } from "graphql-tag";
import client from "../Services/ServicesPopup/apolloclient";
import SuccessPopup from "../SubComponent/AllBlock/SuccessPopup";
import { useLocation } from "react-router-dom";
const CREATE_CONTACT_US = gql`
  mutation createServiceRequest($requestinput: ServiceRequestInput!) {
    createServiceRequest(requestinput: $requestinput) {
      servicerequest {
        id
        firstName
        phoneNumber
        appAmcPlanId
        appAmcPlanName
      }
      message
      success
    }
  }
`;
const ContactFormtwo = ({
  selectedCategoryType,
  enteredPincode,
  OthersValue,
  serviceName,
  selectedLocationin,
  selectedcategoryin,
  servicetype,
  requestTType,
  id,
  plan,
  ammmccid,
  plaaaan
}) => {
  const location = useLocation();

  const getServiceLocation = () => {
    // Check if enteredPincode has a value
    if (enteredPincode) {
      return enteredPincode;
    }
  
    // Otherwise, check if selectedLocationin has a value
    if (selectedLocationin) {
      return selectedLocationin;
    }
  
    // Fallback value if neither is available
    return '';  // or some default value
  };


  console.log("Selected Location from pmc page : ",selectedLocationin);
  console.log("Selected Category from pmc page : ",selectedLocationin);
  console.log("Id in localStorage",localStorage.getItem("amcid"));

  const amcid = localStorage.getItem('amcid');
  console.log(" name changed in to ",amcid);

  console.log("Plan in Local Storage : ",localStorage.getItem("plan"));
  const amcplanname = localStorage.getItem('plan');
  console.log(" amcplanname changed in to ",amcplanname);


    
  console.log("Id in localStorage",localStorage.getItem("amccid"));
  console.log("Plan in Local Storage : ",localStorage.getItem("pleean"));

  const formOrderFromState = location.state?.formOrder || "default";

  const [formOrder, setFormOrder] = useState(formOrderFromState);
  console.log(
    "Form Order in ProductBlockCard :",
    localStorage.getItem("formOrder")
  );


  const preparePayload = () => {
    // Get the current URL
    const currentUrl = window.location.pathname;
  
    let amcPlanId;
    let amcPlanName;
  
    if (currentUrl === '/service/annualpage') {
      // On /service/annualpage, use amcid and amcplanname
      amcPlanId = localStorage.getItem("amcid");
      amcPlanName = localStorage.getItem("amcplanname");
    } else if (currentUrl === '/Addonservice') {
      // On /Addonservice, use amccid and pleean
      amcPlanId = localStorage.getItem("amccid");
      amcPlanName = localStorage.getItem("pleean");
    }
  
    // Prepare the payload
    const payload = {
      appamcplanid: amcPlanId || '',
      appamcplanname: amcPlanName || ''
    };
  
    return payload;
  };

  // console.log("selectedCategoryType in Service Request"  ,selectedCategoryType);
  // console.log("enteredPincode in Service Request"  ,enteredPincode);
  // console.log("OthersValue in Service Request"  ,OthersValue);
  // console.log("serviceName in Service Request"  ,serviceName);

  const requeesttype = localStorage.getItem("requestTType");
  console.log("requestTType in Service Request", requeesttype);
  // console.log("requestTType in Specifc Form Page  :",requestTType);
  // console.log('selectedCategoryType====>', selectedCategoryType);
  // console.log('enteredPincode=====>', enteredPincode);
  // console.log('OthersValue=====>', OthersValue);
  const [phone, setphone] = useState("");
  const [email, setEmail] = useState("");
  const [Factory, setFactory] = useState("");
  const [MachineName, setMachineName] = useState("");
  const [MachineProblem, setMachineProblem] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [ModelName, setModelName] = useState("");
  const [mfgYear, setmfgYear] = useState("");
  const [WorkingCondition, setWorkingCondition] = useState("");
  const [warrenty, setwarrenty] = useState("");
  const [amcplan, setamcplan] = useState("");
  const [description, setDescription] = useState("");
  const [reason, setReason] = useState("");
  const [validation, setvalidaion] = useState({
    factvalidation: false,
    Machinenamevalidation: false,
    fvalidation: false,
    lvalidation: false,
    evalidation: false,
    pvalidation: false,
    mvalidation: false,
    mfgYearvalidation: false,
    dvalidation: false,
    workingvalidation: false,
    warrentyvalidation: false,
    amcplanvalidation: false,
    machinevalidation: false,
  });
  const [showModel, setShowModel] = useState(false);
  // const id=localStorage.getItem('id')
  // console.log("id===>>>",id);
  const onMachineChange = (e) => {
    const newInputString = e.target.value;
    setMachineName(newInputString);
  };
  const onmachineProblemChange = (e) => {
    const newInputString = e.target.value;
    setMachineProblem(newInputString);
  };
  const onphoneChange = (e) => {
    // Get the current value from the event target
    const newInputString = e.target.value;
    
    // Sanitize the input to remove non-numeric characters
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");
    
    // Limit the input to a maximum of 4 digits
    const limitedInput = sanitizedInput.slice(0, 10);
    
    // Update the state with the sanitized and limited input
    setphone(limitedInput);
  };
  
  const onAMCchange = (e) => {
    setamcplan(e.target.value);
  };
  const onwarrentychange = (e) => {
    setwarrenty(e.target.value);
  };
  const onworkingconditionchange = (e) => {
    setWorkingCondition(e.target.value);
  };
  const onmfgyearchange = (e) => {
    // Get the current value from the event target
    const newInputString = e.target.value;
    
    // Sanitize the input to remove non-numeric characters
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");
  
    // Limit the input to a maximum of 4 digits
    const limitedInput = sanitizedInput.slice(0, 4);
  
    // Update the state with the sanitized and limited input
    setmfgYear(limitedInput);
  };
  const onmodelChange = (e) => {
    setModelName(e.target.value);
  };
  const onFactoryChange = (e) => {
    setFactory(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onHide = () => {
    setShowModel(false);
  };
  const onFirstnameChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (newInputString === "" || sanitizedInput === newInputString) {
      setfirstname(sanitizedInput);
    }
  };
  const onLastname = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (newInputString === "" || sanitizedInput === newInputString) {
      setlastname(sanitizedInput);
    }
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    if (serviceName === "AMC") {
      setamcplan("Yes");
    }
  }, [serviceName]);

  const onSubmitHandler = async () => {
    // Validate mandatory fields
    if (firstname === "" || lastname === "" || phone === "") {
      // Check and set validation for mandatory fields
      if (firstname === "") {
        setvalidaion((prev) => ({
          ...prev,
          fvalidation: true,
        }));
      }
      if (lastname === "") {
        setvalidaion((prev) => ({
          ...prev,
          lvalidation: true,
        }));
      }
      if (phone === "" ) {
        setvalidaion((prev) => ({
          ...prev,
          pvalidation: true,
        }));
      }

      // Scroll to top of the page
      window.scrollTo(0, 0);
      return;
    }

    // Additional validation for other fields if needed
    if (email) {
      if (email.length < 8) {
        setvalidaion((prev) => ({
          ...prev,
          evalidation: true,
        }));
        return;
      } else {
        const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const isValid = emailPattern.test(email);

        setvalidaion((prev) => ({
          ...prev,
          evalidation: !isValid,
        }));
        if (!isValid) return;
      }
    }

    try {
      const customerrid = localStorage.getItem("id");
      const serviceLocation = getServiceLocation();
      console.log("Customer id  : ", customerrid);
      const { data } = await client.mutate({
        mutation: CREATE_CONTACT_US,
        variables: {
          requestinput: {
            customerid: customerrid,
            firstname: firstname,
            lastname: lastname,
            emailid: email,
            phonenumber: phone,
            machinename: MachineName,
            machineproblem: MachineProblem,
            servicelocation: serviceLocation,
            description: description,
            servicetype: serviceName,
            factoryname: Factory,
            machinecategory:
              selectedCategoryType === "Others"
                ? OthersValue
                : selectedCategoryType,
            modelname: ModelName,
            mfgyear: null,
            machineworkingcondition: WorkingCondition,
            machineinwarranty: warrenty,
            needamc: amcplan,
            requesttype: "Request Callback",
            pincode: null,
            serialno: "",
            companyname: "",
            address: null,
            appamcplanid: amcid || "",
            appamcplanname: amcplanname || "",
          },
        },
      });

      console.log("API Response==>", data);
      if (data) {
        setShowModel(true); // Show success popup
        // Clear form fields if needed
        setFactory("");
        setMachineName("");
        setphone("");
        setModelName("");
        setmfgYear("");
        setMachineProblem("");
        setWorkingCondition("");
        setwarrenty("");
        setfirstname("");
        setlastname("");
        setEmail("");
        setReason("");
        setDescription("");
      }
    } catch (error) {
      console.error("API Error==>", error.message);
    }
  };

  return (
    <>
      {showModel ? (
        <SuccessPopup
          onHide={onHide}
          message={"Thankyou. Our team will reach out to you soon"}
        />
      ) : null}
      <div
        className="container contactContainer bg-white border border-grey border-1 ms-5"
        style={{ boxShadow: " 0px 2px 6px 0px rgba(0, 0, 0, 0.12)" }}
      >
        <div>
          <h1 className="heading-600-24-16">
            {" "}
            Please Provide Your Details For Requesting a callback
          </h1>
        </div>
        <div className="row">
          {/* <div className="col-lg-12 col-md-12 col-sm-12">
              <div className={`bi-form-group ${validation.factvalidation ? "error-red" : ""}`}>
                <input type="email" name="email" i className={`bi-form-field bg-white ${validation.factvalidation ? "error-red" : ""}`} placeholder="Factory Name" value={Factory}
                  onChange={onFactoryChange} onClick={() => setvalidaion(prev => ({ ...prev, factvalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Factory Name{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className={`bi-form-group ${validation.Machinenamevalidation ? "error-red" : ""}`}>
                <input type="email" name="email"  className={`bi-form-field bg-white ${validation.Machinenamevalidation ? "error-red" : ""}`} placeholder="Machine Name" value={MachineName}
                  onChange={onMachineChange} onClick={() => setvalidaion(prev => ({ ...prev, Machinenamevalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Machine Name{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="">
                
                <div className={`bi-form-group ${validation.fvalidation ? "error-red" : ""}`}>
                  <input type="text" name="name" id="name" className={`bi-form-field bg-white ${validation.fvalidation ? "error-red" : ""}`} placeholder="Name" value={firstname}
                    onChange={onFirstnameChange} onClick={() => setvalidaion(prev => ({ ...prev, fvalidation: false }))} />
                  <label htmlFor="name" className="heading-400-14-12 bi-form-label">First Name{<span style={{ color: '#CB1923' }}>*</span>}</label>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="">
                <div className={`bi-form-group ${validation.lvalidation ? "error-red" : ""}`}>
                  <input type="text" name="name" id="lastname" className={`bi-form-field bg-white ${validation.lvalidation ? "error-red" : ""}`} placeholder="Last" value={lastname}
                    onChange={onLastname} onClick={() => setvalidaion(prev => ({ ...prev, lvalidation: false }))} />
                  <label htmlFor="name" className="heading-400-14-12 bi-form-label">Last Name{<span style={{ color: '#CB1923' }}>*</span>}</label>
  
                </div>
  
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className={`bi-form-group ${validation.evalidation ? "error-red" : ""}`}>
                <input type="email" name="email"  className={`bi-form-field bg-white ${validation.evalidation ? "error-red" : ""}`} placeholder="Email" value={email}
                  onChange={onEmailChange} onClick={() => setvalidaion(prev => ({ ...prev, evalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Email Id{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className={`bi-form-group ${validation.pvalidation ? "error-red" : ""}`}>
                <input type="email" name="email"  maxLength="10" className={`bi-form-field bg-white ${validation.pvalidation ? "error-red" : ""}`} placeholder="Email" value={phone}
                  onChange={onphoneChange} onClick={() => setvalidaion(prev => ({ ...prev, pvalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Phone No{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
              <div className={`bi-form-group ${validation.mvalidation ? "error-red" : ""}`}>
                <input type="text" name="MachineProblem" id="MachineProblem" className={`bi-form-field bg-white ${validation.mvalidation ? "error-red" : ""}`} placeholder="Model Name" value={ModelName}
                  onChange={onmodelChange} onClick={() => setvalidaion(prev => ({ ...prev, mvalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Model Name{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
              <div className={`bi-form-group ${validation.mfgYearvalidation ? "error-red" : ""}`}>
                <input type="text" name="MachineProblem" id="MachineProblem" className={`bi-form-field bg-white ${validation.mfgYearvalidation ? "error-red" : ""}`} placeholder="Mfg Year" value={mfgYear}
                  onChange={onmfgyearchange} onClick={() => setvalidaion(prev => ({ ...prev, mfgYearvalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Mfg Year{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <div className={`bi-form-group ${validation.workingvalidation ? "error-red" : ""}`}>
                <select className={`bi-form-field  ${WorkingCondition ? "" : "empty"}`} onChange={onworkingconditionchange}  onClick={() => setvalidaion(prev => ({ ...prev, workingvalidation: false }))} style={{ borderRadius: "4px " }} value={WorkingCondition} placeholder="Select Machine Working Condition" autoCapitalize='off' >
                  <option value="" disabled></option>
                  <option value="Yes">In working condition </option>
                  <option value="No">Not in working condition </option>
  
                </select>
                <label className="heading-400-14-12 bi-form-label" >Select Machine Working Condition{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <div className={`bi-form-group ${validation.warrentyvalidation ? "error-red" : ""}`}>
                <select className={`bi-form-field  ${warrenty ? "" : "empty"}`} onChange={onwarrentychange}  onClick={() => setvalidaion(prev => ({ ...prev, warrentyvalidation: false }))}style={{ borderRadius: "4px " }} value={warrenty} placeholder="Select Machine Working Condition" autoCapitalize='off' >
                  <option value="" disabled></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
  
                </select>
                <label className="heading-400-14-12 bi-form-label" >Is Machine in Warranty ?{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
  
            {serviceName !== "AMC" && (
              <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                <div className={`bi-form-group ${validation.amcplanvalidation ? "error-red" : ""}`}>
                  <select className={`bi-form-field  ${amcplan ? "" : "empty"}`} onChange={onAMCchange}  style={{ borderRadius: "4px " }} onClick={() => setvalidaion(prev => ({ ...prev, amcplanvalidation: false }))} value={amcplan} placeholder="Select Machine Working Condition" autoCapitalize='off' >
                    <option value="" disabled></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
  
                  </select>
                  <label className="heading-400-14-12 bi-form-label" >Do you already have an AMC ?{<span style={{ color: '#CB1923' }}>*</span>}</label>
                </div>
              </div>
            )}
  
            <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
              <div className={`bi-form-group ${validation.mvalidation ? "error-red" : ""}`}>
                <Textarea minRows={5}  placeholder="" name="MachineProblem" id="MachineProblem"  value={MachineProblem}
                  onChange={onmachineProblemChange} onClick={() => setvalidaion(prev => ({ ...prev, mvalidation: false }))} />
                <label htmlFor="email" className="heading-400-14-12 bi-form-label">Describe your machine problem{<span style={{ color: '#CB1923' }}>*</span>}</label>
              </div>
            </div>
      */}

          {/* Jumbled order */}

          <div className="col-lg-6 col-md-6 col-12">
            <div className="">
              <div
                className={`bi-form-group ${
                  validation.fvalidation ? "error-red" : ""
                }`}
              >
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={`bi-form-field bg-white ${
                    validation.fvalidation ? "error-red" : ""
                  }`}
                  placeholder="Name"
                  value={firstname}
                  onChange={onFirstnameChange}
                  onClick={() =>
                    setvalidaion((prev) => ({ ...prev, fvalidation: false }))
                  }
                />
                <label
                  htmlFor="name"
                  className="heading-400-14-12 bi-form-label"
                >
                  First Name{<span style={{ color: "#CB1923" }}>*</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="">
              <div
                className={`bi-form-group ${
                  validation.lvalidation ? "error-red" : ""
                }`}
              >
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  className={`bi-form-field bg-white ${
                    validation.lvalidation ? "error-red" : ""
                  }`}
                  placeholder="Last"
                  value={lastname}
                  onChange={onLastname}
                  onClick={() =>
                    setvalidaion((prev) => ({ ...prev, lvalidation: false }))
                  }
                />
                <label
                  htmlFor="lastname"
                  className="heading-400-14-12 bi-form-label"
                >
                  Last Name{<span style={{ color: "#CB1923" }}>*</span>}
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className={`bi-form-group ${
                validation.pvalidation ? "error-red" : ""
              }`}
            >
              <input
                type="text"
                name="phonenumber"
                id="phonenumber"
                maxLength="10"
                className={`bi-form-field bg-white ${
                  validation.pvalidation ? "error-red" : ""
                }`}
                placeholder="phonenumber"
                value={phone}
                onChange={onphoneChange}
                onClick={() =>
                  setvalidaion((prev) => ({ ...prev, pvalidation: false }))
                }
              />
              <label
                htmlFor="phonenumber"
                className="heading-400-14-12 bi-form-label"
              >
                Phone No{<span style={{ color: "#CB1923" }}>*</span>}
              </label>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className={`bi-form-group ${
                validation.factvalidation ? "error-red" : ""
              }`}
            >
              <input
                type="text"
                name="FactoryName"
                id="FactoryName"
                className={`bi-form-field bg-white ${
                  validation.factvalidation ? "error-red" : ""
                }`}
                placeholder="Factory Name"
                value={Factory}
                onChange={onFactoryChange}
                onClick={() =>
                  setvalidaion((prev) => ({ ...prev, factvalidation: false }))
                }
              />
              <label
                htmlFor="FactoryName"
                className="heading-400-14-12 bi-form-label"
              >
                Factory Name
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className={`bi-form-group ${
                validation.Machinenamevalidation ? "error-red" : ""
              }`}
            >
              <input
                type="text"
                name="MachineName"
                id="MachineName"
                className={`bi-form-field bg-white ${
                  validation.Machinenamevalidation ? "error-red" : ""
                }`}
                placeholder="Machine Name"
                value={MachineName}
                onChange={onMachineChange}
                onClick={() =>
                  setvalidaion((prev) => ({
                    ...prev,
                    Machinenamevalidation: false,
                  }))
                }
              />
              <label
                htmlFor="MachineName"
                className="heading-400-14-12 bi-form-label"
              >
                Machine Name
              </label>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <div
              className={`bi-form-group ${
                validation.evalidation ? "error-red" : ""
              }`}
            >
              <input
                type="email"
                name="email"
                id="email"
                className={`bi-form-field bg-white ${
                  validation.evalidation ? "error-red" : ""
                }`}
                placeholder="Email"
                value={email}
                onChange={onEmailChange}
                onClick={() =>
                  setvalidaion((prev) => ({ ...prev, evalidation: false }))
                }
              />
              <label
                htmlFor="email"
                className="heading-400-14-12 bi-form-label"
              >
                Email Id
              </label>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div
              className={`bi-form-group ${
                validation.mvalidation ? "error-red" : ""
              }`}
            >
              <input
                type="text"
                name="ModelNo"
                id="ModelNo"
                className={`bi-form-field bg-white ${
                  validation.mvalidation ? "error-red" : ""
                }`}
                placeholder="Model Name"
                value={ModelName}
                onChange={onmodelChange}
                onClick={() =>
                  setvalidaion((prev) => ({ ...prev, mvalidation: false }))
                }
              />
              <label
                htmlFor="ModelNo"
                className="heading-400-14-12 bi-form-label"
              >
                Model Name
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div
              className={`bi-form-group ${
                validation.mfgYearvalidation ? "error-red" : ""
              }`}
            >
              <input
                type="text"
                name="MachineProblem"
                id="MachineProblem"
                className={`bi-form-field bg-white ${
                  validation.mfgYearvalidation ? "error-red" : ""
                }`}
                placeholder="Mfg Year"
                value={mfgYear}
                onChange={onmfgyearchange}
                onClick={() =>
                  setvalidaion((prev) => ({
                    ...prev,
                    mfgYearvalidation: false,
                  }))
                }
                maxLength="4" 
              />
              <label
                htmlFor="MachineProblem"
                className="heading-400-14-12 bi-form-label"
              >
                Mfg Year
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div
              className={`bi-form-group ${
                validation.workingvalidation ? "error-red" : ""
              }`}
            >
              <select
                className={`bi-form-field  ${WorkingCondition ? "" : "empty"}`}
                onChange={onworkingconditionchange}
                onClick={() =>
                  setvalidaion((prev) => ({
                    ...prev,
                    workingvalidation: false,
                  }))
                }
                style={{ borderRadius: "4px " }}
                value={WorkingCondition}
                placeholder="Select Machine Working Condition"
                autoCapitalize="off"
              >
                <option value="" disabled></option>
                <option value="Yes">In working condition </option>
                <option value="No">Not in working condition </option>
              </select>
              <label className="heading-400-14-12 bi-form-label">
                Select Machine Working Condition
              </label>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <div
              className={`bi-form-group ${
                validation.warrentyvalidation ? "error-red" : ""
              }`}
            >
              <select
                className={`bi-form-field  ${warrenty ? "" : "empty"}`}
                onChange={onwarrentychange}
                onClick={() =>
                  setvalidaion((prev) => ({
                    ...prev,
                    warrentyvalidation: false,
                  }))
                }
                style={{ borderRadius: "4px " }}
                value={warrenty}
                placeholder="Select Machine Working Condition"
                autoCapitalize="off"
              >
                <option value="" disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label className="heading-400-14-12 bi-form-label">
                Is Machine in Warranty ?
              </label>
            </div>
          </div>

          {serviceName !== "AMC" && (
            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
              <div
                className={`bi-form-group ${
                  validation.amcplanvalidation ? "error-red" : ""
                }`}
              >
                <select
                  className={`bi-form-field  ${amcplan ? "" : "empty"}`}
                  onChange={onAMCchange}
                  style={{ borderRadius: "4px " }}
                  onClick={() =>
                    setvalidaion((prev) => ({
                      ...prev,
                      amcplanvalidation: false,
                    }))
                  }
                  value={amcplan}
                  placeholder="Select Machine Working Condition"
                  autoCapitalize="off"
                >
                  <option value="" disabled></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <label className="heading-400-14-12 bi-form-label">
                  Do you already have an AMC ?
                </label>
              </div>
            </div>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
            <div
              className={`bi-form-group ${
                validation.mvalidation ? "error-red" : ""
              }`}
            >
              <Textarea
                minRows={5}
                placeholder="Describe your machine problem"
                name="MachineProblemDe"
                id="MachineProblemDe"
                value={MachineProblem}
                onChange={onmachineProblemChange}
                onClick={() =>
                  setvalidaion((prev) => ({ ...prev, mvalidation: false }))
                }
              />
              {/* <label
                htmlFor="MachineProblemDe"
                className="heading-400-14-12 bi-form-label"
              >
                Describe your machine problem
              </label> */}
            </div>
          </div>
          {/* ... other fields in jumbled order */}

          <Container className="d-f-l mb-4">
            <Button message="Submit" callFunction={onSubmitHandler} />
          </Container>
        </div>
      </div>
    </>
  );
};
export default ContactFormtwo;
