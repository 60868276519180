import React from 'react'

const AllOrigaService = ({imageurl}) => {
  return (
    <div className="col">
       <img src={imageurl} alt={imageurl} />
     </div>
  )
}

export default AllOrigaService