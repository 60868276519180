import React, { useEffect, useState } from "react";
import "./style.css";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LeftArrow from "../SubComponent/LeftArrow";
import Slash from "../SubComponent/Slash";
import Button from "../Button/Button";
import Breadcrumbs from "../SubComponent/Breadcrumbs";
import { TextField } from "@mui/material";
import Loader from "../SubComponent/Loader";
const AmcFirstSectionPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [loadinged, setLoadinged] = useState(false);
  const [serviceAreaMessage, setServiceAreaMessage] = useState("");
  const [pincode, setPincode] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [model, setModel] = useState("");
  const [SerialNo, setSerialNo] = useState("");
  const [enteredPincode, setEnteredPincode] = useState("");

  const onModelChange = (event) => {
    setErrorMessage("");
    setModel(event.target.value);
  };

  const onSerialNoChange = (event) => {
    const newInputString = event.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (newInputString === "" || sanitizedInput === newInputString) {
      setSerialNo(sanitizedInput);
    }
  };

  const onPincodeChange = (event) => {
    setErrorMessage("");
    setEnteredPincode(event.target.value);
  };

  const onLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const [firstname, setfirstname] = useState("");
  const [serialNo, setSerialNumber] = useState("");
  const [modellNo, setModeelNo] = useState("");
  const onModelnameChange = (e) => {
    setModeelNo(e.target.value);
  };

  const serialNumberCalling = (e) => {
    setSerialNumber(e.target.value);
  };

  const onClickehandle = () => {

    if (!selectedCategory) {
      setMessage("Please Select Category Type..!");
    } else if (!selectedLocation) {
      setMessage("Please Select a Location..!");
    } else {
      // Reset any existing messages
      setMessage("");

      // Store the selected values in localStorage
      localStorage.setItem("selectedCategory", selectedCategory);
      localStorage.setItem("selectedLocation", selectedLocation);
      localStorage.setItem("ModelNo ", modellNo);
      localStorage.setItem("serialNo", serialNo);
      console.log("selectedCategory", selectedCategory);
      console.log("Selected Location :  ", selectedLocation);
      console.log("modelno : ", modellNo);
      console.log("serialNo", serialNo);

      // Define categories that should navigate to /service/annual
      const annualServiceCategories = [
        "CNC Turning",
        "CNC Vertical machine Center (VMC)",
        "CNC Horizontal machining center",
        "CNC Turnmill Machine",
      ];
  
      // Navigate based on the selected category type
      if (annualServiceCategories.includes(selectedCategory)) {
        // Navigate to the annual service page for specific categories

        setLoadinged(true);
setTimeout(() => {
  navigate("/service/annualpage", {
    state: {
      selectedCategory,
      selectedLocation,
      firstname,
      serialNo,
      modellNo,
    },
  });
}, 300);
        // navigate("/service/annualpage", {
        //   state: {
        //     selectedCategory,
        //     selectedLocation,
        //     firstname,
        //     serialNo,
        //     modellNo,
        //   },
        // });
      } else {
        // Navigate to the ServiceRequest page for other categories
        setLoadinged(true);
setTimeout(() => {
  navigate("/ServiceRequest", {
    state: {
      selectedCategory,
      selectedLocation,
      firstname,
      serialNo,
      modellNo,
    },
  });
}, 300);
        // navigate("/ServiceRequest", {
        //   state: {
        //     selectedCategory,
        //     selectedLocation,
        //     firstname,
        //     serialNo,
        //     modellNo,
        //   },
        // });
      }
    }
  };

  // const onClickehandle = () => {
  //     if (!selectedCategory) {
  //         setMessage('Please Select Category Type..!');
  //     } else if (!selectedLocation) {
  //         setMessage('Please Select a Location..!');
  //     } else {
  //         // Reset any existing messages
  //         setMessage('');

  //         // Store the selected values in localStorage
  //         localStorage.setItem('selectedCategory', selectedCategory);
  //         localStorage.setItem('selectedLocation', selectedLocation);
  //         localStorage.setItem('firstname', firstname);
  //         localStorage.setItem('serialNo', serialNo);
  //         console.log('selectedCategory', selectedCategory);
  //         // Define categories that should navigate to /service/annual
  //         // const annualServiceCategories = ['CNC Turning', 'VMC', 'HMC', 'CMH', 'VTL'];

  //         navigate('/service/annual', {
  //             state: {
  //                 selectedCategory,
  //                 selectedLocation,
  //                 firstname,
  //                 serialNo,
  //             }
  //         });

  //     }
  // };

  // const onClickehandle = () => {
  //     if (!selectedCategory) {
  //         setMessage('Please Select Category Type..!');
  //     }  else if (!selectedLocation) {
  //         setMessage('Please Select a Location..!');
  //     }
  //     else if (!firstname) {
  //         setMessage('Please Select a firstname..!');
  //     }
  //     else if (!serialNo) {
  //         setMessage('Please Select a serialNo..!');
  //     }
  //     else {

  //         setMessage('');

  //         localStorage.setItem('selectedCategory', selectedCategory);
  //         localStorage.setItem('selectedLocation', selectedLocation);
  //         localStorage.setItem('firstname', firstname);
  //         localStorage.setItem('serialNo', serialNo);

  //         navigate('/service/annual', {
  //             state: {
  //                 selectedCategory,
  //                 selectedLocation,
  //                 firstname,
  //                 serialNo,

  //             }
  //         });

  //         console.log('Selected Category:', selectedCategory);
  //         console.log('Selected Location:', selectedLocation);
  //         console.log('Selected Category:', firstname);
  //         console.log('Selected Location:', serialNo);
  //     }
  // };

  // if (!isButtonDisabled) {

  //     // Fetch data from the API
  //     axios.post('https://contacts.origaleasing.com/fetchAMCbasedontype', {
  //         type:selectedCategory
  //     })
  //     .then(response => {
  //         const products = response.data.jsondata;
  // //    console.log("amc product code",products);

  //         if(code===1000){
  //             navigate('/service/annual', { state: { products } });
  //         }else{
  //             navigate('/unfortunately',{state:{naviurl:"/service/amc"}})
  //         }

  //     })
  //     .catch(error => {
  //         console.error('Error fetching data:', error);
  //     });
  // }

  const handlePincodeChange = (e) => {
    const newInputString = e.target.value;
    const sanitizedInput = newInputString.replace(/[^0-9]/g, "");

    // Update the state only if the input is empty or contains valid characters
    if (
      (newInputString === "" || sanitizedInput === newInputString) &&
      sanitizedInput.length <= 6
    ) {
      setPincode(sanitizedInput);
    }
  };

  const fetchServiceArea = () => {
    // Define your GraphQL query
    const graphqlQuery = {
      query: `
                query ServiceArea($pincode: Int!) {
                    nearestServicearea(userPincode: $pincode) {
                        message
                        code
                    }
                }
            `,
      variables: {
        pincode: parseInt(pincode, 10),
      },
    };
    axios
      .post("https://ompextension.origa.market/graphql/", graphqlQuery)
      .then((response) => {
        const data = response.data.data.nearestServicearea;
        console.log("nearestServicearea==>>", data.message);

        setCode(data.code);
        if (data.code === 1000) {
          //setMessage(data.message);
          setIsButtonDisabled(false);
        } else {
          setMessage("Sorry! we are not Serve in this area");
          setIsButtonDisabled(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setMessage("Please Enter Vailed Pincode!!!");
      });
  };

  // Selector Code
  useEffect(() => {
    // Define your GraphQL query and variables
    // const query = `
    //   query GetCategory($categoryId: ID!) {
    //     category(id: $categoryId) {
    //       id
    //       name
    //       children(first: 100) {
    //         edges {
    //           node {
    //             id
    //             name
    //             slug
    //           }
    //         }
    //       }
    //     }
    //   }
    // `;
    // const variables = {
    //     "categoryId": "Q2F0ZWdvcnk6MQ=="
    // };

    // // Make an Axios request to your GraphQL API
    // axios.post('https://dev.origa.market/graphql/', {
    //     query,
    //     variables,
    // })
    //     .then((response) => {
    //         const data = response.data.data;
    //         if (data.category) {
    //             setCategories(data.category.children.edges);
    //         }
    //     })
    //     .catch((error) => {
    //         console.error('Error fetching data:', error);
    //     });
    if (pincode.length === 6) {
      fetchServiceArea();
      setShow(true);
    }
  }, [pincode, show]);

  const breadcrumbsItems = [
    { name: "Home Page", link: "/" },
    { name: "Maintain Page", link: "/service" },
  ];

  const boldtitle = "Preventive";

  return (
    <>
    {loadinged && <Loader />}
      <section className="container-fluid liner-background-50">
        <div className="max-container">
          {/*start*/}
          <div className="pt-3">
            <Breadcrumbs
              boldtitle={boldtitle}
              items={breadcrumbsItems}
              backnavi={() => navigate("/service")}
            />
          </div>

          {/*end*/}
        </div>
        <div className="max-container pt-5">
          <div className="container-fluid p-0 m-0 row ">
            <div className="col col-lg-6 col-12 p-0 ">
              <h1 className="anual_heading">Preventive Maintenance </h1>

              <p className="heading-400-16-12 op-80">
                Safeguard Your Investment with Origa's Expertise
              </p>

              <p className="anual-para">
                At Origa, we understand the importance of keeping your machinery
                in top condition. Our comprehensive Preventive Maintenance
                program is designed to protect your investment, minimize
                downtime, and maximize productivity.
              </p>

              
<p className="heading-400-16-12 ">Why Choose Preventive Maintenance? </p>

<ul className="heading-400-16-12 op-80">
    <li className=""> Minimize Downtime</li>

<li className="heading-400-16-14">Extend Equipment Lifespan</li>
<li className="heading-400-16-14">Improve Safety</li>
<li className="heading-400-16-14">Enhance Productivity</li>
<li className="heading-400-16-14">Predictable Budgeting</li>
</ul>

              {/* <Link to="/" className='explore_txt'>Explore Service</Link> */}
            </div>
            <div className="col col-lg-6 col-12 ">
              <div className="form_lyt">
                <h2 className="form_heading hide-992">
                  Which Machine are you <br /> searching for an PM for?
                </h2>
                <div className="show-992 p-r mt-5 ">
                  <img
                    className="w-100 h-100"
                    src="/asset/image555a.png"
                    alt="image555a.png"
                  />
                </div>

                <div className={`bi-form-group `}>
                  <select
                    className={`bi-form-field  bi-select-dropdown ${
                      selectedCategory ? "" : "empty"
                    } `}
                    value={selectedCategory}
                    placeholder="Model/Categories Types"
                    onChange={(event) =>
                      setSelectedCategory(event.target.value)
                    }
                    autoCapitalize="off"
                  >
                    <option value="" className="selectcategory">
                      Select Category
                    </option>
                    <option value="CNC Vertical machine Center (VMC)">
                      CNC VMC
                    </option>
                    <option value="CNC Turning">CNC Turning</option>
                    <option value="CNC Turnmill Machine">
                      CNC Turnmill Machine
                    </option>
                    <option value="CNC Horizontal machining center">
                      CNC HMC
                    </option>
                    <option value="CNC LATHE">CNC Lathe</option>
                    <option value="CMH">CMH</option>
                    <option value="Grinding Machine">Grinding Machine</option>
                    <option value="SPM (Special Purpose machine)">
                      SPM (Special Purpose machine)
                    </option>
                    <option value="VTL">VTL</option>
                    <option value="Twines Spindle">Twins Spindle</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                {/*  */}
                <div className="location_icon mt-4">
                  {/* <Form.Control className='form__control'
                                        type="text"
                                        id="inputText"
                                        aria-describedby="passwordHelpBlock"
                                        placeholder='Enter the pincode'
                                        value={pincode}
                                        onChange={handlePincodeChange}
                                    
                                    /> */}

                  <Form.Select
                    className="form__control"
                    id="inputText"
                    onChange={onLocationChange}
                    value={selectedLocation}
                  >
                    <option value="">Select Location *</option>
                    <option value="Bengaluru">Bengaluru</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Ichalkaranji">Ichalkaranji</option>
                    <option value="Kolhapur">Kolhapur</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Navi Mumbai">Navi Mumbai</option>
                    <option value="Palghar">Palghar</option>
                    <option value="PUNE">Pune</option>
                    <option value="Thane">Thane</option>
                    <option value="Vasai">Vasai</option>
                  </Form.Select>

                  {/* <div onClick={fetchServiceArea}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <path d="M17.4863 12.757C17.4863 13.0625 17.3649 13.3555 17.1489 13.5715C16.9329 13.7875 16.6399 13.9089 16.3344 13.9089C16.029 13.9089 15.736 13.7875 15.52 13.5715C15.304 13.3555 15.1826 13.0625 15.1826 12.757C15.1826 12.4516 15.304 12.1586 15.52 11.9426C15.736 11.7266 16.029 11.6052 16.3344 11.6052C16.6399 11.6052 16.9329 11.7266 17.1489 11.9426C17.3649 12.1586 17.4863 12.4516 17.4863 12.757Z" stroke="#211E24" stroke-width="1.5" stroke-linejoin="round" />
                                            <path d="M22.67 13.335C22.67 16.8342 20.3664 20.8218 16.335 25.4291C12.3036 20.8218 10 16.8342 10 13.335C10 11.6549 10.6674 10.0435 11.8555 8.85548C13.0435 7.66744 14.6549 7 16.335 7C18.0151 7 19.6265 7.66744 20.8145 8.85548C22.0026 10.0435 22.67 11.6549 22.67 13.335Z" stroke="#211E24" stroke-width="1.5" stroke-linejoin="round" />
                                        </svg>
                                    </div> */}
                </div>
                <br></br>
                <div>
                  {/* <Form.Control
                type="text"
                className='form__control'
                id="inputText"
                placeholder="Model No"
                value={model}
                onChange={onModelChange}
            />
                                </div>
                                <div>
                                <br></br>
                                <Form.Control
                type="text"
                className="form__control"
                id="inputText"
                placeholder="Serial No"
                value={SerialNo}
                onChange={onSerialNoChange}
            /> */}
                </div>

                <div>
                  <TextField
                    className="form-width "
                    fullWidth
                    label="Model"
                    id="First-Name"
                    size="small"
                    value={modellNo}
                    onChange={onModelnameChange}
                  />
                </div>
                <br></br>
                <div>
                  <TextField
                    className="lejdvnvld form-width"
                    fullWidth
                    label="Serial Number/Machine Number"
                    id="First-Name"
                    size="small"
                    value={serialNo}
                    onChange={serialNumberCalling}
                  />
                </div>
                <div>
                  {/* <div className="">
                                                <input type="text" name="name" id="lastname"placeholder="Last Name"    value={firstname}
                                        onChange={onFirstnameChange} />
                                                
                                            
                                        </div> */}
                </div>
                {/* <p className="text-danger">{message}</p> */}
                {/* {message && <p>{message}</p>} */}
                {/* {serviceAreaMessage && <p>{serviceAreaMessage}</p>} */}
                <div className="typ-button-flexes">
                  <Button
                    message={"Search"}
                    callFunction={onClickehandle}
                    disabled={isButtonDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmcFirstSectionPage;
