import React, { useState, useEffect } from "react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import "./CartPageSampleStyles.css";
import axios from 'axios'
import client from '../Services/ServicesPopup/apolloclient'
import { gql } from 'graphql-tag';
import { Button, Container } from "react-bootstrap";
import Loader from "../SubComponent/Loader";
import '../Addonservice/success.css'
import Button2 from "../Button/Button2";
const CREATE_PAYMENT = gql`
mutation CreateAmc($amcPlanData: AMCPlanInput!) {
  createAmc(amcPlanData: $amcPlanData) {
    message
    success
    paymenturl
    referenceid
  }
}
`;

const SummaryCartAmcAgainNew = () => {
    const [loading, setLoading] = useState(true);
    const [loadinged, setLoadinged] = useState(false);
      const location = useLocation();
      const product = location.state?.product; // Use optional chaining to safely access product
  
      const [loginPortal, setLoginPortal] = useState(false);
     
      const loggedIn = !!localStorage.getItem('userToken');
      const navigate = useNavigate();
      const { selectedCategory, selectedLocation, firstname, serialNo,
        id,
        companyName,
        factoryName,
        city,
        state,
        secondgstno,
        secondcity,
        secondstate,
    address1,
    lastname,
    email,
    formattedPhoneNumber,
    formattedAlterPhoneNumber,
    pinCode,
    address2,
    secondpinCode,
    secondaddress1,
    productId,
    productName,
    producttype,
    productPrice,
    productPlan,
    productGstAmount,
    productTotalAmount,
    productImage,
    secondaddress2,
    productStatus } = location.state || {};
    //   console.log('Selected Category in CartPageAmc:  ', selectedCategory);
    //   console.log('Selected Location in CartPageAmc:  ', selectedLocation);
    //  console.log('Selected Firstname in CartPageAmc:  ', firstname);
    //   console.log('Selected serialNo in CartPageAmc:  ', serialNo);
       const AMC_BASED_ON_TYPE = location.state?.selectedCategory?.toUpperCase();  
  
       console.log(
        "Here in SummaryCartforamc  for buy flow :  " + "\n",
        "ProductId: " + productId + "\n" +
          "ProductName: " + productName + "\n" +
          "ProductType: " + producttype + "\n" +
          "ProductPrice: " + productPrice + "\n" +
          "ProductPlan: " + productPlan + "\n" +
          "ProductGstAmount: " + productGstAmount + "\n" +
          "ProductTotalAmount: " + productTotalAmount + "\n" +
          "ProductStatus: " + productStatus
        )
  
      //  console.log("AMC BASED ON TYPE in SummaryCartforamc : -------------> ",AMC_BASED_ON_TYPE);
      const [responseData, setResponseData] = useState(null);
      const productData = location.state && location.state.product
      console.log("product data=>>", productData);
      const [showModal, setShowModal] = useState(false);
      const [isHovered, setIsHovered] = useState(false);
      
      
      const onMouseEnterhandler = () => {
          setIsHovered(true);
      };
  
      const onMouseLeavehandler = () => {
          setIsHovered(false);
      };
  
      const handleModal = (status) => {
          setShowModal(status);
      };
  
  
  
  
      const [SuccessPopUp, setSuccessPopUp] = useState(false);
      const [PaymentLink, setPaymentLink] = useState([]);
  

      
  const onBuyHandler = async () => {
    console.log("wwswswswswwsw");
    const id = localStorage.getItem("id");
    try {
      const { data } = await client.mutate({
        mutation: CREATE_PAYMENT,
        variables: {
            "amcPlanData": {
              "appAmcPlanId": productId,  // Pass as an integer, not a string
              "customerId": id,
              "stage": "PAYMENT",
              "serviceType": "AMC",
              "category":selectedCategory,
              "location":selectedLocation,
              "serialNo":serialNo,
              "firstName":firstname,
              "companyName": companyName,
              "factoryName": factoryName,
              "address": {
                "billingAddress": {
                  "city": city,
                  "cityArea": "",
                  "companyName":companyName ,
                  "country": "IN",
                  "countryArea": state,
                  "firstName": firstname,
                  "lastName": lastname,
                  "phone": `${formattedPhoneNumber}`,
                  "postalCode": `${pinCode}`,
                  "streetAddress1": address1,
                  "streetAddress2": address2,
                  "gst": secondgstno
                },
                "shippingAddress": {
                  "city": secondcity,
                  "cityArea": "",
                  "companyName": companyName,
                  "country": "IN",
                  "countryArea": secondstate,
                  "firstName": firstname,
                  "lastName": lastname,
                  "phone": `${formattedPhoneNumber}`,
                  "postalCode": `${secondpinCode}`,
                  "streetAddress1": secondaddress1,
                  "streetAddress2": secondaddress2,
                  "gst": secondgstno
                }
              }
            }
          }
      });
      console.log("API Response paymentgetway==>>>", data);
      console.log("Create payment is happening : ------------------------>");
      console.log("appAmcPlanId : ------------------------>", productData?.id);
      setResponseData(data);
      setSuccessPopUp(true);
      setPaymentLink(data.createAmc.paymenturl);
      console.log(
        "Payment Link :  ---------------------------------> ",
        PaymentLink
      );
      //window.location.href = data.createAmc.paymenturl;
    } catch (error) {
      console.error("API Error==>", error.message);
    }
  };
  
      
    
      const GotoAddressPage = () => {
        // navigate('/manageaddresspartforamc', {
          setLoadinged(true);
          setTimeout(() => {
            navigate('/addamcflow', {
              state: {
                  selectedCategory,
                  selectedLocation,
                  firstname,
                  serialNo,
                  productId,
                  productName,
                  producttype,
                  productPrice,
                  productPlan,
                  productGstAmount,
                  productTotalAmount,
                  productImage,
                  productStatus,
                  titlename:"Add Address"
      
      
      
                  
              }
          });
  
          console.log(
            "Here in /cartpageofamcforbuy for buy flow going to address page :  " + "\n",
            "ProductId: " + productId + "\n" +
            "ProductName: " + productName + "\n" +
            "ProductType: " + producttype + "\n" +
            "ProductPrice: " + productPrice + "\n" +
            "ProductPlan: " + productPlan + "\n" +
            "ProductGstAmount: " + productGstAmount + "\n" +
            "ProductTotalAmount: " + productTotalAmount + "\n" +
            "ProductStatus: " + productStatus
          );
          }, 1000); // Adjust the delay as needed
      //     navigate('/addaddresscartforamc', {
      //     state: {
      //         selectedCategory,
      //         selectedLocation,
      //         firstname,
      //         serialNo,
      //         productId: productData?.id,
      //         productName:productData?.name,
      //         producttype:productData?.type,
      //         productPrice:productData?.price,
      //         productPlan:product?.plan,
      //         productGstAmount:product?.gst_amount,
      //         productTotalAmount:product?.total_amount,
      //         productImage: productData?.plan_image || '',
      //         productStatus: productData?.status || '',
      //         titlename:"Add Address"
  
  
  
              
      //     }
      // });
      }
      
      // console.log("ProductIdSecondSection===>>>", product);
  
    return (
      <>
      {loadinged && <Loader />}
          {SuccessPopUp ? (
          <Container fluid className="p-fixed bg-blur hw-100 d-j-a">
            <div className='App'>
              <div className='success-login'>
                <div className='contents'>
                  <h3 className='heading-600-28'>Confirm Payment</h3>
                  <p className='heading-400-16 op-60'>Proceed to the payment</p>
                  <div className="d-flex justify-content-between mt-3">
                    <a href={PaymentLink} rel="noreferrer" >
                      <Button variant="success" className="me-2">
                        Pay Now
                      </Button>
                    </a>
                    <Button variant="danger" style={{ marginLeft: '8px' }} onClick={() => setSuccessPopUp(false)}>
                      Cancel
                    </Button>
                  </div>
  
                </div>
              </div>
            </div>
          </Container>
        ) : null}
      <div>
        <div className="rifhtsidebortertotaldivcontent">
          <p>
            <div class="meter-container">
              <div class="meter">
                <div class="meter-fill" style={{ width: "80%" }}></div>
              </div>
            </div>
            <IoCheckmarkDoneSharp className="ioiconscc" size={28} /> &nbsp;
            <span className="orderdetailscart">
              Your order is ready for processing.{" "}
            </span>
            <br></br>
          </p>
          <p className="summaryContent">
            <span className="summaryItem">
              <span className="label labledddd">Summary</span>
            </span>
            
            <span className="summaryItem">
      <span className="label">Price:</span>
      <span className="value">
          ₹{product?.price || productPrice}
      </span>
  </span>
  <span className="summaryItem">
      <span className="label">GST:</span>
      <span className="value">₹{product?.gst_amount || productGstAmount}</span>
  </span>
  <span className="summaryItem">
      <span className="label">Total Amount:</span>
      <span className="value">₹{product?.total_amount || productTotalAmount}</span>
  </span>
  
  
          </p>
        </div>
  
  
  
  
        {/* <div className="proceddcartbuttondiv">
        
        <Button2  message="Go to Payment"  onClick={onBuyHandler}>
    Go to Payment
  </Button2>
       
        </div> */}
        {/* <div>
          <button className="proceedcartbtnnew"  onClick={onBuyHandler}>Go to Payment
          </button>
          <button className="proceedcartbtnnew"  onClick={GotoAddressPage}>
       Add or Edit Address
          </button>
          </div> */}
      </div>
  
      </>
    );
  };
  

export default SummaryCartAmcAgainNew