import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { secondClient, GET_SPARE_DETAILS } from "../../../OrigaExtentionAPI/mutations";
import './QuotationPopupStyles.css';

const client = secondClient;

const QuotationPopup = ({ quantity, setQuantity, onSubmit, productDetails }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get("id");
    const [infokey, setInfokey] = useState(0);
    const { name, price, image } = productDetails;
    const groupId = queryParams.get("group_id");
    const [selectedQuantity, setSelectedQuantity] = useState(1);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [productDETAILS, setProductDETAILS] = useState({});
    const [endQuantity, setEndQuantity] = useState();
    const [clickedPriceItem, setClickedPriceItem] = useState(null);
    const [bucketPrice, setBucketPrice] = useState(null);
    const [bucketSize, setBucketSize] = useState(null);
    const [bucketId, setBucketId] = useState(null); // State to store bucketId

    useEffect(() => {
        if (productDETAILS && infokey !== undefined) {
            const product = productDETAILS[infokey];
            setSelectedProduct(product);
            if (product && Array.isArray(product.price_list) && product.price_list.length > 0) {
                setClickedPriceItem(0);
                setBucketPrice(product.price_list[0].price);
                setBucketSize(product.price_list[0].bucket_size);
                setBucketId(product.price_list[0].bucket_id); // Initialize bucketId
            } else {
                setClickedPriceItem(null);
                setBucketPrice(null);
                setBucketSize(null);
                setBucketId(null);
            }
        }
    }, [productDETAILS, infokey]);

    const renderPriceList = () => {
        if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
            return (
                <div>
                    {selectedProduct.price_list.length > 0 ? (
                        selectedProduct.price_list.map((priceItem, index) => (
                            <button
                                key={index}
                                className={`buttonclickednodvjbktor ${clickedPriceItem === index ? "btn-clicked" : ""}`}
                                onClick={() => handlePriceClick(priceItem, index)}
                            >
                                {priceItem.price_label}
                                <br />₹{priceItem.price}/Ltr
                            </button>
                        ))
                    ) : (
                        <p>No price list available</p>
                    )}
                </div>
            );
        }
        return <p>Loading...</p>;
    };

    const handlePriceClick = (priceItem, index) => {
        console.log("Clicked price item:", priceItem);
        setClickedPriceItem(index);
        setBucketPrice(priceItem.price);
        setBucketSize(priceItem.bucket_size);
        setEndQuantity(priceItem.end_quantity);
        setBucketId(priceItem.bucket_id); // Store bucket_id in state

        if (priceItem.start_quantity) {
            setSelectedQuantity(priceItem.start_quantity);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            let id = localStorage.getItem("id");
            if (id === null) {
                id = "";
            }
            try {
                const { data } = await client.query({
                    query: GET_SPARE_DETAILS,
                    variables: { groupId: groupId || "", itemId: productId || "" },
                });
                setProductDETAILS(data?.inventoryItem?.response);
                setLoading(false);
                updateFormattedPrice();
            } catch (error) {}
        };
        fetchData();
    }, [groupId, productId]);

    const updateFormattedPrice = () => {
        setProductDETAILS((prevDetails) => ({
            ...prevDetails,
            price_details: {
                ...prevDetails.price_details,
                machine_price: formatCurrency(prevDetails.sales_rate),
            },
        }));
    };

    const formatCurrency = (value) => {
        const fractionDigits = value % 1 !== 0 ? 2 : 0;
        return new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }).format(value || 0);
    };

    const handlePopupSubmit = () => {
        onSubmit({
            productId: productDetails.id,
            quantity: selectedQuantity,
            bucketId: bucketId // Include bucketId in the submission
        });
    };

    return (
        <div className="totalpopupdiv">
            <h4 className="quotationDetailsheading">Quotation Details</h4>
            <div className="popupContentWrapper">
                <div className="Contentisnidepopup">
                    <p className="">Name: {name || "N/A"}</p>
                    <p>Price: {bucketPrice !== null ? bucketPrice : "..."} / Ltr</p>
                    Quantity&nbsp;:&nbsp;{selectedQuantity}
                    <br />
                    <br />
                    {renderPriceList()}
                    <button
                        className="button-submit-popup"
                        onClick={handlePopupSubmit}
                        // disabled={!bucketId}
                    >
                        Submit
                    </button>
                </div>
                <div className="imageWrapper">
                    <img
                        className="imageinpopup"
                        src={image || "N/A"}
                        alt="Product"
                    />
                </div>
            </div>
        </div>
    );
};

export default QuotationPopup;
