import React from 'react'

const RadioButton = ({w,h,fInner,fOuter}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox="0 0 24 24" fill="none">
    <path d="M12.0008 2.57031C6.79501 2.57031 2.57227 6.79306 2.57227 11.9989C2.57227 17.2047 6.79501 21.4275 12.0008 21.4275C17.2067 21.4275 21.4294 17.2047 21.4294 11.9989C21.4294 6.79306 17.2067 2.57031 12.0008 2.57031ZM12.0008 20.0869C7.55466 20.0869 3.91282 16.4674 3.91282 11.9989C3.91282 7.53033 7.55454 3.91087 12.0008 3.91087C16.4471 3.91087 20.0889 7.55259 20.0889 11.9989C20.0889 16.4452 16.4471 20.0869 12.0008 20.0869Z" fill={fOuter}/>
    <path d="M18.3073 12.1517C18.3073 15.55 15.5525 18.3048 12.1541 18.3048C8.75578 18.3048 6.00098 15.55 6.00098 12.1517C6.00098 8.75334 8.75578 5.99854 12.1541 5.99854C15.5525 5.99854 18.3073 8.75334 18.3073 12.1517Z" fill={fInner}/>
  </svg>
  )
}

export default RadioButton