import React, { useEffect, useState } from 'react';
import { dollerIcon, tagIcon } from '../../../../helpers/Icons';
import { useLocation, useNavigate } from 'react-router-dom';

const MyOrdersPart = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSize, setSize] = useState({ width: 38, height: 38 });
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const message = queryParams.get('message');

  // Fetch orders for the customer
useEffect(() => {
  const fetchOrders = async () => {
    const customer_id = localStorage.getItem('id');
    const requestBody = { customer_id };

    try {
      const response = await fetch('https://ompextension.origa.market/sparesapi/get_orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }

      const data = await response.json();
      console.log('API response:', data);

      if (data.orders) {
        const groupedOrders = data.orders.reduce((acc, order) => {
          if (!acc[order.reference_id]) {
            const uniqueLineItems = order.line_items.reduce((uniqueItems, item) => {
              if (!uniqueItems.some(existingItem => existingItem.id === item.id)) {
                uniqueItems.push(item);
              }
              return uniqueItems;
            }, []);
            acc[order.reference_id] = { ...order, line_items: uniqueLineItems };
          }
          return acc;
        }, {});

        setOrders(Object.values(groupedOrders));
      } else {
        setOrders([]);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  fetchOrders();
}, []);
  // Fetch order details based on ID and message
  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      console.log("Fetching order details for:", { customerId, referenceNumber });

      try {
        const response = await fetch(
          'https://ompextension.origa.market/sparesapi/get_orders',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customer_id: customerId,
              reference_number: referenceNumber,
            }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        console.log('API response:', data);

        if (data.order) {
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    if (id && message === 'Paid') {
      const customerId = localStorage.getItem('id');
      fetchOrderDetails(customerId, id);
    }
  }, [id, message]);

  const handleDownloadInvoice = () => {
    if (invoiceUrl) {
      window.open(invoiceUrl, '_blank');
    } else {
      alert('Invoice URL not available');
    }
  };

  // const viewDetailsFunction = (referenceNumber, message) => {
  //   navigate(`/ordersummarycart?id=${referenceNumber}&message=${message}`);
  // };

  const viewDetailsFunctionDummy = (referenceNumber, message) => {
    navigate(`/ordersummarycartmy?id=${referenceNumber}&message=${message}`);
  };


  const generateItemSummary = (lineItems) => {
    if (lineItems.length === 0) return 'No items';

    const firstItemName = lineItems[0].name;
    const additionalItemCount = lineItems.length - 1;

    if (lineItems.length === 1) {
      return firstItemName;
    }

    return `${firstItemName} + ${additionalItemCount}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container-fluid">
      <div className="max-container my-5">
        <div className="layout-wrap-machine">
          {orders.length > 0 ? (
            orders.map((order, orderIndex) => (
              order.line_items.length > 0 && (
                <div key={orderIndex} className="max-container detail-wrap">
                  <div className="img-wrap-1">
                    <img
                      className="img-1"
                      src={order.line_items[0].thumbnail?.[0]?.url || 'N/A'}
                      alt="Product"
                    />
                  </div>

                  <div className="price-wrap">
                    <div className="top">
                    <div className="name heading-600-20 heading-600-20-16">
                          {generateItemSummary(order.line_items)}
                        </div>
                      <div className="right bi-process-wrap">
                        <span className="completed">
                          {tagIcon({
                            width: isSize.width,
                            height: isSize.height,
                            className: 'completed',
                          })}
                          
                        </span>
                      
                        <span className="completed">
                          {dollerIcon({
                            width: isSize.width,
                            height: isSize.height,
                            className: '',
                          })}
                        </span>
                        <span className="completed">
                          {dollerIcon({
                            width: isSize.width,
                            height: isSize.height,
                            className: '',
                          })}
                        </span>
                       

                      </div>
                    </div>
                    <div className="bottom">
                      <div className="left-wrap">
                        
                        <span className='orderreferenceid'>
            (Order Reference: {order.reference_id})
                      </span>
                        <div className="price">
                          Order Total: ₹{order.subtotal}
                        </div>
                        <div className="price">
                          Total Quantity: {order.total_quantity}
                        </div>
                      </div>
                      <div className="right-wrap">
                        <div className="block-info">
                          <div className="something_here block-info__text heading-400-14-12">
                            Shipping Charge: ₹{order.shipping_charge}
                          </div>
                        </div>
                        <div className="advance-info">
                          <button
                            className="button heading-600-16-14"
                            type="button"
                            onClick={() => viewDetailsFunctionDummy(order.reference_id, 'Paid')}
                          >
                            View Details
                          </button>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))
          ) : (
            <div>No orders available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrdersPart;
