import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginModel from '../Authentication/LoginModel/LoginModel';
import ImageSlider from '../Buying/Modals/ImageSlider.jsx';
import { framre3dIcon } from '../../helpers/Icons.js';
import ThreeDIcon from '../SubComponent/AllSvgs/ThreeDIcon';
import { heartIcon } from '../../helpers/Icons';
import useAMCWishListAddOrUpdate from '../SubComponent/useAMCWishListAddOrUpdate.jsx';
import TabSlider from '../SubComponent/AllSlider/TabSlider';

import SummaryCartLoggedNot from './SummaryCartLoggedNot.jsx';
import SummaryCartforamc from './SummaryCartforamc.jsx';
import { Container,Button } from 'react-bootstrap';
import client from '../Services/ServicesPopup/apolloclient'
import { gql } from 'graphql-tag';
import "../Addonservice/success.css"
import Footer from '../Footer/Footer.jsx';
import SummaryCartAmcAgain from './SummaryCartAmcAgain.jsx';

const CREATE_PAYMENT = gql`
mutation CreateAmc($amcPlanData: AMCPlanInput!) {
  createAmc(amcPlanData: $amcPlanData) {
    message
    success
    paymenturl
    referenceid
  }
}
`;
const CartPageAmcRo = () => {
  useEffect(() => {
    window.scrollTo(0,0);
      },[])
    const location = useLocation();
    const navigate = useNavigate();
    const { productId,productName,producttype,productPrice,productPlan,productImage,productStatus,productTotalAmount,productGstAmount,selectedCategory, selectedLocation, firstname, serialNo } = location.state || {};
    
 
      console.log(
        "Here in /cartpageofamcforbuy for buy flow :  " + "\n",
        "ProductId: " + productId + "\n" +
        "ProductName: " + productName + "\n" +
        "ProductType: " + producttype + "\n" +
        "ProductPrice: " + productPrice + "\n" +
        "ProductPlan: " + productPlan + "\n" +
        "ProductGstAmount: " + productGstAmount + "\n" +
        "ProductTotalAmount: " + productTotalAmount + "\n" +
        "ProductStatus: " + productStatus
      );
      
    
    const product = location.state?.product; 
    const productData = location.state && location.state.product;
    const AMC_BASED_ON_TYPE = location.state?.selectedCategory?.toUpperCase();  

    //  console.log("AMC BASED ON TYPE in Cart finCartPageAmc : -------------> ",AMC_BASED_ON_TYPE);
    const [responseData, setResponseData] = useState(null);
    // console.log("product data=>>", productData);
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { onWishlistHandler, heartColor } = useAMCWishListAddOrUpdate();
    
    const onMouseEnterhandler = () => {
        setIsHovered(true);
    };

    const onMouseLeavehandler = () => {
        setIsHovered(false);
    };

    const handleModal = (status) => {
        setShowModal(status);
    };

    // console.log("ProductIdSecondSection===>>>", product);

    

    const [SuccessPopUp, setSuccessPopUp] = useState(false);
    const [PaymentLink, setPaymentLink] = useState([]);



    return (
        <>
         {SuccessPopUp ? (
        <Container fluid className="p-fixed bg-blur hw-100 d-j-a">
          <div className='App'>
            <div className='success-login'>
              <div className='contents'>
                <h3 className='heading-600-28'>Confirm Payment</h3>
                <p className='heading-400-16 op-60'>Proceed to the payment</p>
                <div className="d-flex justify-content-between mt-3">
                  <a href={PaymentLink} rel="noreferrer" >
                    <Button variant="success" className="me-2">
                      Pay Now
                    </Button>
                  </a>
                  <Button variant="danger" style={{ marginLeft: '8px' }} onClick={() => setSuccessPopUp(false)}>
                    Cancel
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </Container>
      ) : null}

        {/* <div className="amccartpage"> */}

    
          <div className="totalcartpage">
        <div className="leftsideBordertotaldiv">
          <div className="toppartofcartpage row justify-content-between align-items-center">
            <div className="leftheadingcart col-4">
              <h1 className="leftheadingcarttext">My Cart</h1>
            </div>
            {/* <div className="rightheadingcart col-4">
              <h1 className="rightheadingcarttext">Price</h1>
            </div> */}
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartunder" />
          </div>

  <div className="threesectionsofcart">
            <div className="firstsectionofcart">
                {/* <img className="imageonleftofcart"src={product.plan_image} alt={product.name} /> */}
                <img
  className="imageonleftofcart"
  src={productImage} // Use a fallback image URL or placeholder
  alt={product ? productName: 'Default Alt Text'} // Provide a default alt text
/>
            </div>
            <div className="secondsectionofcart">
            <h1 className="headingpartofcarttt">
    {productPlan} Preventive maintenance  (PM) plan for {producttype || 'Unknown'} machine 
    <br />
    {productName ? (
        <span className="rbfbef heading-400-20-14 op-60">
            &nbsp;| {productName}
        </span>
    ) : "N/A"}
</h1>
Status : {productStatus || 'N/A'}
{/* <div className="contentofcartpara">
    <p className="inStocktext">
        <span className="inStocktextcontent">₹{productPrice || '0'}</span>
    </p>
</div> */}

               
            </div>
            <div className="thirdsectionofcart mt-5">
                {/* <p className="badgerightside">
                    <span className="salespricecart">GST : ₹{product. gst_amount}</span>
                    
                </p>
                <p className="badgerightside">
                   
                    <span className="salespricecart">Total Amount ₹{product. total_amount}</span>
                </p> */}
            </div>
            <div className='basespace'>
              
            </div>
            
            
        </div>
        

        <div>

{/* <div className='d-flex justify-content-end'>
<Button message={"Proceed to Payment"}   />
</div> */}

        </div>
        {/* <button className="proceedcartbtnnew"  onClick={onBuyHandler}>
    Confirmation 
        </button> */}
{/*         
        <button className="proceedcartbtnnew"  onClick={GotoAddressPage}>
  Add Address
        </button> */}
        </div>

        <div className="rightsideBordertotaldiv">
     <SummaryCartAmcAgain />
         
        </div>

        
        </div>
        
     

        <Footer />



        {/* </div> */}
        </>
    );
}

export default CartPageAmcRo