import React, { useState, useEffect } from "react";
import Cplus from "../../SubComponent/Cplus";
import EditIcon from "../../SubComponent/EditIcon";
import EmailIcon from "../../SubComponent/EmailIcon";
import PhoneIcon from "../../SubComponent/PhoneIcon";
import Button from "../../Button/Button";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import RadioButton from "../../SubComponent/AllSvgs/RadioButton";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../SubComponent/Loader";
import AccountFooter from "../AccountFooter";
import "./SummaryPageSampleStyles.css";
import {
  closeIcon,
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  skipIcon,
  tickIcon,
  vectorLineIcon,
} from "../../../helpers/Icons";

const ManageAddressPart = () => {
  const [loadinged, setLoadinged] = useState(false);
  const [activeAddress, setActiveAddress] = useState(0);
  const [isSmallScreen] = useState(window.innerWidth <= 992);
  const navigate = useNavigate();
  const [selectedMpAddressId, setSelectedMpAddressId] = useState(null);
  const [showPopupforCart, setShowPopupforCart] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
const emailIdta = localStorage.getItem('emailId');
  const location = useLocation();
  const {
    itemId,
    bucketId,
    quantity,
    orderId,
    quantityTotal,
    subtotalAmount,
    gstAmount,
    taxesAmount,
    shippingFee,
    totalAmount,
  } = location.state || {};

  // Use these variables in your component
  // console.log("Item ID:", itemId);
  // console.log("Bucket ID:", bucketId);
  // console.log("Quantity:", quantity);
  // console.log("Order Identifier:", orderId);
  // console.log("Quantity Total:", quantityTotal);
  // console.log("Subtotal Amount:", subtotalAmount);
  // console.log("GST Amount:", gstAmount);
  // console.log("Taxes Amount:", taxesAmount);
  // console.log("Shipping Fee:", shippingFee);
  // console.log("Total Amount:", totalAmount);

  const closePopup = () => {
    setShowPopupforCart(false);
  };

  const handleEditAddressClick = () => {
    setShowPopupforCart(true);
  };

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(
    parseInt(localStorage.getItem('selectedAddressIndex'), 10) || 0
  );


  useEffect(() => {
    if (data.length > 0) {
      const defaultIndex = selectedAddressIndex || 0;
      const defaultMpAddressId = data[defaultIndex]?.mp_address_id;
      
      setSelectedAddressIndex(defaultIndex);
      setSelectedMpAddressId(defaultMpAddressId);
  
      // If mp_address_id is not already set in localStorage, set it
      if (!localStorage.getItem('selectedMpAddressId')) {
        localStorage.setItem('selectedAddressIndex', defaultIndex.toString());
        localStorage.setItem('selectedMpAddressId', defaultMpAddressId);
      }
  
      handleCheckboxChange(defaultIndex); // Trigger address display on first render
    }
  }, [data]);

  const handleCheckboxChange = (index) => {
    console.log(`Selected index: ${index}`);

    const selectedAddress = data[index]?.address;
    const mpAddressId = data[index]?.mp_address_id;
    console.log(`Selected mp_address_id: ${mpAddressId}`);
    

    if (selectedAddress) {
      console.log(` 
        Firstname: ${selectedAddress.input?.firstName || 'N/A'} 
        Lastname: ${selectedAddress.input?.lastName || 'N/A'} 

        Billing Address: ${selectedAddress.input?.streetAddress1 || 'N/A'} 
        ${selectedAddress.input?.streetAddress2 || 'N/A'} 
        ${selectedAddress.input?.city || 'N/A'}
        ${selectedAddress.input?.countryArea || 'N/A'}
        ${selectedAddress.input?.postalCode || 'N/A'}`); 
      console.log(`
        Shipping Address: ${selectedAddress.businessaddress?.streetAddress1 || 'N/A'}
        ${selectedAddress.businessaddress?.streetAddress2 || 'N/A'} 
        ${selectedAddress.businessaddress?.city || 'N/A'} 
        ${selectedAddress.businessaddress?.countryArea || 'N/A'}
        ${selectedAddress.businessaddress?.postalCode || 'N/A'}`);
      
      console.log(`GST Number: ${selectedAddress.gst_no || 'N/A'}`);
      console.log(`Factory Name: ${selectedAddress.factoryName || 'N/A'}`);
    } else {
      console.log('No address found for this index.');
    }

    setSelectedAddressIndex(index);
    setSelectedMpAddressId(mpAddressId); 
    localStorage.setItem('selectedAddressIndex', index.toString());
    localStorage.setItem('selectedMpAddressId', mpAddressId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number")
          }
        );
        console.log("API Response:", response.data);

        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;

 const handleDeliverHere = () => {
  setLoadinged(true);
  setTimeout(() => {
    navigate('/summaryCartPage', {
      state: {
        newItemId: itemId,      // Renamed itemId to newItemId
        newBucketId: bucketId,  // Renamed bucketId to newBucketId
        newQuantity: quantity,  // Renamed quantity to newQuantity
        newOrderId: orderId,    // Renamed orderId to newOrderId
        mpAddressId: selectedMpAddressId ,
        quantityTotal,
                                subtotalAmount,
                                gstAmount,
                                taxesAmount,
                                shippingFee,
                                totalAmount,
      },
    });
  }, 3000);
};


  const onRadioHandler = (index) => {
    setActiveAddress(index);
    localStorage.setItem('selectedAddressIndex', index);
  };

  return (
    <>
      {loadinged && <Loader />}
      <div className="container-fluid pt-4">
        <div className="totalcartpagebuttop">
          <div className="centertotaldivcart">
            <div className="icon-containere">
              <span className="dilevery-address-1">
                {ellipseWhiteIcon({ width: 32, height: 32 })}
              </span>
              <span className="icon-number">1</span>
            </div>
            <span className="add-to-cart-text">Add to Cart</span>
            <div className="line lineedbr">
              {vectorLineIcon({ width: 88, height: 1 })}
            </div>
            <div className="icon-containere rsbbref">
              <span className="order-sumary-2">
                {ellipsePurpleIcon({ width: 32, height: 32 })}
              </span>
              <span className="icon-number">2</span>
            </div>
            <span className="add-to-cart-text">Delivery Address</span>
            <div className="line lineedbr">
              {vectorLineIcon({ width: 88, height: 1 })}
            </div>
            <div className="icon-containere ">
              <span className="order-sumary-2">
                {ellipseWhiteIcon({ width: 32, height: 32 })}
              </span>
              <span className="icon-number">3</span>
            </div>
            <span className="add-to-cart-text">Payment</span>
          </div>
        </div>
        <div className={`${isSmallScreen ? "container-fluid" : "container"} pt-5`}>
          {data.length > 0 ? (
            <div className="row">
              <div
                className={`col col-md-12 ${isSmallScreen ? "pt-4" : "p-5"} bg-F9F9F9`}
              >
                <div className="row pt-3 justify-content-between">
                  <div className="col col-md-6 col-12 heading-600-24-20">
                    Select a Delivery Address
                  </div>
                  <div
                    className={`col col-md-4 col-12 ${isSmallScreen ? "pt-3" : ""}`}
                  >
                    <div
                      className="d-flex justify-content-end curser-pointer"
                      onClick={() => {
                        setLoadinged(true);
                        setTimeout(() => {
                          navigate(`/addaddresscart`, {
                            state: {
                              cancelNavi: "/manageaddresspart",
                              saveNavi: "/manageaddresspart",
                              status: "Add Address",
                              itemId,
                              bucketId,
                              quantity,
                              orderId,
                              quantityTotal,
                              subtotalAmount,
                              gstAmount,
                              taxesAmount,
                              shippingFee,
                              totalAmount,
                            },
                          });
                        }, 2000);
                      }}
                    >
                      <Cplus />
                      <p className="m-0 pl-2 heading-600-14">Add New Address</p>
                    </div>
                  </div>
                </div>
                {data.map((add, index) => (
                  <div key={index} className="row justify-content-between pt-4">
                    <div className="col col-lg-7 col-md-9 col-12">
                      <div
                        className="d-flex align-items-center"
                        onClick={() => handleCheckboxChange(index)}
                      >
                        <RadioButton
                          w={24}
                          h={24}
                          fInner={
                            selectedAddressIndex === index
                              ? "#73509E"
                              : "none"
                          }
                          fOuter={
                            selectedAddressIndex === index
                              ? "#73509E"
                              : "#AAAAAA"
                          }
                          checked={selectedAddressIndex === index}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <p className="pl-3 heading-600-20 m-0">
                          {`${add.address?.input?.firstName || 'N/A'} ${add.address?.input?.lastName || 'N/A'}`}
                        </p>
                      </div>

                     
                      <div className="pt-2 heading-400-14 op-80" style={{ paddingLeft: "39px" }}>
                        <>
                        <div className="" style={{paddingBottom:"1%"}}>
                        <strong >Factory Name :</strong>  {add.address?.factoryName || 'N/A'}

                      </div>
                      </>
                        {add.address?.input || add.address?.businessaddress ? (
                          <>
                            {add.address?.input && (
                              <><div  style={{paddingBottom:"2%"}}>
                                <strong>Company Name :</strong>  {add.address.input.companyName || 'N/A'}
                                <div>
                                   
                                  
                                  </div>
                              </div>
                              
                              <div>
                                  <strong>Billing Address:</strong>
                                  <div>
                                    {add.address.input.streetAddress1 || 'N/A'},{" "}
                                    {add.address.input.streetAddress2 || 'N/A'},{" "}
                                    {add.address.input.city || 'N/A'},{" "}
                                    {add.address.input.countryArea || 'N/A'}{" "}
                                    {add.address.input.postalCode || 'N/A'}
                                  </div>
                                  <br />
                                </div></>
                            )}
                            {add.address?.businessaddress && (
                              <div>
                                <strong>Shipping Address:</strong>
                                <div>
                                  {add.address.businessaddress.streetAddress1 || 'N/A'},{" "}
                                  {add.address.businessaddress.streetAddress2 || 'N/A'},{" "}
                                  {add.address.businessaddress.city || 'N/A'},{" "}
                                  {add.address.businessaddress.countryArea || 'N/A'}{" "}
                                  {add.address.businessaddress.postalCode || 'N/A'}
                                </div>
                                <br />
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>
                      <div className="pt-2" style={{ paddingLeft: "39px" }}>
                        <div className="row pb-4">
                          <div className="col col-lg-4 col-12 pr-0">
                            <div className="d-flex">
                              <PhoneIcon />
                              <p className="heading-400-16-14 op-80">
                                {add.address?.input?.phone || 'N/A'}
                               
                              </p>
                            </div>
                          </div>
                          <div className="col col-lg-8 col-12">
                            <div className="d-flex">
                              <EmailIcon />
                              <p className="heading-400-16-14 op-80">
                                {/* {add.address?.input?.email || 'N/A'} */}
                                {emailIdta}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-5 col-md-3 col-12 d-flex justify-content-end">
                      <div
                        className="d-flex curser-pointer"
                        onClick={() => {
                          setLoadinged(true);
                          setTimeout(() => {
                            navigate(`/addaddresscart?AddressId=${add.mp_address_id}`, {
                              state: {
                                cancelNavi: "/manageaddresspart",
                                saveNavi: "/manageaddresspart",
                                status: "Edit Address",
                                itemId,
                                bucketId,
                                quantity,
                                orderId,
                                quantityTotal,
                                subtotalAmount,
                                gstAmount,
                                taxesAmount,
                                shippingFee,
                                totalAmount,
                              },
                            });
                          }, 2000);
                        }}
                      >
                        <EditIcon />
                        <p className="m-0 pl-2 heading-600-14">Edit Address</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col col-md-12 pt-5 d-flex justify-content-center align-items-center">
                  <p className="heading-600-24">No addresses found</p>
                </div>
              </div>
              <button
                className="unique-submitbtn gap-2 mb-3"
                style={{ marginLeft: "42%" }}
                onClick={() =>
                  navigate("/addaddresscart", {
                    state: {
                      cancelNavi: "/manageaddresspart",
                      saveNavi: "/manageaddresspart",
                      status: "Add Address",
                      itemId,
                      bucketId,
                      quantity,
                      orderId,
                      quantityTotal,
                      subtotalAmount,
                      gstAmount,
                      taxesAmount,
                      shippingFee,
                      totalAmount,
                    },
                  })
                }
              >
                Add new Address
              </button>
            </>
          )}
          <div className="row">
            <div className="col col-md-12 d-flex justify-content-end pt-4">
              <button className="unique-submitbtn gap-2 mb-3" onClick={handleDeliverHere}>
                Deliver Here
              </button>
            </div>
          </div>
        </div>
      </div>
      <AccountFooter />
    </>
  );
};

export default ManageAddressPart;
