import React from 'react'
import './PopupforCartPageStyles.css'
const PopupforCartPage = () => {
    
  return (
    <>
 <div className="row justify-content-center popuppagecartdiv">
  <div className="col-4 centeredcolumndivforpopup">
    <div className="edityouraddresstext">
      <p className="youreditaddresstext">Edit Your Address</p>
    </div>
    
    <div className="input-container m-0">
      <div className="bi-form-group-white">
        <input
          type="text"
          name="phone"
          id="phone"
          className="bi-form-field-white"
          maxLength={10}
          value=""
          placeholder="Phone Number"
        />
        <label htmlFor="phone" className="heading-400-14-12 bi-form-label-white">
          Phone Number <span style={{ color: '#CB1923' }}>*</span>
        </label>
        <span className='phniintheumber'>May be used to assist delivery</span>

      </div>
    </div>
    
    <div className="input-container m-0">
      <div className="bi-form-group-white">
        <input
          type="text"
          name="pincode"
          id="pincode"
          className="bi-form-field-white"
          value=""
          placeholder="PinCode"
        />
        <label htmlFor="pincode" className="heading-400-14-12 bi-form-label-white">
          PinCode <span style={{ color: '#CB1923' }}>*</span>
        </label>
      </div>
    </div>

    <div className="input-container m-0">
      <div className="bi-form-group-white">
        <input
          type="text"
          name="state"
          id="state"
          className="bi-form-field-white"
          value=""
          placeholder="State"
        />
        <label htmlFor="state" className="heading-400-14-12 bi-form-label-white">
          State <span style={{ color: '#CB1923' }}>*</span>
        </label>
      </div>
    </div>

    <div className="input-container m-0">
      <div className="bi-form-group-white">
        <input
          type="text"
          name="addressLine1"
          id="addressLine1"
          className="bi-form-field-white"
          value=""
          placeholder="Address Line 1"
        />
        <label htmlFor="addressLine1" className="heading-400-14-12 bi-form-label-white">
          Address Line 1 <span style={{ color: '#CB1923' }}>*</span>
        </label>

      </div>
    </div>

    <div className="input-container m-0">
      <div className="bi-form-group-white">
        <input
          type="text"
          name="addressLine2"
          id="addressLine2"
          className="bi-form-field-white"
          value=""
          placeholder="Address Line 2"
        />
        <label htmlFor="addressLine2" className="heading-400-14-12 bi-form-label-white">
          Address Line 2 <span style={{ color: '#CB1923' }}>*</span>
        </label>
      </div>
    </div>

    <div className="input-container m-0">
      <div className="bi-form-group-white">
        <input
          type="text"
          name="city"
          id="city"
          className="bi-form-field-white"
          value=""
          placeholder="City"
        />
        <label htmlFor="city" className="heading-400-14-12 bi-form-label-white">
          City <span style={{ color: '#CB1923' }}>*</span>
        </label>
      </div>
    </div>
  </div>
</div>


    </>
  )
}

export default PopupforCartPage