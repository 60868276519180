import React from 'react';
import './ErrorModal.css'; // Optional: Create a CSS file for styling

const ErrorModal = ({ messages, onClose }) => {
    return (
      <div className="modal-overlaysfbdj">
        <div className="modal-contentdvsbf">
          <h2 className='pleasefillin'>Please fill in the below </h2>
          {messages.length > 0 ? (
            <ul className='sjfkbfdcdddd'>
              {messages.map((msg, index) => (
                <li className='sfdbjgn' key={index}>{msg}</li>
              ))}
            </ul>
          ) : null}
          <button className="buttonsjxfbhdf" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };
  
  export default ErrorModal;
  