import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./BlogPageStyles.css";
import { Helmet } from "react-helmet";

const QualityProduction = () => {
    useEffect(() => {
        window.scrollTo(0,0)
        }, [])
    
        const blogPost = {
          heading: "Quality Production And Quality–Critical Factors In Enhancing Shareholder Value",
        };
    
        
      return (
        <>
        <Helmet>
                <title>{blogPost.heading}</title>
                <meta
                  name="keywords"
                  content={`${blogPost.heading}`}
                />
              </Helmet>
          <div className="blogpage-totaldiv">
            {" "}
            {/* Total div to contain all divs inside :  */}
            <div className="blogpage-headingcontent">
              <h6 className="CaseStudy">Article</h6>
              <h1 className="CaeStudy-content">
              Quality Production And Quality–Critical Factors In Enhancing Shareholder Value
              </h1>
            </div>
            <div className="blogpage-profile">
              <div className="blogpage-profile-image">
                <img
                  className="blogpage-profile-img-seperate"
                  src="https://www.origa.market/asset/user.png"
                />
              </div>
    
              <div className="blogpage-profile-text">
                <p className="blogpage-para">
                  <span>Origa</span>
                  <span className="blogpage-year">Sept 16, 2024 • 3 min read</span>
                </p>
              </div>
            </div>
            <div className="blogpage-profile-bigimage">
              <img
                className="blogpage-profile-bigimage-seperate"
                src="/asset/internalimageof6thcard.webp" 
              />
              <p className="blogpage-profile-bigimage-text">
              Quality Production And Quality
              </p>
            </div>
            <div className="blogpage-realcontent">
              <h3 className="blogpage-realcontent-introductionheading">
                Introduction:
              </h3>
              <p className="blogpage-realcontent-introductioncontent">
              High quality machinery and shareholder value!! A co-relation that has never been heard off.
However, it is important to understand how high quality production capacity plays an
important role in enhancing the intrinsic value of any company.
              </p>
    
              <p className="blogpage-realcontent-introductioncontent">
              Shareholders value of a company is majorly measured by either a revenue multiple or a
profitability multiple or a combination of both. Assuming its not a service industry, the
company needs to manufacture high quality products to keep the cash registers ringing.
Today, in a globalized competitive environment, the products manufactured need to be of
global standards, export quality, price competitive and highly scalable. All this can be
achieved only through large number high quality equipment and machinery installed, with
technology upgradation undertaken at regular intervals. A bad manufacturing plant leads to
value erosion of shareholder’s wealth over a period of time due to its non competitiveness.
              </p>
    
              <h3 className="blogpage-strategic-objectives">Problem Statement:</h3>
              <p className="blogpage-realcontent-Strategicpara">
              Its not only the large listed entities that need to mindful of their machinery, but also their
down the line MSME suppliers. These MSME entities supply large number of parts to these
large entities and are required to be equally geared up to meet the quality and productivity
standards.
              </p>


              <p className="blogpage-realcontent-Strategicpara">
              India today is one of the top five economies of the world with USD 2.6 trillion Gross
Domestic Product. It plans to touch USD 5 trillion in the next five years. In order to achieve
this, it has launched many initiatives to boost the manufacturing capabilities within the
country. This will in turn require large quantity of machinery and varied technologies.
One of the largest programs launched is the Performance Linked Incentive schemes (PLI
schemes), whereby large manufacturers will be provided incentives based on actual units
produced. The total investment committed to these schemes is over USD 30 billion.

              </p>


              <p className="blogpage-realcontent-Strategicpara">
              Additionally, India’s standoff with its neighbouring country made Government think of
becoming “Atmanirbhar” across all sectors – specially plethora of manufacturing sectors.
Though everyone knew of our dependency on imports across engineering and electronics
space, the border issue magnified the realization. Government of India has made a strong
resolution to make our country Atmanirbhar. It has launched various incentive schemes
across manufacturing segments which are expected to increase production capabilities
manifold.

<p className="blogpage-realcontent-Strategicpara">
One area which will require significant focus to achieve Government objectives is the area
of capacity building, i.e. machinery requirement, specially machinery of high quality and
high precision. India is not targeting to become the hub of casting and forging. It is aiming to
build large manufacturing capabilities across key segments such as electronics, toys,
containers, food processing, auto and pharmaceuticals etc. Many of the processes across
these segments will require the next generation production facilities and latest
technologies.
              </p>
              </p>
    

    
              <p className="blogpage-realcontent-Strategicpara">
              Today, institutional investors also value companies on the basis of their ESG (Environment,
Social and Governance) initiatives. If a company has a very polluting plant or a plant that is
producing much more wastage than warranted, it is definitely looked upon as a big
negative. Thus installing latest superior technology oriented machinery with due
consideration to climate change is crucial from ESG perspective.


<p className="blogpage-realcontent-Strategicpara">
These machineries are going to be expensive and financing it through both traditional and
innovative products will play a pivotal role in ensuring its penetration. To understand the
gravity of the resource crunch, lets look at some numbers. India has an estimated credit gap
of USD 1 trillion of which almost USD 900 Billion is met through informal sourcing of
financing. Roughly USD 200 Billon to USD 300 Billion will be required for capital expenditure
alone! In addition to financing, a lot of hand holding needs to be done across MSME
segment with respect to availability of machines, brands, skilled manpower to use those
machines and most importantly its servicing and maintenance to ensure high level of
productivity at all times.
              </p></p>

              <p className="blogpage-realcontent-Strategicpara">
              With respect to financing, it is thus important that the financing companies stands up to this
              requirement and provide innovative solutions across the value chain.
              </p>

              <p className="blogpage-realcontent-Strategicpara">
             
For India to march ahead in its manufacturing, a paradigm shift is required on how financial
institutions including banks evaluate a funding opportunity. The current over emphasis on
historical performance with little weightage to future needs to change. Capabilities will
need to be developed within the financial sector to create risk reward matrix of growth
oriented debt/fixed income capital. Additional collateral has not helped much in recovery of
NPAs and thus the thrust should be given to cashflow backed financing than accounting
profit based financing.

<p className="blogpage-realcontent-Strategicpara">
It is important that equipment financing institutions understand the cashflow generating
ability of the production facility and take a futuristic view. New age products such as leasing
can solve a large part of this challenge. Leasing when done in a pure form and not
camouflaged as loan, can lead to significant impact on the ground. Leasing not only
considers future cashflows of the company, but it also undertakes deeper technology life
cycle analysis of the equipment. This helps them to provide a comprehensive solution to its
clients rather than just capital.
              </p>    </p>
     
    
              <ul className="blogpage-unorderd">
              <span className="blogpage-boost">Leasing is very different from loan in many ways. Some of the critical factors that
              differentiates the two are –</span>
                <li className="blogpage-list dljgn">
                  {" "}
                  <span className="blogpage-boost">a)</span>Leasing lays more emphasis on the quality of machinery and its future earning
                  capability.
                </li>
                <li className="blogpage-list">
                  {" "}
                  <span className="blogpage-boost">b)</span>
                  Detailed unit economics based on cashflows is considered for calculating lease
servicing
                </li>
                <li className="blogpage-list">
                  {" "}
                  <span className="blogpage-boost">c)</span>
                  Leasing provides multiple options of technology upgradation at the end of the
tenure or at the end of the equipment lifecycle
                </li>
                <li className="blogpage-list">
                  {" "}
                  <span className="blogpage-boost">d)</span> 
                  Leasing strengthens balancesheet of the company over a period of time
                </li>
              </ul>


              <p className="blogpage-realcontent-innovativecontent">
              Leasing is of two types – finance lease and operating lease. Finance lease is very akeen to
term loans whereas operating lease is a highly specialised product that charges monthly

rentals for financing the equipment. In an operating lease, the company availing the lease
(Lessee) neither shows the equipment as fixed assets nor the liability as loan. The entire
leasing is expensed out over a period of the lease tenure. When a company plans to grow at
a steady growth rate with expansion at regular intervals, leasing is one of the best options
available.
              </p>
    
              <div className="blogpage-realcontent-leasingstructureparagrapdiv">
             
                  <p className="blogpage-realcontent-leasingstructureparagrap">
                  Leasing has been a strong force of industrialization in the developed economy post world
war II. Leasing has contributed immensely to the industrial revolution of the countries such
as the USA, Germany and Japan. Today, it is leading to growth even in developing world
such as South East Asia, Africa and China. On an average, leasing funds almost USD 1 trillion
of new capital expenditure in the USA each year.
                  </p>
                  <p className="blogpage-realcontent-leasingstructureparagrap">
                  Investments in manufacturing capabilities is expected to have a snowball impact on the
services sector of India. India will require large number of startups, enterprises and
consultants across areas such as skill development, machine servicing, waste management,
taxation etc. This will lead to leasing requirements in other categories of assets such as
Information Technology (computers, data servers etc), auto and infrastructure (construction
equipment).
manifold.
                  </p>
               
              </div>
    



              <h3 className="blogpage-realcontent-conclusion">Conclusion:</h3>
    
    <p className="blogpage-realcontent-conclusionparagraph">
    Today, institutional investors also value companies on the basis of their ESG (Environment,
Social and Governance) initiatives. If a company has a very polluting plant or a plant that is
producing much more wastage than warranted, it is definitely looked upon as a big
negative. Thus installing latest superior technology oriented machinery with due
consideration to climate change is crucial from ESG perspective.
    </p>


            </div>
          </div>
    
          <div className="lastline">
            <hr className="lastlinehr" />
          </div>
    
          <div className="somespacetop"></div>
          <Footer />
        </>
      );
    };
export default QualityProduction