import React from 'react'
import   {Circles} from 'react-loader-spinner'

// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
const Loader = () => {
  return (
    <div className=' bi-popup-wrap d-j-a' > {< Circles color='gray'/>}</div>
  )
}

export default Loader