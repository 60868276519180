import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../Footer/Footer";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../OrigaExtentionAPI/mutations";
import "./CartPageStyles.css";

const client = secondClient;

const CartPage = () => {
  const location = useLocation();
  const [infokey, setInfokey] = useState(0);
  const [productDETAILS, setProductDETAILS] = useState({});
  const [SuccessPopUp, setSuccessPopUp] = useState(false);
  const [PaymentLink, setPaymentLink] = useState([]);
  const navigate = useNavigate();
  // Retrieve productId and groupId from localStorage
  const productId = localStorage.getItem("productIdtoCart");
  const groupId = localStorage.getItem("groupIdtoCart");
  const [quantity, setQuantity] = useState(1);
  const price = productDETAILS?.productDetails?.sales_rate;//derieves the sales_rate from productDetails

  const handleIncreaseQuantity = () => {
      setQuantity(prevQuantity => prevQuantity + 1);//for increasing
  };

  const handleDecreaseQuantity = () => {
      setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1));//for decreasing
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: "", itemId: productId },
        });
        setProductDETAILS(data?.inventoryItem?.response);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    fetchData();
  }, [productId]); // Dependency array includes productId

  const handleModal = async () => {
    // Check if the user is logged in
    const loggedIn = !!localStorage.getItem("userToken");
    if (!loggedIn) {
      // If not logged in, redirect to login page or handle login logic
      navigate("/login"); // Redirect to login page
      return;
    }

    // Navigate to a specific route with query parameters
    navigate(`/buy/add-address?id=${productId}&sparetools=true`);
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);

  //   const fetchCartItems = async () => {
  //     const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
  //     const promises = cartItems.map(async (item) => {
  //       try {
  //         const { data } = await client.query({
  //           query: GET_SPARE_DETAILS,
  //           variables: { groupId: "", itemId: productId },
  //         });
  //         return data?.inventoryItem?.response;
  //       } catch (error) {
  //         console.error("Error fetching product details:", error);
  //         return null;
  //       }
  //     });

  //     const responses = await Promise.all(promises);
  //     setProductDETAILS(responses.filter((item) => item !== null));
  //   };

  //   fetchCartItems();
  // }, [productId]);

  const productName = productDETAILS[0]?.name || "N/A";
  const productBrand = productDETAILS[0]?.brand || "N/A";

  const productImage =
    productDETAILS[0]?.images?.productImages[0]?.url || "N/A";
  const salePrice = productDETAILS[0]?.saleprice || 0;
  const AvailableStock = productDETAILS[0]?.available_stock || "N/A;";

  console.log("productIdtoCart--->", localStorage.getItem("productIdtoCart"));
  console.log("groupIdtoCart = ", localStorage.getItem("groupIdtoCart"));
  console.log("product DETAILS  --> ", productDETAILS);
  return (
    <>
      <div className="container-fluid">
        <div className="layout-wrap-machine">
          <div className="max-container detail-wrap ">
            <div className="img-wrap-1">
              <img className="img-1" src={productImage} alt="Product" />
            </div>
            <div className="price-wrap">
              <div className="top">
                <div className="right bi-process-wrap">
                <div className="name heading-600-20 heading-600-20-16">
                  {productName}
                  {productBrand && (
                    <span className="heading-400-20-14 op-60">
                      &nbsp;| {productBrand}
                    </span>
                  )}
                </div>
                <div className="price">No of Stock : {AvailableStock}</div>
                <div className="quantity-selector tobetopfirst">
                <button
                  onClick={handleDecreaseQuantity}
                  className="box-item-btn1"
                >
                  -
                </button>
                
                <p class="custom-badge">{quantity}</p>
                
            
                <button
                  onClick={handleIncreaseQuantity}
                  className="box-item-btn1"
                >
                  +
                </button>
              </div>
                </div>
              </div>
            </div>
            <div className="bottom">
              <div className="left-wrap">
                <div className="price bold-text  ">₹{salePrice * quantity}</div>
              </div>
             
              <div className="right-wrap">
                <div className="advance-info tobetop">
                  <button
                    onClick={handleModal}
                    className=" button heading-600-16-14"
                    type="button"
                  >
                    Proceed to buy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
