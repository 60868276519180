import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dollerIcon, phoneIcon, tagIcon } from "../../helpers/Icons";
import "./MyOrdersDesign.css";
import axios from "axios";
import Footer from "../Footer/Footer";

const MyOrderSummaryDesigRevisedCode = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const message = queryParams.get("message");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSize, setSize] = useState({ width: 48, height: 48 });
  const [invoiceUrl, setInvoiceUrl] = useState("");

  //To show the selectedAddress that the user have choosen
  const selectedAddressIndex = parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;
  const selectedAddress = data ? data[selectedAddressIndex]?.address : null;


  //Go to orders page when clicked on Your order Button :
  const handlegotoOrdersPage = () => {
    navigate("/yourorder");
  };

  //When download Invoice button is clicked this will help to get the invoice link
  const handleDownloadInvoice = () => {
    console.log("Inside HandleDownloadInvoice Button Function  : ");
    if (invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    } else {
      alert("Invoice URL not available");
    }
  };

  //This is used for to fetch all order of a customer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number")
          }
        );
        console.log("API Response:", response.data);
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  /*This Function  : For the related the reference id and the customer id i will send to the api and */
  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      console.log(
        "Correct details : ------------------> ",
        customerId,
        referenceNumber
      );
      try {
        console.log("Fetching order details for:", {
          customerId,
          referenceNumber,
        });

        const response = await fetch(
          `https://ompextension.origa.market/sparesapi/get_orders`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customer_id: customerId,
              reference_number: referenceNumber,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }

        const data = await response.json();
        console.log("API response:", data);

        if (data.order) {
          setOrderDetails(data.order);

          // When dowload invoice is clicked it should get the link from the response of the the api
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    //This fetch operation is triggered only if both id and message  = Paid
    if (id && message === "Paid") {
      const customerId = localStorage.getItem("id");
      fetchOrderDetails(customerId, id);
      console.log("Now here : ");
    }
  }, [id, message]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!orderDetails) {
    return <div>No order details found</div>;
  }
  return (
    <>
     <div className="OrderSummaryTotalPageDiv">
  <div className=" ordercontainerrrrr container">
    <div className="row">
      <div className="col-12">
        <div className="totalcontentsummary">
          <div className="ordersummaryh1div">
            <h1 className="ordersummaryh1">Order Summary :</h1>
          </div>
          <div className="ordersummarystepperdiv">
          <div className="lrbnbnf right bi-process-wrap">
  <span className="completed icon-container">
    {tagIcon({
      width: isSize.width,
      height: isSize.height,
      className: "completed",
    })}
  </span>
  <span className="completed icon-container">
    {dollerIcon({
      width: isSize.width,
      height: isSize.height,
      className: "",
    })}
  </span>
  <span className="completed icon-container">
    {dollerIcon({
      width: isSize.width,
      height: isSize.height,
      className: "",
    })}

  </span>
</div>
          </div>
        </div>

        <div className="BelowOrderSummaryDiv">
  <div className="order-summary-wrap">
    <div className="order-container">
      {orderDetails.line_items.map((item, index) => (
        <div className="order-item" key={index}>
          <div className="product-wrapEB">
            <div className="left-wraRSNFFDp">
              <img
                className="product-SBF"
                src={item.thumbnail[0]?.url || "N/A"}
                alt="Product Image"
              />
              <div className="detail-wrap">
                <div className="heading-600-16 title">
                  {item.name}
                </div>
                <div className="price-wrap">
                  <div className="heading-600-14 price">
                    ₹{item.rate}
                  </div>
                  <div className="heading-600-14 price">
                 Quanitity :  {item.quantity}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>

    <div className="amount-summary">
      <div className="summary-heading">Amount Summary</div>
      <div className="summary-item">
        <span className="summary-label">Total Price:</span>
        <span className="summary-value">₹{orderDetails.total}</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Delivery Charges:</span>
        <span className="summary-value">₹{orderDetails.shipping_charge}</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Total Quantity:</span>
        <span className="summary-value">{orderDetails.total_quantity}</span>
      </div>
      <div className="summary-item">
        <span className="summary-label">Grand Total:</span>
        <span className="summary-value">₹{orderDetails.total + orderDetails.shipping_charge}</span>
      </div>

    </div>
    <div className="somespavebetween"></div>
    <div className="amount-summary">
    <div className="summary-label">
               
   {selectedAddress && (
                      <>
                       <div className="summary-heading">Delivery Address</div>
                        <span className="headingindelivery">Name : </span>
                        {selectedAddress.input.firstName} {selectedAddress.input.lastName}
                        <br></br>
                        <span className="headingindelivery">
                          Mobile Number :{" "}
                        </span>
                        {selectedAddress.phone}
                        <br></br>
                        <span className="headingindelivery">
                          Billing Address:{" "}
                        </span>
                        <br></br>
                        {selectedAddress.input.streetAddress1},<br></br>
                        {selectedAddress.input.city},{selectedAddress.input.countryArea},{selectedAddress.countryArea},{" "}
                        {selectedAddress.input.postalCode}
                        <br></br>
                        <span className="headingindelivery">
                          Shipping Address:{" "}
                        </span>
                        <br></br>
                        {selectedAddress.businessaddress?.streetAddress1},{" "}
                        {selectedAddress.businessaddress?.streetAddress2}
                        {selectedAddress.businessaddress.countryArea},<br></br>
                        {selectedAddress.businessaddress?.city},
                        {selectedAddress.businessaddress?.postalCode}
                        <br></br>
                      </>
                    )}


                  </div>
    
    </div>
  </div>
</div>


      </div>

      <div className="delivery-btn-wrap rwbjkn">
                  <button className="buttonhandlegotorders" onClick={handlegotoOrdersPage}>
                    Go to orders
                  </button>
                  <button className="buttondownloadinvoice" onClick={handleDownloadInvoice}>
                    Download invoice
                  </button>
                </div>
    </div>
  </div>
</div>
    </>
  );
};

export default MyOrderSummaryDesigRevisedCode;
