import React,{useState} from 'react'
import Col from 'react-bootstrap/Col';
const Avatar = () => {
  const [isHover,setIshover]=useState(false);  
  const onEnter=()=>{
setIshover(true);
  }
  const onLeave=()=>{
      setIshover(false)
  }
  return (
    <Col style={{paddingRight:'0'}} onMouseEnter={onEnter} onMouseLeave={onLeave}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 7.0294 7.02944 2.99995 12 2.99995C16.9705 2.99995 21 7.0294 21 12C21 16.9704 16.9705 21 12 21C7.02944 21 3 16.9704 3 12ZM12 4.29094C7.74244 4.29094 4.29098 7.74239 4.29098 12C4.29098 14.0004 5.05298 15.823 6.30256 17.193C7.50314 15.3175 9.60601 14.0725 12.0005 14.0725C14.3947 14.0725 16.4973 15.3173 17.6981 17.1924C18.9472 15.8224 19.7091 14.0001 19.7091 12C19.7091 7.74239 16.2575 4.29094 12 4.29094ZM16.7318 18.0864C15.7834 16.4568 14.0192 15.3635 12.0005 15.3635C9.98162 15.3635 8.21726 16.457 7.26901 18.0869C8.57504 19.1036 10.2168 19.709 12 19.709C13.7835 19.709 15.4256 19.1033 16.7318 18.0864ZM8.8067 10.6478C8.8067 8.88409 10.2365 7.45431 12.0002 7.45431C13.7639 7.45431 15.1937 8.88409 15.1937 10.6478C15.1937 12.4115 13.7639 13.8413 12.0002 13.8413C10.2365 13.8413 8.8067 12.4115 8.8067 10.6478ZM12.0002 8.7453C10.9495 8.7453 10.0977 9.59708 10.0977 10.6478C10.0977 11.6985 10.9495 12.5503 12.0002 12.5503C13.0509 12.5503 13.9027 11.6985 13.9027 10.6478C13.9027 9.59708 13.0509 8.7453 12.0002 8.7453Z" fill={isHover?"#999e51":"#211E24"}/>
</svg></Col>
  )
}

export default Avatar