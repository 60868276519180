
import React, { useEffect, useState } from "react";
import './QuotationPopupStylesForBuyNow.css'
import { closeIcon } from "../../../helpers/Icons";
import { useNavigate } from "react-router-dom";

const QuotationPopupForBuynow = ({ bucketId, itemId, quantity, itemName, price, thumbnailUrl, subtotal, total, totalQuantity }) => {


  const [showThanksPopup, setShowThanksPopup] = useState(false);
   const navigate = useNavigate();
    // Function to handle the API call
    const [showPopup, setShowPopup] = useState(false);
    const handleSubmitdns = async () => {
        // Check if function is being triggered
        console.log('Submit button clicked');
      
      
        // Construct the payload for the API request
        const loggedin = !!localStorage.getItem('userToken');
        const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName");
        const customer_contact_no = localStorage.getItem("number");
        const customer_email = localStorage.getItem("emailId");
      
        const payload = {
          items: [{
            item_id: itemId,
            quantity: quantity,
            bucket_id: bucketId,
          }],
          ompuserId, // get the id from the localStorage
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: ""
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "IN",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: ""
          },
          gst_no: ""
        };
      
        try {
          // Make the API request
          const response = await fetch('https://ompextension.origa.market/api/request_quotation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          });
      
          const data = await response.json();
          if (data.message === "Quotation request saved successfully!") {
            setShowPopup(false);
            setShowThanksPopup(true);
            setTimeout(() => {
              setShowThanksPopup(false);
              navigate("/store");
            
            }, 4000);
          } else {
            console("Failed to raise a quotation");
          }
        } catch (error) {
          console.error("Error submitting quotation:", error);
          alert("Error submitting quotation.");
        }
      };
      
    // Log props to verify they are passed correctly
    console.log("Props received in QuotationPopup:");
    console.log("Bucket ID:", bucketId);
    console.log("Item ID:", itemId);
    console.log("Quantity:", quantity);
    console.log("Item Name:", itemName);
    console.log("Price:", price);
    console.log("Thumbnail URL:", thumbnailUrl);
    console.log("Subtotal:", subtotal);
    console.log("Total:", total);
    console.log("Total Quantity:", totalQuantity);
    return (

        <>
      {/* <div>
        <h3>Quotation Details</h3>
        <p>Bucket ID: {bucketId}</p>
        <p>Item ID: {itemId}</p>
        <p>Quantity: {quantity}</p>
        <p>Item Name: {itemName}</p>
      </div> */}


<div className="totalpopupdiv">
            <h4 className="quotationDetailsheading">Quotation Details</h4>
            <div className="popupContentWrapper">
                <div className="Contentisnidepopup">
                    <p className="">Name: {itemName}<br></br>
                    Price:  ₹{price}<br></br>
                    Total: ₹{total}<br></br>
                    Total Quantity: {totalQuantity}<br></br>
                    </p>
                    {/* <p>Price:  ₹{price}</p>
            <p>Total: ₹{total}</p>
            <p></p> */}

                    <button
                        className="button-submit-popup"
                        onClick={handleSubmitdns}
                    >
                        Submit
                    </button>
                </div>
                <div className="imageWrapper">
                <img src={thumbnailUrl} alt={itemName} style={{ width: '100px', height: '100px' }} />
                </div>
            </div>
        </div>

        
        {showThanksPopup && (
                  <div className="  popup-overlay ">
                    <div className="popup-banner ">
                      <div
                        className="popup-content "
                        style={{ backgroundColor: "#d2eff1" }}
                      >
                        <div
                          className="differentthankspopupcontnet"
                          style={{ backgroundColor: "white" }}
                        >
                          <h3 className="thanksforyourresponse">
                            Thank you for your quotation request!
                          </h3>
                          <p className="wewillcontactu">
                            We'll get back to you soon
                          </p>
                          <br></br>
                          <div
                            className="close-popup"
                            onClick={() => setShowThanksPopup(false)}
                          >
                            {closeIcon({ width: 14, height: 14 })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
      </>
    );
  };
  
  export default QuotationPopupForBuynow;
  