import React, { useState } from 'react'

const DollerWithStar = () => {
  const [isHover,setIshover]=useState(false)
  return (
    <div className='d-j-a' onMouseEnter={()=>setIshover(true)} onMouseLeave={()=>setIshover(false)} style={{height:"90px"}}> 
    <svg xmlns="http://www.w3.org/2000/svg" width={isHover ?"65":"55"} height={isHover ?"70":"60"} viewBox="0 0 55 60" fill="none">
  <g opacity="0.6">
    <path d="M27.1 38.6C37.7 38.6 46.4001 29.9 46.4001 19.3C46.4001 8.7 37.7 0 27.1 0C16.5 0 7.80005 8.7 7.80005 19.3C7.80005 30 16.5 38.6 27.1 38.6ZM27.1 2C36.7 2 44.5 9.8 44.5 19.4C44.5 29 36.7 36.8 27.1 36.8C17.5 36.8 9.70005 29 9.70005 19.4C9.70005 9.8 17.5 2 27.1 2Z" fill="#211E24"/>
    <path d="M26.2001 26.1002C24.8001 26.1002 23.6001 25.6002 22.7001 25.0002L22.1001 26.6002C23.0001 27.2002 24.4001 27.7002 25.9001 27.7002V30.1002H27.4001V27.6002C29.9001 27.2002 31.3001 25.4002 31.3001 23.4002C31.3001 21.3002 30.0001 19.9002 27.5001 19.0002C25.4001 18.1002 24.5001 17.5002 24.5001 16.2002C24.5001 15.2002 25.2001 14.1002 27.1001 14.1002C28.6001 14.1002 29.6001 14.7002 30.2001 15.0002L30.8001 13.4002C30.1001 13.0002 29.1001 12.6002 27.6001 12.5002V10.2002H26.1001V12.6002C23.9001 13.0002 22.4001 14.5002 22.4001 16.6002C22.4001 18.7002 23.9001 19.9002 26.5001 20.9002C28.3001 21.7002 29.3001 22.4002 29.3001 23.8002C29.1001 25.2002 28.0001 26.1002 26.2001 26.1002Z" fill="#211E24"/>
    <path d="M53.2 58H1C0.4 58 0 58.4 0 59C0 59.6 0.4 60 1 60H53.2C53.8 60 54.2 59.6 54.2 59C54.2 58.4 53.7 58 53.2 58Z" fill="#211E24"/>
    <path d="M3.70009 49.8998L3.20009 52.7998C3.10009 53.3998 3.30009 53.9998 3.90009 54.2998C4.40009 54.6998 5.10009 54.6998 5.50009 54.3998L8.10009 52.9998L10.7001 54.3998C10.9001 54.4998 11.2001 54.5998 11.5001 54.5998C11.8001 54.5998 12.2001 54.4998 12.5001 54.2998C13.0001 53.8998 13.3001 53.2998 13.2001 52.7998L12.7001 49.8998L14.8001 47.8998C15.3001 47.4998 15.4001 46.7998 15.2001 46.2998C15.0001 45.6998 14.5001 45.2998 13.9001 45.1998L11.1001 44.7998L9.80009 42.1998C9.50009 41.5998 9.00009 41.2998 8.40009 41.2998C7.80009 41.2998 7.20009 41.6998 7.00009 42.1998L5.70009 44.7998L2.80009 45.1998C2.20009 45.2998 1.70009 45.6998 1.50009 46.2998C1.30009 46.8998 1.50009 47.4998 1.90009 47.8998L3.70009 49.8998ZM5.80009 46.5998C6.30009 46.4998 6.80009 46.1998 7.00009 45.6998L8.10009 43.4998L9.20009 45.6998C9.40009 46.1998 9.90009 46.4998 10.4001 46.5998L12.8001 46.9998L11.1001 48.6998C10.7001 49.0998 10.5001 49.5998 10.6001 50.0998L11.0001 52.4998L8.90009 51.2998C8.40009 50.9998 7.80009 50.9998 7.50009 51.2998L5.40009 52.4998L5.80009 50.0998C5.90009 49.5998 5.70009 48.9998 5.30009 48.6998L3.30009 46.9998L5.80009 46.5998Z" fill="#211E24"/>
    <path d="M34.3999 46.2002C34.1999 45.6002 33.6999 45.2002 33.0999 45.1002L30.1999 44.7002L28.8999 42.1002C28.5999 41.5002 28.0999 41.2002 27.4999 41.2002C26.8999 41.2002 26.2999 41.6002 26.0999 42.1002L24.7999 44.7002L21.8999 45.1002C21.2999 45.2002 20.7999 45.6002 20.5999 46.2002C20.3999 46.8002 20.5999 47.4002 20.9999 47.8002L23.0999 49.8002L22.5999 52.7002C22.4999 53.3002 22.6999 53.9002 23.2999 54.2002C23.8999 54.5002 24.4999 54.6002 24.8999 54.3002L27.4999 52.9002L30.0999 54.3002C30.2999 54.4002 30.5999 54.5002 30.8999 54.5002C31.1999 54.5002 31.5999 54.4002 31.8999 54.2002C32.3999 53.8002 32.6999 53.2002 32.5999 52.7002L32.0999 49.8002L34.1999 47.8002C34.3999 47.4002 34.5999 46.8002 34.3999 46.2002ZM30.3999 48.6002C29.9999 49.0002 29.7999 49.5002 29.8999 50.0002L30.2999 52.4002L28.1999 51.2002C27.6999 50.9002 27.0999 50.9002 26.7999 51.2002L24.6999 52.4002L25.0999 50.0002C25.1999 49.5002 24.9999 48.9002 24.5999 48.6002L22.8999 46.9002L25.2999 46.5002C25.7999 46.4002 26.2999 46.1002 26.4999 45.6002L27.5999 43.4002L28.6999 45.6002C28.8999 46.1002 29.3999 46.4002 29.8999 46.5002L32.2999 46.9002L30.3999 48.6002Z" fill="#211E24"/>
    <path d="M45.6 41.2002C45 41.2002 44.4 41.6002 44.2 42.1002L42.9 44.7002L40 45.1002C39.4 45.2002 38.9 45.6002 38.7 46.2002C38.5 46.8002 38.7 47.4002 39.1 47.8002L41.2 49.8002L40.7 52.7002C40.6 53.3002 40.8 53.9002 41.4 54.2002C41.7 54.4002 42 54.5002 42.4 54.5002C42.7 54.5002 42.9 54.4002 43.2 54.3002L45.8 52.9002L48.4 54.3002C49 54.6002 49.6 54.5002 50 54.2002C50.5 53.8002 50.8 53.2002 50.7 52.7002L50.2 49.8002L52.3 47.8002C52.8 47.4002 52.9 46.7002 52.7 46.2002C52.5 45.6002 52 45.2002 51.4 45.1002L48.5 44.7002L47.2 42.1002C46.7 41.5002 46.2 41.2002 45.6 41.2002ZM47.9 46.6002L50.3 47.0002L48.6 48.7002C48.2 49.1002 48 49.6002 48.1 50.1002L48.5 52.5002L46.4 51.3002C46.2 51.2002 45.9 51.1002 45.6 51.1002C45.3 51.1002 45.1 51.2002 44.8 51.3002L42.7 52.5002L43.1 50.1002C43.2 49.6002 43 49.0002 42.6 48.7002L40.8 47.0002L43.2 46.6002C43.7 46.5002 44.2 46.2002 44.4 45.7002L45.5 43.5002L46.6 45.7002C46.9 46.2002 47.3 46.5002 47.9 46.6002Z" fill="#211E24"/>
  </g>
</svg>
</div>
  )
}

export default DollerWithStar