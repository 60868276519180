import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginModel from '../Authentication/LoginModel/LoginModel';
import ImageSlider from '../Buying/Modals/ImageSlider.jsx';
import { framre3dIcon } from '../../helpers/Icons.js';
import ThreeDIcon from '../SubComponent/AllSvgs/ThreeDIcon';
import { heartIcon } from '../../helpers/Icons';
import useAMCWishListAddOrUpdate from '../SubComponent/useAMCWishListAddOrUpdate.jsx';
import TabSlider from '../SubComponent/AllSlider/TabSlider';

import SummaryCartLoggedNot from './SummaryCartLoggedNot.jsx';
import SummaryCartforamc from './SummaryCartforamc.jsx';
import { Container,Button } from 'react-bootstrap';
import client from '../Services/ServicesPopup/apolloclient'
import { gql } from 'graphql-tag';
import "../Addonservice/success.css"
import Footer from '../Footer/Footer.jsx';
import Loader from '../SubComponent/Loader.jsx';

const CREATE_PAYMENT = gql`
mutation CreateAmc($amcPlanData: AMCPlanInput!) {
  createAmc(amcPlanData: $amcPlanData) {
    message
    success
    paymenturl
    referenceid
  }
}
`;
const CartPageAmc = () => {


  useEffect(() => {
window.scrollTo(0,0);
  },[])
    const [loading, setLoading] = useState(true);
    const [loadinged, setLoadinged] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedCategory, selectedLocation, firstname, serialNo } = location.state || {};
    const product = location.state?.product; // Use optional chaining to safely access product
    const productData = location.state && location.state.product;
    // console.log('Selected Category in CartPageAmc:  ', selectedCategory);
    //  console.log('Selected Location in CartPageAmc:  ', selectedLocation);
    // console.log('Selected Firstname in CartPageAmc:  ', firstname);
    //  console.log('Selected serialNo in CartPageAmc:  ', serialNo);
    //  console.log("appAmcPlanId : ------------------------>",productData?.id);
    //  console.log("Here Product  Name : - ",product.name);
    //  console.log("Here Product Status  :  -",product.status);
    //  console.log("Here Product Status  :  -",product?.plan);
    //  console.log("Here Product Status  :  -",product?.gst_amount);
    //  console.log("Here Product Status  :  -",product?.total_amount);
     
    //  console.log("Here Product Type :  -",product.type);
    //  console.log("AMC plan id :  ",productId);
     const AMC_BASED_ON_TYPE = location.state?.selectedCategory?.toUpperCase();  


    //  console.log("AMC BASED ON TYPE in Cart finCartPageAmc : -------------> ",AMC_BASED_ON_TYPE);
    const [responseData, setResponseData] = useState(null);
    // console.log("product data=>>", productData);
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { onWishlistHandler, heartColor } = useAMCWishListAddOrUpdate();
    
    const onMouseEnterhandler = () => {
        setIsHovered(true);
    };

    const onMouseLeavehandler = () => {
        setIsHovered(false);
    };

    const handleModal = (status) => {
        setShowModal(status);
    };

    // console.log("ProductIdSecondSection===>>>", product);

    

    const [SuccessPopUp, setSuccessPopUp] = useState(false);
    const [PaymentLink, setPaymentLink] = useState([]);


    const GotoAddressPage = () => {
      // navigate('/manageaddresspartforamc', {
        navigate('/addaddresscartforamc', {
        state: {
            selectedCategory,
            selectedLocation,
            firstname,
            serialNo,
            productId: productData?.id,
            productName:productData?.name,
            producttype:productData?.type,
            productPrice:productData?.price,
            productPlan:product?.plan,
            productGstAmount:product?.gst_amount,
            productTotalAmount:product?.total_amount,
            productImage:product.plan_image,
            productStatus:product?.status ,
            titlename:"Add Address"



            
        }
    });
    }
    
  // const onBuyHandler = async () => {
  //   console.log('wwswswswswwsw');
  //   const id = localStorage.getItem('id');
  //   try {
  //     const { data } = await client.mutate({
  //       mutation: CREATE_PAYMENT,
  //       variables:
  //       {
  //         "amcPlanData": {
  //            "appAmcPlanId": productData?.id,
  //             "customerId": id,
  //             "stage": "PAYMENT",
  //             "serviceType": "AMC",
  //             "category":selectedCategory,
  //             "location":selectedLocation,
  //             "serialNo":serialNo,
  //             "firstName":firstname,
  //         }
  //       }
  //     });
  //     console.log("API Response paymentgetway==>>>", data);
  //     console.log("Create payment is happening : ------------------------>");
  //     console.log("appAmcPlanId : ------------------------>",productData?.id);
  //     setResponseData(data);
  //     setSuccessPopUp(true)
  //     setPaymentLink(data.createAmc.paymenturl);
  //     console.log("Payment Link :  ---------------------------------> ",PaymentLink);
  //     //window.location.href = data.createAmc.paymenturl;

  //   } catch (error) {
  //     console.error('API Error==>', error.message);

  //   }
  // }

    return (
        <>

{loadinged && <Loader />}
         {SuccessPopUp ? (
        <Container fluid className="p-fixed bg-blur hw-100 d-j-a">
          <div className='App'>
            <div className='success-login'>
              <div className='contents'>
                <h3 className='heading-600-28'>Confirm Payment</h3>
                <p className='heading-400-16 op-60'>Proceed to the payment</p>
                <div className="d-flex justify-content-between mt-3">
                  <a href={PaymentLink} rel="noreferrer" >
                    <Button variant="success" className="me-2">
                      Pay Now
                    </Button>
                  </a>
                  <Button variant="danger" style={{ marginLeft: '8px' }} onClick={() => setSuccessPopUp(false)}>
                    Cancel
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </Container>
      ) : null}

        {/* <div className="amccartpage"> */}

    
          <div className="totalcartpage">
        <div className="leftsideBordertotaldiv">
          <div className="toppartofcartpage row justify-content-between align-items-center">
            <div className="leftheadingcart col-4">
              <h1 className="leftheadingcarttext">My Cart</h1>
            </div>
            {/* <div className="rightheadingcart col-4">
              <h1 className="rightheadingcarttext">Price</h1>
            </div> */}
          </div>

          <div className="horizontallineofcartpage">
            <hr className="lineofcartunder" />
          </div>

  <div className="threesectionsofcart">
            <div className="firstsectionofcart">
                {/* <img className="imageonleftofcart"src={product.plan_image} alt={product.name} /> */}
                <img
  className="imageonleftofcart"
  src={product ? product.plan_image : 'fallback-image-url'} // Use a fallback image URL or placeholder
  alt={product ? product.name : 'Default Alt Text'} // Provide a default alt text
/>
            </div>
            <div className="secondsectionofcart">
            <h1 className="headingpartofcarttt">
    {product?.plan} Preventive maintenance  (PM) plan for {product?.type || 'Unknown'} machine 
    <br />
    {product?.name ? (
        <span className="rbfbef heading-400-20-14 op-60">
            &nbsp;| {product.name}
        </span>
    ) : "N/A"}
</h1>
Status : {product?.status || 'N/A'}
<div className="contentofcartpara">
    <p className="inStocktext">
        {/* <span className="inStocktextcontent">₹{product?.price || '0'}</span> */}
    </p>
</div>

               
            </div>
            <div className="thirdsectionofcart mt-5">
                {/* <p className="badgerightside">
                    <span className="salespricecart">GST : ₹{product. gst_amount}</span>
                    
                </p>
                <p className="badgerightside">
                   
                    <span className="salespricecart">Total Amount ₹{product. total_amount}</span>
                </p> */}
            </div>
            <div className='basespace'>
              
            </div>
            
            
        </div>
        

        <div>

{/* <div className='d-flex justify-content-end'>
<Button message={"Proceed to Payment"}   />
</div> */}

       
        {/* <button className="proceedcartbtnnew"  onClick={onBuyHandler}>
    Confirmation 
        </button> */}
        
        </div> 

        {/* <div className='proceddcartbuttondivrshfhf'>
        <button className="proceedcartbtnnew"  onClick={GotoAddressPage}>
  Add Address
        </button>
        </div> */}
        



        </div>

        <div className="rightsideBordertotaldiv">
     <SummaryCartforamc />
         
        </div>

        
        </div>


        {/* </div> */}

        <Footer className="foootterrr"/>

        </>
    );
}

export default CartPageAmc;




  {/* <div>
                <h1>Cart Page for AMC</h1>
                {product ? (
                    <>
                            <div className="heading-600-24 heading-600-24-20 heading-600-24-16 heading-600-24-14">{product?.plan} Annual maintenance contract (AMC) plan for {product.type} machine </div>
                        
                        <p>Product Name: {product.name}</p>
                        <p>Product Plan Amount: {product.price}</p>
                        <p>Product GST: {product. gst_amount}</p>
                        <p>Total Amount: {product.total_amount}</p>
                        <img src={product.plan_image} alt={product.name} />




                    </>
                ) : (
                    <p>No product data available</p>
                )}
             
            </div> */}