import React from 'react'

const RightArrow = ({callFun}) => {
    
    
    const onCallFun=()=>{
        if(callFun){
            callFun();
        }else return
        
       }
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={onCallFun}>
    <path d="M5 12H16.86L13.23 16.36C13.146 16.4611 13.0826 16.5778 13.0437 16.7034C13.0047 16.829 12.9909 16.961 13.003 17.0919C13.0274 17.3564 13.1558 17.6003 13.36 17.77C13.5642 17.9397 13.8275 18.0214 14.0919 17.997C14.3563 17.9726 14.6003 17.8442 14.77 17.64L19.77 11.64C19.8036 11.5923 19.8337 11.5421 19.86 11.49C19.86 11.44 19.91 11.41 19.93 11.36C19.9753 11.2453 19.9991 11.1233 20 11C19.9991 10.8767 19.9753 10.7547 19.93 10.64C19.93 10.59 19.88 10.56 19.86 10.51C19.8337 10.4579 19.8036 10.4077 19.77 10.36L14.77 4.36C14.676 4.24712 14.5582 4.15634 14.4252 4.09412C14.2921 4.0319 14.1469 3.99976 14 4C13.7663 3.99955 13.5399 4.08092 13.36 4.23C13.2587 4.31395 13.175 4.41705 13.1137 4.5334C13.0523 4.64975 13.0145 4.77705 13.0025 4.90803C12.9904 5.03901 13.0043 5.17108 13.0433 5.29668C13.0824 5.42229 13.1458 5.53895 13.23 5.64L16.86 10H5C4.73478 10 4.48043 10.1054 4.29289 10.2929C4.10536 10.4804 4 10.7348 4 11C4 11.2652 4.10536 11.5196 4.29289 11.7071C4.48043 11.8946 4.73478 12 5 12Z" fill="#211E24" />
  </svg>
  )
}

export default RightArrow