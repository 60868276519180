import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./BlogPageStyles.css";
import { Helmet } from "react-helmet";

const YashoIndustries = () => {
  useEffect(() => {
    window.scrollTo(0,0)
    }, [])

    const blogPost = {
      heading: "ORIGA’S innovative leasing strategy for Yasho Industries Limited's growth initiatives",
    };

    
  return (
    <>
    <Helmet>
            <title>{blogPost.heading}</title>
            <meta
              name="keywords"
              content={`${blogPost.heading}`}
            />
          </Helmet>
      <div className="blogpage-totaldiv">
        {" "}
        {/* Total div to contain all divs inside :  */}
        <div className="blogpage-headingcontent">
          <h6 className="CaseStudy">Case Study</h6>
          <h1 className="CaeStudy-content">
            ORIGA’S innovative leasing strategy for Yasho Industries
            Limited&#39;s growth initiatives
          </h1>
        </div>
        <div className="blogpage-profile">
          <div className="blogpage-profile-image">
            <img
              className="blogpage-profile-img-seperate"
              src="https://www.origa.market/asset/user.png"
            />
          </div>

          <div className="blogpage-profile-text">
            <p className="blogpage-para">
              <span>Origa</span>
              <span className="blogpage-year">July 5, 2024 • 2 min read</span>
            </p>
          </div>
        </div>
        <div className="blogpage-profile-bigimage">
          <img
            className="blogpage-profile-bigimage-seperate"
            src="/YahoPage.webp" 
          />
          <p className="blogpage-profile-bigimage-text">
            Yasho Industries Limited
          </p>
        </div>
        <div className="blogpage-realcontent">
          <h3 className="blogpage-realcontent-introductionheading">
            Company Background:
          </h3>
          <p className="blogpage-realcontent-introductioncontent">
            Yasho Industries Limited, a listed entity, was established in 1993.
            It is a prominent manufacturer of performance chemicals, catering to
            a wide array of industries, including Rubber &amp; Latex, Food &amp;
            Flavors, Perfumery, Lubricants, and other Specialty applications. As
            a listed and rated company, Yasho has a significant presence in the
            market.
          </p>

          {/* 
          <h3 className="blogpage-realcontent-introductionheading">Problem Statement:</h3>
          <p className="blogpage-realcontent-introductioncontent">
          In its pursuit of growth, Yasho Industries heavily relied on debt to finance its new projects.
          This strategy led to a substantial increase in its debt-to-equity ratio. However additional
          capital was required to fund its expansion.
          Recognizing the critical need for a sustainable financing solution, the company explored
          alternative financial strategies. It approached ORIGA, a company known for innovative
          leasing &amp; financing solutions, to understand how it could assist in its expansion.
          </p> */}

          <h3 className="blogpage-strategic-objectives">Problem Statement:</h3>
          <p className="blogpage-realcontent-Strategicpara">
          Yasho Industries aimed to achieve  key objectives with this campaign:
          </p>

          <ul className="blogpage-unorderd">
            <li className="blogpage-list dljgn">
              {" "}
              <span className="blogpage-boost">Growth Strategy: </span>Increase
              brand recognition and familiarity among potential customers in
              Mumbai.
            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Capital Requirement: </span>{" "}
              Additional capital was required to fund its expansion.
            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">
                Sustainable Financing Solution:
              </span>{" "}
              Recognizing the critical need for a sustainable financing
              solution, the company explored alternative financial strategies.
            </li>
            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Approaching ORIGA:</span> Yasho
              Industries approached ORIGA, a company known for innovative
              leasing & financing solutions, to understand how it could assist
              in its expansion.
            </li>
          </ul>

          <h3 className="blogpage-realcontent-innovateheading">
            Origa’s Innovative Leasing And Financing Solution:
          </h3>
          <p className="blogpage-realcontent-innovativecontent">
            After understanding the overall requirement and the corresponding
            constrains, ORIGA proposed an innovative leasing solution to Yasho.
            The leasing structure was carefully developed by ORIGA considering
            all the strengths as well as the constrains of the company.
          </p>

          <h4 className="blogpage-realcontent-leasingstructureheading">
            The leasing structure enabled the company to undertake INR 300
            Million of expansion.
          </h4>

          <div className="blogpage-realcontent-leasingstructureparagrapdiv">
         
              <p className="blogpage-realcontent-leasingstructureparagrap">
                This was undertaken without disturbing the current debt
                structure. It provided greater flexibility to the company to
                fund its expansion and the expected from the same is
                expected to have significant positive impact on the various financial parameters of the
                company in future.
              </p>
              <p className="blogpage-realcontent-leasingstructureparagrap">
                The positive experience of the company led Yasho to refer
                additional requirements to Origa, demonstrating the
                effectiveness and satisfaction derived from its financial
                advisory and solution.
              </p>
           
          </div>

          <h3 className="blogpage-realcontent-conclusion">Conclusion:</h3>

          <p className="blogpage-realcontent-conclusionparagraph">
            ORIGA provided a holistic solution to the company and addressed its
            requirements effectively. It advised the company in all the areas of
            the transaction such as working out the right lease structure,
            pricing discussion, appropriate documentation and transaction
            closure. It demonstrated deep domain expertise of leasing and
            financing throughout the transaction that not only protected the
            interests of the company but also provided adequate comfort to the
            leasing company.
          </p>
        </div>
      </div>

      <div className="lastline">
        <hr className="lastlinehr" />
      </div>

      <div className="somespacetop"></div>
      <Footer />
    </>
  );
};

export default YashoIndustries;
