import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../../../OrigaExtentionAPI/mutations";
import "../../Product/SparePage/ViewProduct.css"
import "../../../Buying/Product/SparePage/QuotationPopupStyles.css";

const client = secondClient;
const QuotationPopupForSpareAddtoCart = ({ productDetails, onSubmit }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  const [infokey, setInfokey] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  let { name, price, image, packPrice, bcukettSize, brands } =
    productDetails || {}; // Destructure productDetails

  const [selectedPriceList, setSelectedPriceList] = useState([]);
  // console.log("Inside QuotationPopupForSpareAddtoCart - name:", name);

  // console.log("Inside QuotationPopupForSpareAddtoCart - image:", image);

  const groupId = queryParams.get("group_id");
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productDETAILS, setProductDETAILS] = useState({});
  const [endQuantity, setEndQuantity] = useState();
  const [clickedPriceItem, setClickedPriceItem] = useState(null);
  const [bucketPrice, setBucketPrice] = useState(null);
  const [bucketSize, setBucketSize] = useState(null);
  const [bucketId, setBucketId] = useState(null); // State to store bucketId

  const calculateTotalValue = () => {
    // Calculate for barrel price when quantity is 11 or more
    if (selectedQuantity >= 11) {
      return bucketSize * bucketPrice;
    } else {
      // Calculate for bucket price for quantities less than 11
      return bucketSize * bucketPrice * selectedQuantity;
    }
  };

  // Calculate total value
  const totalvalue = calculateTotalValue();

  // console.log("Here in QuotationPopupForInSpareAddtoCart file the productId : ",productDetails?.id);

  const handlePopupSubmit = () => {
    console.log(
      "The clicked productId QuotationPopupForInSpareAddtoCart : ",
      productDetails?.id
    );
    console.log(
      "The clicked Selected Quantity  QuotationPopupForInSpareAddtoCart: ",
      selectedQuantity
    );
    console.log(
      "The clicked BucketId  QuotationPopupForInSpareAddtoCart: ",
      bucketId
    );

    onSubmit({
      productIded: productDetails?.id,
      quantityed: selectedQuantity,
      bucketIded: bucketId,
      priceList: selectedPriceList,
    });
  };

  useEffect(() => {
    if (productDETAILS) {
      const product = Object.values(productDETAILS).find(
        (item) => item.id === productDetails?.id
      );
      if (product) {
        setSelectedProduct(product);
        setSelectedPriceList(product.price_list || []);
        if (product.price_list.length > 0) {
          setClickedPriceItem(0);
          setBucketPrice(product.price_list[0].price);
          setBucketSize(product.price_list[0].bucket_size);
          setBucketId(product.price_list[0].bucket_id);
        }
      }
    }
  }, [productDETAILS, productDetails?.id]);

 
  const renderPriceList = () => {
    if (selectedPriceList.length > 0) {
      return (
        <div>
          {selectedPriceList.map((priceItem, index) => (
            <button
              key={index}
              className={`buttonclickednodvjbktorbfdg ${
                clickedPriceItem === index ? "btn-clicked" : ""
              }`}
              onClick={() => handlePriceClick(priceItem, index)}
            >
              <span className="priceItemLabel">{priceItem.price_label}</span>
              <br></br>
              <span
                className="offerrrr"
                style={{
                  color: clickedPriceItem === index ? "#58cb58" : "#58cb58",
                  fontSize: "16px",
                  fontWeight: "500"
                }}
              >
                {Math.ceil(
                  ((priceItem.mrp - priceItem.price) / priceItem.mrp) * 100
                )}
                % off
              </span>
              <br />₹{priceItem.price}/Ltr
              <br />
              <span
                className={`text-xs transition-colors duration-200 ${
                  clickedPriceItem === index || hoveredIndex === index ? "text-white" : "text-gray-500"
                }`}
              >
                <s>₹{priceItem.mrp}/Ltr</s>
              </span>
            </button>
          ))}
        </div>
      );
    }
    return <p>No price list available</p>;
  };

  

  const handlePriceClick = (priceItem, index) => {
    console.log("Clicked price item:", priceItem);
    setClickedPriceItem(index);
    setBucketPrice(priceItem.price);
    setBucketSize(priceItem.bucket_size);
    setEndQuantity(priceItem.end_quantity);
    setBucketId(priceItem.bucket_id); // Store bucket_id in state

    // Set the selected quantity based on the price item
    if (priceItem.start_quantity) {
      setSelectedQuantity(priceItem.start_quantity);
    }
  };

  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    //runs everytime when selectedQuantity or selectedProduct changes
    if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
      //checks if the price_list exists
      const applicablePriceItem = selectedProduct.price_list.find(
        (
          item //searches for the first item that matches the logic in the below
        ) =>
          selectedQuantity >= item.start_quantity && //checks the selectedquantity is within the bucket range
          (item.end_quantity === null || selectedQuantity <= item.end_quantity)
      );

      if (applicablePriceItem) {
        //if valid price item is found then
        setBucketPrice(applicablePriceItem.price); //set the price range to that butcketPrice
        setBucketSize(applicablePriceItem.bucket_size);
        setEndQuantity(applicablePriceItem.end_quantity);
        setClickedPriceItem(
          selectedProduct.price_list.indexOf(applicablePriceItem)
        ); //used to hightlight which priceItem is currently selected :
      }
    }
  }, [selectedQuantity, selectedProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: productId || "" },
        });
        setProductDETAILS(data?.inventoryItem?.response);
        setLoading(false);
        updateFormattedPrice();
      } catch (error) {}
    };
    fetchData();
  }, [groupId, productId]);

  const updateFormattedPrice = () => {
    setProductDETAILS((prevDetails) => ({
      ...prevDetails,
      price_details: {
        ...prevDetails.price_details,
        machine_price: formatCurrency(prevDetails.sales_rate),
      },
    }));
  };

  const formatCurrency = (value) => {
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value || 0);
  };

  const handleQuanttyChange = (increment) => {
    setSelectedQuantity((prevQuantity) => prevQuantity + increment);
  };

  const handlesizeandcolour = (index) => {
    const productArray = Object.values(productDETAILS).filter(
      (item) => typeof item === "object" && item.id
    );
    const selectedProduct = productArray[index];

    setInfokey(index);
    setSelectedQuantity(1);
    setSelectedItemId(selectedProduct.id);
    setSelectedProduct(selectedProduct);
  };

  const renderButtons = () => {
    console.log("productDETAILS inside renderButtons :", productDETAILS);

    const productArray = Object.entries(productDETAILS)
      .filter(([key]) => key !== "price_details")
      .map(([_, value]) => value);

    if (productArray.length === 0) {
      console.log("No products to render buttons for.");
      return <p>No product variants available.</p>;
    }

    return productArray
      .map((product, index) => {
        if (!product) {
          console.log(`Product at index ${index} is undefined or null`);
          return null;
        }

        const attribute1 = product.attributes?.attribute1;
        const attribute2 = product.attributes?.attribute2;

        console.log("Attribute 1  : ", attribute1);

        if (!attribute1 && !attribute2) {
          return null;
        }

        return (
          <button
            key={product.id}
            className={`buttonclickednotor ${
              product.id === selectedItemId ? "btn-clicked" : ""
            }`}
            onClick={() => handlesizeandcolour(index)}
          >
            {attribute1 && `${attribute1.options?.name}`}
            {attribute1 && attribute2 && <br />}
            {attribute2 && `${attribute2.options?.name}`}
          </button>
        );
      })
      .filter(Boolean);
  };

  return (
    <>
    
    <p className="quotationDetailsheading mt-2">Choose Variants </p>
  
    <div className="totalpopupdiv">
      <div className="popupContentWrapper">
        <div className="Contentisnidepopup">
          <p className="" style={{ fontWeight: "600" }}>
            {brands} - {name || "N/A"}
          </p>



                    {/* {renderButtons()} */}
                    <div className="renderPricListsf mt-1">
                    <p style={{fontWeight: "600" }}> Buying Options  </p>
          {renderPriceList()}
          </div>

          <p className="mt-1">
            <span style={{ fontWeight: "400" }}>
              {bcukettSize} (Pack of {selectedQuantity}) 
            </span>{" "}

<br></br>
            <span style={{ fontWeight: "620" }}>
{" "}
₹{totalvalue}   - <span  style={{ fontWeight: "620" }}>({bucketPrice !== null ? bucketPrice : "..."}/Ltr)</span>
</span>
          </p>
          
        

        


        
        </div>
        <div className="imageWrapper">
          <img className="imageinpopup" src={image || "N/A"} alt="Product" />
        </div>
      </div>
      <div className="buttonsqautntiyf ">

<div>

Qty :{" "}
<button
  className="butttonplusminus "
  onClick={() => handleQuanttyChange(-1)}
  disabled={selectedQuantity === 1}
>
  -
</button>
&nbsp;   {selectedQuantity}   &nbsp;
<button
  className="butttonplusminus"
  onClick={() => handleQuanttyChange(1)}
  disabled={selectedQuantity === 11}
>
  +
</button>

</div>


 <div className="sgjrdkbg">
 <button
className="button-submit-popup"
onClick={handlePopupSubmit}
// disabled={!bucketId}
>
Add to Cart
</button>
 </div>

</div>
    </div>

    </>
  );
};

export default QuotationPopupForSpareAddtoCart;
