import React from 'react'
import './Vector.css'
const Vector = ({onMove,move}) => {
const onMoveHandler=()=>{
if(onMove){
  onMove()
}else{
  return null
}
}
  return (
    <div className='div' onClick={onMoveHandler}>
        {move && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M5 12H16.86L13.23 16.36C13.146 16.4611 13.0826 16.5778 13.0437 16.7034C13.0047 16.829 12.9909 16.961 13.003 17.0919C13.0274 17.3564 13.1558 17.6003 13.36 17.77C13.5642 17.9397 13.8275 18.0214 14.0919 17.997C14.3563 17.9726 14.6003 17.8442 14.77 17.64L19.77 11.64C19.8036 11.5923 19.8337 11.5421 19.86 11.49C19.86 11.44 19.91 11.41 19.93 11.36C19.9753 11.2453 19.9991 11.1233 20 11C19.9991 10.8767 19.9753 10.7547 19.93 10.64C19.93 10.59 19.88 10.56 19.86 10.51C19.8337 10.4579 19.8036 10.4077 19.77 10.36L14.77 4.36C14.676 4.24712 14.5582 4.15634 14.4252 4.09412C14.2921 4.0319 14.1469 3.99976 14 4C13.7663 3.99955 13.5399 4.08092 13.36 4.23C13.2587 4.31395 13.175 4.41705 13.1137 4.5334C13.0523 4.64975 13.0145 4.77705 13.0025 4.90803C12.9904 5.03901 13.0043 5.17108 13.0433 5.29668C13.0824 5.42229 13.1458 5.53895 13.23 5.64L16.86 10H5C4.73478 10 4.48043 10.1054 4.29289 10.2929C4.10536 10.4804 4 10.7348 4 11C4 11.2652 4.10536 11.5196 4.29289 11.7071C4.48043 11.8946 4.73478 12 5 12Z" fill="#211E24"/>
</svg>}
        {!move && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
  <path d="M15 6L3.14 6L6.77 1.64C6.85405 1.53888 6.91735 1.4222 6.95631 1.29661C6.99526 1.17103 7.00909 1.039 6.99702 0.908073C6.97264 0.643646 6.84422 0.399736 6.64 0.229997C6.43578 0.060258 6.1725 -0.0214032 5.90808 0.00297649C5.64365 0.0273562 5.39974 0.155781 5.23 0.359998L0.23 6.36C0.196361 6.40772 0.166279 6.45786 0.14 6.51C0.14 6.56 0.0900008 6.59 0.0700003 6.64C0.0246741 6.75466 0.000941899 6.87671 6.1196e-07 7C0.000941878 7.12329 0.0246741 7.24534 0.0700003 7.36C0.0700003 7.41 0.12 7.44 0.14 7.49C0.166279 7.54214 0.196361 7.59227 0.23 7.64L5.23 13.64C5.32402 13.7529 5.44176 13.8437 5.57485 13.9059C5.70793 13.9681 5.85309 14.0002 6 14C6.23365 14.0005 6.46009 13.9191 6.64 13.77C6.74126 13.686 6.82496 13.5829 6.88631 13.4666C6.94766 13.3503 6.98546 13.2229 6.99754 13.092C7.00961 12.961 6.99573 12.8289 6.95669 12.7033C6.91764 12.5777 6.8542 12.461 6.77 12.36L3.14 8L15 8C15.2652 8 15.5196 7.89464 15.7071 7.7071C15.8946 7.51957 16 7.26521 16 7C16 6.73478 15.8946 6.48043 15.7071 6.29289C15.5196 6.10536 15.2652 6 15 6Z" fill="#211E24"/>
</svg>}
    </div>
  )
}

export default Vector