import React from 'react'

const EquipmentLoan = ({fill}) => {
  return (
    <svg
      width="90"
      height="90"
      viewBox={fill ? "5 5 80 80":"0 0 90 90"}
      fill={"none" || fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="noun-loan-4911487 1" opacity="1">
        <g id="Group 1000003878">
          <g id="Group">
            <path
              id="Vector"
              d="M59.5377 63.0082C57.7011 60.8551 56.0685 60.2781 54.9041 60.1568C52.7834 59.9357 51.5721 61.159 48.0725 61.9983C46.1327 62.4635 44.4802 62.5732 43.3795 62.5923C42.3859 62.7616 41.684 63.628 41.7162 64.5528C41.7524 65.5948 42.7125 66.4763 43.8547 66.3943H53.5378"
              stroke={fill ? fill : "#211E24"}
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              id="Vector_2"
              d="M41.5831 64.2556L31.5417 59.0094L30.8018 58.5891C29.6992 57.9162 28.328 58.3178 27.8605 59.2062C27.445 59.9956 27.7392 61.1767 28.6921 61.82C33.4446 64.7705 38.197 67.7209 42.9494 70.6715L45.0347 71.822C45.9274 72.3145 46.9288 72.577 47.9483 72.5859C51.9849 72.621 57.4807 72.6561 61.5173 72.6912"
              stroke={fill ? fill : "#211E24"}
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </g>
          <g id="Group_2">
            <path
              id="Vector_3"
              d="M30.7874 28.0422C32.6239 30.1953 34.2565 30.7723 35.4209 30.8936C37.5416 31.1147 38.7529 29.8914 42.2525 29.0521C44.1923 28.5869 45.8449 28.4772 46.9456 28.458C47.9391 28.2887 48.6411 27.4223 48.6089 26.4976C48.5727 25.4556 47.6125 24.5741 46.4703 24.656H36.7872"
              stroke={fill ? fill : "#211E24"}
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              id="Vector_4"
              d="M48.7421 26.7947L58.7834 32.0409L59.5234 32.4612C60.626 33.1341 61.9972 32.7325 62.4647 31.8441C62.8801 31.0547 62.586 29.8736 61.633 29.2303C56.8806 26.2798 52.1282 23.3294 47.3758 20.3789L45.2905 19.2283C44.3977 18.7358 43.3964 18.4733 42.3769 18.4644C38.3402 18.4293 32.8445 18.3942 28.8079 18.3591"
              stroke={fill ? fill : "#211E24"}
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </g>
          <path
            id="Vector_5"
            d="M36.4829 55.5514L50.6376 36.0105"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_6"
            d="M37.3316 43.9708C39.3914 43.9708 41.0612 42.3011 41.0612 40.2413C41.0612 38.1815 39.3914 36.5117 37.3316 36.5117C35.2718 36.5117 33.6021 38.1815 33.6021 40.2413C33.6021 42.3011 35.2718 43.9708 37.3316 43.9708Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_7"
            d="M50.0113 55.119C52.0711 55.119 53.7409 53.4493 53.7409 51.3895C53.7409 49.3297 52.0711 47.6599 50.0113 47.6599C47.9515 47.6599 46.2817 49.3297 46.2817 51.3895C46.2817 53.4493 47.9515 55.119 50.0113 55.119Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_8"
            d="M24.4508 15H24.4508C22.4104 15 20.7563 16.654 20.7563 18.6944V25.7474C20.7563 27.7878 22.4104 29.4419 24.4508 29.4419H24.4508C26.4912 29.4419 28.1452 27.7878 28.1452 25.7474V18.6944C28.1452 16.654 26.4912 15 24.4508 15Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            id="Vector_9"
            d="M65.5489 60.5581H65.5489C63.5085 60.5581 61.8545 62.2122 61.8545 64.2525V71.3055C61.8545 73.3459 63.5085 75 65.5489 75H65.5489C67.5893 75 69.2433 73.3459 69.2433 71.3055V64.2525C69.2433 62.2122 67.5893 60.5581 65.5489 60.5581Z"
            stroke={fill ? fill : "#211E24"}
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </g>
      </g>
    </svg>
  );
}

export default EquipmentLoan

