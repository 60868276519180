import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import "./BlogPageStyles.css";
import { Helmet } from "react-helmet";

const SevenMyths = () => {
    useEffect(() => {
        window.scrollTo(0,0)
        }, [])
    
        const blogPost = {
          heading: "Seven Myths About Equipment Leasing in India",
        };
    
        
      return (
        <>
        <Helmet>
                <title>{blogPost.heading}</title>
                <meta
                  name="keywords"
                  content={`${blogPost.heading}`}
                />
              </Helmet>
              <div className="blogpage-totaldiv">
        {" "}
        {/* Total div to contain all divs inside :  */}
        <div className="blogpage-headingcontent">
          <h6 className="CaseStudy">Blog</h6>
          <h1 className="CaeStudy-content">
          Seven Myths About Equipment Leasing in India
          </h1>
        </div>
        <div className="blogpage-profile">
          <div className="blogpage-profile-image">
            <img
              className="blogpage-profile-img-seperate"
              src="https://www.origa.market/asset/user.png"
            />
          </div>

          <div className="blogpage-profile-text">
            <p className="blogpage-para">
              <span>Origa</span>
              <span className="blogpage-year">Sept 16, 2024 • 4 min read</span>
            </p>
          </div>
        </div>
        <div className="blogpage-profile-bigimage">
          <img
            className="blogpage-profile-bigimage-seperate"
            src="/asset/internalimageofleasing5thcard.webp"
          />
          <p className="blogpage-profile-bigimage-text">
          Seven Myths About Equipment Leasing in India
          </p>
        </div>
        <div className="blogpage-realcontent">

          <ul className="blogpage-unorderd">
          <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Leasing and Loans Are Mutually Exclusive Markets:</span>{" "}
              It’s a common misconception
that leasing and loans operate in separate markets. In reality, both are products of
the equipment financing industry. Whether you’re considering a lease or a loan,
you’re looking at part of the same financial landscape. Both options cover the entire
spectrum of equipment financing opportunities, both tapped and untapped.
            </li>

            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Leasing Is Only for Those Who Can’t Get a Loan:</span>{" "}
              Many believe that leasing is a last
resort when loans aren’t available. However, leasing and loans are simply two
different tools in the equipment financing toolbox. Companies today use both,
choosing based on what best fits their financial strategy and equipment needs. They
can coexist, serving different aspects of expansion projects.
            </li>


            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Leasing Is Always More Expensive Than a Loan</span>{" "}
              The cost of leasing isn’t set in stone.
It varies depending on the assessment of the equipment and the financial credit of
the company. Contrary to popular belief, leasing isn’t always more expensive than
taking out a loan. The actual cost depends on various factors, including the nature of
the equipment and the terms of the lease.
            </li>




            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Leasing Doesn’t Require Financial Credit Assessment</span>{" "}
              Just like with loans, leasing
companies conduct thorough financial credit assessments. They evaluate your
company’s ability to meet lease obligations, ensuring that leasing is a viable option
based on your financial health.
            </li>


            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">The Type and Nature of Equipment Don’t Matter in Leasing</span>{" "}
              The type and nature of
the equipment are critical factors in leasing. Leasing companies assess the quality,
utility, and resale value of the equipment before entering into a leasing agreement.
This ensures that the equipment is a good fit for the lease terms and conditions.
            </li>

            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Leasing Isn’t Reported to CIBIL</span>{" "}
              Contrary to what some believe, most leasing
transactions are reported to CIBIL. There is a specific option for reporting leasing
transactions, making it an integral part of the credit reporting system.
            </li>


            <li className="blogpage-list">
              {" "}
              <span className="blogpage-boost">Depreciation Offers a Better Tax Shield Than Leasing</span>{" "}
              While depreciation under
ownership can provide significant tax benefits, lease rentals under an operating
lease are also 100% tax-deductible. Depending on the lease pricing, the tax benefits
from leasing can be comparable to, or even exceed, those from depreciation. It’s not
always the case that depreciation will offer a better tax shield.
            </li>
   
          </ul>



          <div className="lastconsclusioninfourthcard">
<a href="/finance" className="blogpage-year aakjbvdsf" style={{marginLeft:"10%"}}>Apply for lease today with India’s leading capex advisory &amp; leasing platform, ORIGA</a>

</div>
          

        </div>
      </div>

      <div className="lastline">
        <hr className="lastlinehr" />
      </div>

      <div className="somespacetop"></div>
    
          <div className="somespacetop"></div>
          <Footer />
        </>
      );
    };

export default SevenMyths