import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "./HotDealsnEWpromotions.css";
import { IoChevronBackSharp } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";

const HotDealsPromotionNew = () => {
  const promotions = [
    { imageUrl: "asset/CapexEquipment.jpg", altText: "Lease Hot Deals", url: "/finance" },
    { imageUrl: "asset/ServicePMandDB.jpg", altText: "Service Preventive Maintenance Hot Deals", url: "/service/pmc" },
    { imageUrl: "asset/CuttingandHydralicOil.jpg", altText: "Oil Hot Deals", url: "/store" },
    /* { imageUrl: "asset/first.jpg", altText: "Fourth Promotion", url: "https://example.com/fourth" }, */
  ];

  const Mobilepromotions = [
    { imageUrl: "asset/MobileCapexEquipment.jpg", altText: "Lease Hot Deals", url: "/finance" },
    { imageUrl: "asset/MobileServicePMandDB.jpg", altText: "Service Preventive Maintenance Hot Deals", url: "/service/pmc" },
    { imageUrl: "asset/MobileCuttingandHydralicOil.jpg", altText: "Oil Hot Deals", url: "/store" },
    /* { imageUrl: "asset/first.jpg", altText: "Fourth Promotion", url: "https://example.com/fourth" }, */
  ];
  const sliderRef = useRef(null); // Create a ref for the slider

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 620);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 620);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const advantageTopSection = {
    imgurl: "/OrigaHotDeals.svg",
    title: "Origa Hot Deals",
    para: "Step into a World of Origa Hot Deals",
  };

  return (
    <>
      {isMobile ? (
        <div className="hot-deals-container">
          <img src={advantageTopSection.imgurl} alt="" />
          <h2 className="text-left heading-600-24-20 c-green">
            Hot Deals & Promotions
          </h2>
          <div className="promotions-wrapper">
            {Mobilepromotions.map((promotion, index) => (
              <div className="promotion-card-upper" key={index}>
                <div className="promotion-card">
                  <a href={promotion.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={promotion.imageUrl}
                      alt={promotion.altText}
                      className="promotion-image"
                     /*  style={{ width: '75%', height: 'auto' }} */
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
          <a className="view-all-link">View All</a>
        </div>
      ) : (
        <div className="promotion-section-container">
          <div className="theheadingandsvg">
            <img src={advantageTopSection.imgurl} alt="" className="" />
            <h2 className="text-left heading-600-24-20 c-green">
              Hot Deals & Promotions
            </h2>
          </div>

          <div className="promotion-slider-wrapper">
            <button
              className="promotion-nav-button prev"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <IoChevronBackSharp />
            </button>
            <Slider ref={sliderRef} {...settings}>
              {promotions.map((promotion, index) => (
                <div key={index} className="promotion-slide-card">
                  <a href={promotion.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={promotion.imageUrl}
                      alt={promotion.altText}
                      className="promotion-slide-image"
                    />
                  </a>
                </div>
              ))}
            </Slider>
            <button
              className="promotion-nav-button next"
              onClick={() => sliderRef.current.slickNext()}
            >
              <IoChevronForward />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HotDealsPromotionNew;