import React from 'react'

const ThreeDIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="8" fill="#73509E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.6482 16.1455C32.6482 15.8642 32.5075 15.6007 32.2744 15.445C32.0412 15.2892 31.7454 15.2611 31.4865 15.371L23.6744 18.6744C23.3641 18.8056 23.1621 19.1117 23.1621 19.4491V28.5355C23.1621 28.8168 23.3028 29.0803 23.536 29.2361C23.7691 29.3918 24.0649 29.4188 24.3239 29.31L32.1359 26.0066C32.4462 25.8743 32.6482 25.5694 32.6482 25.2309V16.1455ZM30.9742 17.4122V24.6726L24.8362 27.2687V20.0073L30.9742 17.4122Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5094 15.371C16.2505 15.2612 15.9547 15.2891 15.7215 15.4449C15.4883 15.6007 15.3477 15.8641 15.3477 16.1454V25.2309C15.3477 25.5695 15.5496 25.8744 15.86 26.0067L23.672 29.3101C23.931 29.4187 24.2267 29.3919 24.4599 29.2361C24.6931 29.0804 24.8338 28.8169 24.8338 28.5356V19.4491C24.8338 19.1117 24.6318 18.8056 24.3215 18.6745L16.5094 15.371ZM17.0217 17.4121L23.1597 20.0071V27.2685L17.0217 24.6724V17.4121Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.258 12.0654C24.0493 11.978 23.8161 11.978 23.6085 12.0666L15.8577 15.3711C15.5485 15.5022 15.3477 15.8072 15.3477 16.1446C15.3477 16.482 15.5485 16.787 15.8577 16.9182L23.6085 20.2227C23.8161 20.3112 24.0493 20.3112 24.258 20.2238L32.1317 16.9204C32.4432 16.7904 32.6463 16.4843 32.6463 16.1446C32.6463 15.8061 32.4432 15.5 32.1317 15.3689L24.258 12.0654ZM23.9377 13.7525L29.6371 16.1446L23.9377 18.5367L18.3273 16.1446L23.9377 13.7525Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.6218 28.4208C33.2167 28.6596 33.6942 28.9174 34.0202 29.2055C34.1899 29.3556 34.3272 29.489 34.3272 29.6571C34.3272 29.7849 34.2356 29.8925 34.123 30.0092C33.893 30.249 33.5505 30.4654 33.1241 30.6716C31.5996 31.4103 29.1064 31.9338 26.1845 32.1132C25.7236 32.1412 25.372 32.5404 25.4 33.0033C25.429 33.4663 25.8252 33.8194 26.2873 33.7913C30.0416 33.5614 33.1384 32.7533 34.6642 31.7242C35.5759 31.11 36.0012 30.3734 36.0012 29.6572C36.0012 29.164 35.8116 28.6751 35.4052 28.2201C34.9656 27.7291 34.2311 27.2549 33.2424 26.8592C32.8138 26.6865 32.3261 26.8972 32.1542 27.3277C31.9835 27.7594 32.1922 28.248 32.6219 28.4207L32.6218 28.4208Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8166 32.1131C18.8948 31.9337 16.4017 31.4103 14.8769 30.6715C14.4506 30.4652 14.1079 30.2488 13.8781 30.009C13.7654 29.8925 13.6739 29.7848 13.6739 29.657C13.6739 29.4888 13.8112 29.3554 13.9809 29.2053C14.3067 28.9172 14.7844 28.6594 15.3793 28.4207C15.809 28.248 16.0177 27.7593 15.847 27.3277C15.6751 26.8973 15.1874 26.6865 14.7588 26.8592C13.7701 27.2549 13.0357 27.7289 12.596 28.2201C12.1898 28.6751 12 29.164 12 29.6572C12 30.3734 12.4252 31.1098 13.337 31.7242C14.8627 32.7533 17.9596 33.5614 21.7139 33.7913C22.1759 33.8192 22.572 33.4662 22.6012 33.0033C22.629 32.5403 22.2776 32.1414 21.8167 32.1132L21.8166 32.1131Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2015 31.23L21.7014 33.0079L19.9313 34.5133C19.5785 34.8126 19.5339 35.3438 19.833 35.6981C20.131 36.0525 20.6599 36.0962 21.0127 35.7969L23.421 33.7478C23.7738 33.4474 23.8184 32.9172 23.5193 32.5629L21.4793 30.1429C21.1801 29.7886 20.6523 29.7449 20.2996 30.0453C19.9468 30.3446 19.9033 30.8759 20.2013 31.2302L20.2015 31.23Z" fill="white"/>
  </svg>
  )
}

export default ThreeDIcon