import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dollerIcon, phoneIcon, tagIcon } from "../../helpers/Icons";
import "./MyOrdersDesign.css";
import axios from "axios";
import Footer from "../Footer/Footer";

const MyOrderSummaryDesign = () => {
  const location = useLocation();
  const [orderId, setOrderId] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const message = queryParams.get("message");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSize, setSize] = useState({ width: 38, height: 38 });
  const [invoiceUrl, setInvoiceUrl] = useState("");

  const selectedAddressIndex =
    parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;

  const selectedAddress = data ? data[selectedAddressIndex] : null;

  console.log(
    "selectedAddress-------------------------------->",
    selectedAddress
  );

  const handlegotoOrdersPage = () => {
    navigate("/yourorder");
  };

  const handleDownloadInvoice = () => {
    if (invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    } else {
      alert("Invoice URL not available");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
          }
        );
        console.log("API Response:", response.data);
        setData(response?.data?.response?.data?.user?.addresses);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      console.log("ekgjbbjksjbrsfnbrsfkjbfkjblsfljbfjkc : ------------------> ",customerId, referenceNumber)
      try {
        console.log("Fetching order details for:", {
          customerId,
          referenceNumber,
        });

        const response = await fetch(
          `https://ompextension.origa.market/sparesapi/get_orders`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customer_id: customerId,
              reference_number: referenceNumber,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }

        const data = await response.json();
        console.log("API response:", data);

        if (data.order) {
          setOrderDetails(data.order);

          // Extract the s3_url from the invoice_info
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id && message === "Paid") {
      const customerId = localStorage.getItem("id");
      fetchOrderDetails(customerId, id);
      console.log("Now here : ")
    }
  }, [id, message]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!orderDetails) {
    return <div>No order details found</div>;
  }

  return (
    <>
      <div className="container-fluid sbjlnlbf">
        <div className="max-container my-5">
          <div className="summary-wrap">
            <div className="content-wrap pt-5">
              <div className="bottom-wrap">
                <div className="top-heading">
                  <div className="heading-600-24 heading-600-24-20 heading-600-24-16 heading-600-24-14 select-heading">
                    <span className="ordersummaryheadingres">
                      Order Summary
                    </span>
                  </div>
                  <div className="customer-info indofrrrr">
                    {selectedAddress && (
                      <>
                        <span className="headingindelivery">Name : </span>
                        {selectedAddress.firstName} {selectedAddress.lastName}
                        <br></br>
                        <span className="headingindelivery">
                          Mobile Number :{" "}
                        </span>
                        {selectedAddress.phone}
                        <br></br>
                        <span className="headingindelivery">
                          Billing Address:{" "}
                        </span>
                        <br></br>
                        {selectedAddress.streetAddress1},<br></br>
                        {selectedAddress.city},{selectedAddress.countryArea},{" "}
                        {selectedAddress.postalCode}
                        <br></br>
                        <span className="headingindelivery">
                          Shipping Address:{" "}
                        </span>
                        <br></br>
                        {selectedAddress.shipping_address.streetAddress1},{" "}
                        {selectedAddress.shipping_address.city},<br></br>
                        {selectedAddress.countryArea},{" "}
                        {selectedAddress.shipping_address.postalCode}
                        <br></br>
                      </>
                    )}
                  </div>
                </div>
                <div className="iconsintetio right bi-process-wrap">
                  <span className="completed">
                    {tagIcon({
                      width: isSize.width,
                      height: isSize.height,
                      className: "completed",
                    })}
                  </span>
                  <span className="completed">
                    {dollerIcon({
                      width: isSize.width,
                      height: isSize.height,
                      className: "",
                    })}
                  </span>
                  <span className="completed">
                    {dollerIcon({
                      width: isSize.width,
                      height: isSize.height,
                      className: "",
                    })}
                  </span>
                </div>
                <div className=" ">
                  <div className="order-container">
                    {orderDetails.line_items.map((item, index) => (
                      <div className="order-item" key={index}>
                        <div className="product-wrapEB">
                          <div className="left-wraRSNFFDp">
                            <img
                              className="product-SBF "
                              src={item.thumbnail[0]?.url || "N/A"}
                              alt="chi-img"
                            />
                            <div className="detail-wrap">
                              <div className="heading-600-16 title">
                                {item.name}
                              </div>
                              <div className="price-wrap">
                                <div className="price-item">
                                  <div className="heading-600-14 price">
                                    ₹{item.rate}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="ammount-summarydiv indofrrrr">
                    <div className="heading-600-20 amount-heading">
                      Amount Summary
                    </div>
                    <div className="price-itemngnb">
                      <div className="heading-400-16-12 title">Price</div>₹
                      {orderDetails.total}
                    </div>
                    <div className="price-itemngnb">
                      <div className="heading-400-16-12 title">
                      Delivery Charges 
                      </div>
                      ₹{orderDetails.shipping_charge}
                    </div>
                    <div className="price-itemngnb">
                      <span className="heading-400-16-12 title">
                        Total Quantity
                      </span>
                      <div className="heading-600-16 total-price">{orderDetails.total_quantity}</div>
                    </div>
                    <div className="total-wrap">
                      <div className="heading-600-16 title">Total</div>
                      <div className="heading-600-16 total-price">
                        ₹{orderDetails.total}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="delivery-btn-wrap rwbjkn">
                  <button className="buttonhandlegotorders" onClick={handlegotoOrdersPage}>
                    Go to orders
                  </button>
                  <button className="buttondownloadinvoice" onClick={handleDownloadInvoice}>
                    Download invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyOrderSummaryDesign;
