import { connectRefinementList } from "react-instantsearch-dom";
import React, { useState, useEffect } from "react";

const ConnectedSpareRefinementList = ({
  attribute,
  items,
  refine,
  currentRefinement,
  setIndustryCheckBoxValue,
  CategoryCheckBoxValue,
  setCategoryCheckBoxValue,
}) => {
    console.log('attribute---->', attribute);
    console.log('items :: ', items)
  const regex = /.*?>\s*/g;
  const [selectedValues, setSelectedValues] = useState([]);

//   useEffect(() => {
//     console.log(`Values under attribute "${attribute}":`);
//     console.log(items.map((item) => item.label.replace(regex, "")));
//   }, [attribute, items]);

  const handleCheckboxChange = (event) => {
    const value = event.currentTarget.value;
    const newSelectedValues = [...selectedValues];

    // console.log(
    //   `CheckBox changed : ${value} , Checked : ${event.currentTarget.checked}`
    // );

    if (event.target.id === "categories.lvl0") {
      setIndustryCheckBoxValue(newSelectedValues);
    }

    if (event.target.id === "categories.lvl1") {
        if (event.currentTarget.checked) {
            setCategoryCheckBoxValue([value]); // Limit to one selection for "categories.lvl1"
            refine(value); // Perform refine action immediately for "categories.lvl1"
            return;
        }
        else {
            setCategoryCheckBoxValue([]);
            refine([]);
            return
        }

    }

    if (event.currentTarget.checked) {
      newSelectedValues.push(value);
    } else {
      const index = newSelectedValues.indexOf(value);
      newSelectedValues.splice(index, 1);
    }

    setSelectedValues(newSelectedValues);
    refine(newSelectedValues);
  };

  const handleReset = () => {
    setSelectedValues([]);
    setIndustryCheckBoxValue([]);
    setCategoryCheckBoxValue([]);
    refine([]);
  };

  useEffect(() => {
    // Get the Reset button element
    const resetBtn = document.getElementById('ResetBtn');
  
    if (resetBtn) { // Check if resetBtn is not null
      if (selectedValues.length > 0 || CategoryCheckBoxValue.length > 0) {
        resetBtn.style.display = 'block';
      } else {
        resetBtn.style.display = 'none';
      }
  
      // Add a click event listener to the Reset button
      resetBtn.addEventListener('click', handleReset);
  
      // Clean up: remove the event listener when the component unmounts
      return () => {
        resetBtn.removeEventListener('click', handleReset);
      };
    }
  }, [selectedValues, CategoryCheckBoxValue]); // Add CategoryCheckBoxValue to the dependency array
  
 
    // const headDiv = document.querySelector('.head');
    // const existingResetButton = headDiv?.querySelector('.reset-button');

    // // Check if a Reset button already exists
    // if (!existingResetButton && currentRefinement.length > 0) {
    //     const resetButton = document.createElement('div');
    //     resetButton.classList.add('clear', 'heading-600-14', 'reset-button');
    //     resetButton.textContent = 'Reset';
    //     resetButton.addEventListener('click', handleReset);

    //     headDiv.appendChild(resetButton);
    // } else if (existingResetButton && currentRefinement.length === 0) {
    //     // Remove the existing Reset button if no refinement is applied
    //     existingResetButton.remove();
    // }


  // The reset button will be conditionally rendered based on whether there are selected values
  return (
    <>
      {items.length === 0 ? (
        <p>No records found.</p>
      ) : (
        <>
          {items
            .filter((item) => item.label !== "0" && item.label !== "1900")
            .map((item) => (
              <div className="checkbox-item" key={item.id}>
                <label className="heading-400-16">
                  {item.label.replace(regex, "")} ({item.count})
                </label>
                <input
                  id={attribute}
                  type="checkbox"
                  name={item.name}
                  value={item.label}
                  checked={
                    attribute === "categories.lvl1"
                      ? CategoryCheckBoxValue.includes(item.label)
                      : selectedValues.includes(item.label)
                  }
                  onChange={handleCheckboxChange}
                />
              </div>
            ))}
          {/* {selectedValues.length > 0 || CategoryCheckBoxValue.length > 0 ? (
                        <button id="ResetBtn" onClick={handleReset}>
                            Reset
                        </button>
                    ) : null} */}
        </>
      )}
    </>
  );
};

export default connectRefinementList(ConnectedSpareRefinementList);
