import React from 'react'

const EmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M18.6772 6H4.92438C3.76146 6 2.80078 6.96067 2.80078 8.1236V17.0225C2.80078 18.1854 3.76146 19.1461 4.92438 19.1461H18.6772C19.8401 19.1461 20.8008 18.1854 20.8008 17.0225V8.1236C20.8008 6.96067 19.8401 6 18.6772 6ZM18.6772 7.01124L12.4075 12.1685C11.9525 12.5225 11.3457 12.5225 10.8907 12.1685L4.87381 7.01124H4.92438H18.6772ZM19.7895 17.0225C19.7895 17.6292 19.2839 18.1348 18.6772 18.1348H4.92438C4.31763 18.1348 3.81202 17.6292 3.81202 17.0225V8.1236C3.81202 7.92135 3.86258 7.76966 3.9637 7.61798L10.2334 12.9775C10.6379 13.3315 11.1435 13.4831 11.6491 13.4831C12.1547 13.4831 12.6098 13.3315 13.0143 12.9775L19.5873 7.56742C19.6884 7.7191 19.739 7.92135 19.739 8.1236V17.0225H19.7895Z" fill="black"/>
</svg>
  )
}

export default EmailIcon