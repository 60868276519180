import React from 'react'

const Cplus = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{borderRadius:"50%", border:"1px solid black", height:"24px", width:"24px"}}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_1_99741)">
                  <path d="M6.47762 11.173C6.47762 11.3114 6.53259 11.4441 6.63044 11.542C6.72828 11.6398 6.86099 11.6948 6.99936 11.6948C7.13774 11.6948 7.27044 11.6398 7.36829 11.542C7.46613 11.4441 7.5211 11.3114 7.5211 11.173V7.52086H11.1733C11.3116 7.52086 11.4444 7.46589 11.5422 7.36804C11.64 7.2702 11.695 7.13749 11.695 6.99912C11.695 6.86074 11.64 6.72804 11.5422 6.63019C11.4444 6.53235 11.3116 6.47738 11.1733 6.47738H7.5211V2.82521C7.5211 2.68683 7.46613 2.55413 7.36829 2.45628C7.27044 2.35844 7.13774 2.30347 6.99936 2.30347C6.86099 2.30347 6.72828 2.35844 6.63044 2.45628C6.53259 2.55413 6.47762 2.68683 6.47762 2.82521V6.47738H2.82545C2.68708 6.47738 2.55437 6.53235 2.45652 6.63019C2.35868 6.72804 2.30371 6.86074 2.30371 6.99912C2.30371 7.13749 2.35868 7.2702 2.45652 7.36804C2.55437 7.46589 2.68708 7.52086 2.82545 7.52086H6.47762V11.173Z" fill="black"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_99741">
                    <rect width="12.5217" height="12.5217" fill="white" transform="translate(0.738281 0.738281)"/>
                  </clipPath>
                </defs>
              </svg>
    </div>
  )
}

export default Cplus